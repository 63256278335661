import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { Avatar } from "@mui/material";
import { ArrowDown2, ArrowUp2, LogoutCurve } from "iconsax-react";
import { getCategoryList } from "../../Store/categories/categoryService";
import Imge from "../../assets/logo.png";
import {
  Profile,
  Heart,
  Notification,
  MedalStar,
  Box1,
} from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
const drawerWidth = 240;
function Menuicon(props) {
  const theme = useTheme();
  const {pathname}=useLocation()
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  React.useEffect(() => {
    setOpen(false)
  },[pathname]);
  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const categoryList = useSelector(
    (state) => state?.categories?.categoryList
  );
  const navigate=useNavigate()
  const Logout = () => {
    localStorage.removeItem("persist:mySecureKey");       
    navigate("/login");
    navigate(0);
  };
  const pages = [
    {
      nom: "Accueil",
      link: "home",
    },
    {
      nom:"Shop",
      link: "Shop",
    },
    {
      nom: "À propos",
      link: "AboutUs",
    },
    {
      nom: "Devenir partenaire",
      link: "BecomePartner",
    },
    {
      nom: "Contact",
      link: "Contact",
    },
    {
      nom: "Commandé",
      link: props.Auth?"/Commande_personnelle":"/Commande_personnelle2",
    },
    {
      nom: "Services",
    },

 ,
  !props.Auth? {
      nom: "Connexion",
      link:"login",
    } :{
      nom:"fullname",
      link:"Profile/Monidentite"
    }
    ,
    props.Auth?{
      nom:
      <div onClick={Logout} style={{display:"flex",alignItems:"center",gap:"1em"}}>
      <LogoutCurve size="20" />
      <div className="txt-icon">Déconnection</div>
    </div>
    }
    :null
    
  ];
  const dispatch=useDispatch()
  React.useEffect(() => {
    dispatch(getCategoryList());
  },[]);
  const [dropdownStates, setDropdownStates] = React.useState({});
  const dropDownOn = (key) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [key]: true,
    }));
    setOpen(true)
  };

  const dropDownOff = (key) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [key]: false,
    }));
    setOpen(true)
  };
  return (
    <Box>
      <CssBaseline />
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2}}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <IconButton onClick={handleDrawerOpen}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
        <Divider />
        <List>
          { pages?.map((page, key) => (
           page? 
            <MenuItem key={key} className="dropdown-content">
                {page?.link==="Shop"?
              
                  <div style={location.pathname==="/"+page?.link?{textAlign: "left", backgroundColor:"rgb(229 169 27)",color:"#fff",width:"100%"}:{textAlign: "center",color:"#fff"}}>
                   <div style={{display:"flex",alignItems:"center", gap:"5em"}}>
                   <Link to={page?.link}style={location.pathname==="/"+page?.link?{color:"#fff"}:{color:"#222"}}> 
                   <div>Shop</div>
                   </Link>
                   {!dropdownStates[key] ? (
                          <ArrowDown2
                            size="17"
                            color="#fff"
                            className="icondown"
                            onClick={(e) => dropDownOn(key)}
                          />
                        ) : (
                          <ArrowUp2
                            size="17"
                            className="icondown"
                            onClick={(e) => {
                              dropDownOff(key);
                            }}
                             color="#fff"
                          />
                        )}
 
                 </div>
         <div className={dropdownStates?.[1] ?"inlinke":"hding"}>

    {
       Array.isArray(categoryList) && categoryList?.map((obj, key) => (
        <Link    style={location.pathname==="/shop/"+obj?.id?{background:"#E9B949"}:null} to={"/shop/"+obj?.id}><p className="txt-content">{obj?.name}</p></Link>
       ))
    }
</div>
                </div>
               :
               page?.nom==="Services"?

               <Typography style={location.pathname==="/"+page?.link?{textAlign: "left", backgroundColor:"rgb(229 169 27)",color:"#fff",width:"100%"}:{textAlign: "center",color:"#fff"}}>
                   <div style={{display:"flex",alignItems:"center", gap:"2em"}}>
                   <Link to={page?.link}> 
                   <div>Services</div>
                   </Link>
                   {!dropdownStates[key] ? (
                          <ArrowDown2
                            size="17"
                            color="#fff"
                            className="icondown"
                            onClick={(e) => dropDownOn(key)}
                          />
                        ) : (
                          <ArrowUp2
                            size="17"
                            color="#fff"
                            className="icondown"
                            onClick={(e) => {
                              dropDownOff(key);
                            }}
               
                          />
                        )}
 
                 </div>
         <div className={dropdownStates?.[key] ?"inlinke":"hding"}>
  <Link to="/Services_Informatique" > services informatiques</Link>
  <Link to="/Faire_un_don" > Faire un don</Link>
</div>
                </Typography>

                :page?.nom==="fullname"?
                <Typography >
                <div style={{display:"flex",alignItems:"center", gap:"2em"}}>
                <Link to={page?.link}> 
                <div style={{display:"flex",alignItems:"center",gap:"1em"}}>
      <Avatar
      style={{ height: "25px", width: "25px" }}
      src={props?.avatar?props?.avatar:null}
      className="avrt-pagenave"
      />
      <div> <p className="username2"> {props?.fullname}</p></div>
      </div>
                </Link>
                {!dropdownStates[key] ? (
                       <ArrowDown2
                         size="17"
                         color="#fff"
                         className="icondown"
                         onClick={(e) => dropDownOn(key)}
                       />
                     ) : (
                       <ArrowUp2
                         size="17"
                         color="#fff"
                         className="icondown"
                         onClick={(e) => {
                           dropDownOff(key);
                         }}
            
                       />
                     )}

              </div>


<div className={dropdownStates?.[key] ?"inlinke":"hding"}>
  
<Link to="/Profile/Monidentite" > 
<div className="row1-profile">
                  <Profile size="20"variant="Bulk" />
               <div className="txt0-icon">Profile</div>
                 </div> 
</Link>
<Link to="/Profile/Favoris" > 
<div className="row1-profile">
                  <Heart size="20" variant="Bulk"/>
               <div className="txt0-icon">Favoris</div>
                 </div> 
</Link>
<Link to="/Profile/Commandes" > 
<div className="row1-profile">
                  <Box1 size="20" variant="Bulk"/>
               <div className="txt0-icon">Commandes</div>
                 </div> 
</Link>
<Link to="/Profile/Avis" > 
<div className="row1-profile">
                  <Notification size="20" variant="Bulk"/>
               <div className="txt0-icon">Mes avis</div>
                 </div> 
</Link>
<Link to="/Profile/MesPoints" > 
<div className="row1-profile">
                  <MedalStar size="20" variant="Bulk"/>
               <div className="txt0-icon">Mes points</div>
                 </div> 
</Link>

</div>
                </Typography>
                  :
                <Typography style={location.pathname==="/"+page?.link?{textAlign: "left", backgroundColor:"rgb(229 169 27)",color:"#fff",width:"100%"}:{textAlign: "center",color:"#fff"}}>
                <Link to={page?.link} onClick={handleDrawerOpen}style={location.pathname==="/"+page?.link?{color:"#fff"}:{color:"#222"}}>
                  {page?.nom}
                </Link>
                
                </Typography>
            
                }
                
              </MenuItem>
            :null

          ))}
               <Link to="/home">
<div className="logo3">
<img src={Imge} alt="logo"/>
</div>
        </Link>
        </List>
        <Divider />
      </Drawer>
    </Box>
  );
}
export default Menuicon;
