import axios from "axios"
import { createAsyncThunk} from '@reduxjs/toolkit';
import { Path, Base_url } from '../../config/Config';
export const getAlluser = createAsyncThunk(
    'getAlluser',
    async (data) => {
      try {
        const response = await axios.get(Base_url+ Path.getAlluser+`?sortBy=id&sortOrder=de&page=${data.current}&pageSize=20`)
        return response.data
      } catch (err) {
        
      }
    }
)