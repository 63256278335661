import React, { useEffect, useState } from 'react'
import './Liste_livraisons.css'
import Filterbar from '../../../filterbar/Filterbar';
import { Sort } from 'iconsax-react';
import {
  Avatar,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Base_url, Path } from '../../../../config/Config';
const Liste_livraisons = () => {
  const [action, setAction] = React.useState("tout");
  const [all, setAll] = React.useState([]);
  const { id } = useParams();
  const data = [
    { label: 'Tout', action: 'tout' },
    { label: 'En cours', action: 'en_cours' },
    { label: 'Compléter', action: 'Compléter' },

  ];  
  const [current,setCurrent]=useState(1)
  const [totalPages, settotalPages] =useState();
  function handlePagination (event,page) {
    setCurrent(page)
  }
const AllFavorisp = async () => {
  try {
    const response = await axios.get(Base_url + Path.findCommandeBylibrairie+id+`?sortBy=id&sortOrder=desc&page=${current}&pageSize=10&etatcommande=${action}`);
    setAll(response?.data?.commandes)
    settotalPages(response?.data?.totalPages)
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
useEffect(() => {
  AllFavorisp()
}, [current,action]);
return (
    <div className='Liste_commandes'>
      <div className='row-Liste_liv'>
   {
       data?.map((item, index) => (

        <div key={index} className={item.action==action?'box1-Liste_commandes':'box2-Liste_commandes'} onClick={()=>{setAction(item.action);setCurrent(1)}}>
         {item.label}
        </div>
       ))
   }
      </div>
      <Filterbar icon={  <Sort size="22" color="#222222"/> } nom="Trier par" />
<TableContainer
    component={Paper}
    style={{
      boxShadow: "none",
      borderWidth: " 1px 0px",
      borderStyle: "solid",
      borderColor: "#EEEDF2",
      width: "100%" 
    }}
  >
    <Table sx={{ width: "100%" }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>
            <p className="tableCell">#</p>
          </TableCell>
          <TableCell align="left">
            <p className="tableCell">User</p>
          </TableCell>
          <TableCell>
            <p className="tableCell">Montant</p>
          </TableCell>
          <TableCell align="left">
            <p className="tableCell">Nbr d’articles</p>
          </TableCell>
          <TableCell align="left">
            <p className="tableCell">Date de création</p>
          </TableCell>
          <TableCell align="left">
            <p className="tableCell">Statut</p>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {all?.map((item, index) => (
   <TableRow key={index}>
   <TableCell>
   {item?.id}
   </TableCell>
   <TableCell>
   <div className='row-tab'><Avatar src={item?.user?.avatar} className='img-tab'/><div><p>{item?.user?.fullname}</p></div> </div>
   </TableCell>
   <TableCell >
  <p className='Montant-tab'>{item?.total_ttc?.toFixed(3)}</p>
   </TableCell>
   <TableCell >
  <p className='Nbr_articles-tab'>{item?.produitlabrairies?.length}</p>
   </TableCell>
   <TableCell>
  {item?.createdAt}
   </TableCell>
   <TableCell>
   {item?.etatVender==="Compléter"?
<><button className='bnt01-c'><p className='txtbnt01-c'>{item?.etatVender}</p></button></>
:
<>
{
item?.etatVender==="en_cours" ||item?.etatVender==="En cours"?
<button className='bnt02-c'><p className='txtbnt02-c'>{item?.etatVender}</p></button>
:<>{item?.etatVender==="Rejeter"?<button className='bnt02-c' style={{background:"#FACDCD"}}><p className='txtbnt02-c'>Rejeter</p></button>:
<>{item?.etatVender==="Nouveau"?<button className='bnt02-c' style={{background:"#FCEFC7"}}><p className='txtbnt02-c'style={{color:"#513C06"}}>{item?.etatVender}</p></button>:<></>}</>}</>}</>}
   </TableCell>


 </TableRow>

      ))}
   
   
      </TableBody>
    </Table>
  </TableContainer>
  <div className="pagination-serc">
  <Pagination
                  count={totalPages}
                  shape="rounded"
                  className="pagination-shop"
                  page={current}
                  onChange={handlePagination}
            />
         </div>
    </div>
  )
}

export default Liste_livraisons
