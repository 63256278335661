import React, { useState } from 'react'
import {
    Avatar,
    OutlinedInput,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@mui/material";
  import { Trash,CloseCircle, TickCircle, Send2, ArrowCircleLeft, Stickynote, ConvertCard, ArrowDown2, ArrowUp2} from "iconsax-react";
  import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
  import Paper from "@mui/material/Paper";
import { More, Sort } from 'iconsax-react';
import Filterbar from '../../../filterbar/Filterbar';
import axios from 'axios';
import { Base_url, Path } from '../../../../config/Config';
import { toast } from 'react-toastify';
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';

const TableeEchange = (props) => {
    const [iduser ,setIduser] = useState()
    const [modallisteoffre ,setmodallisteoffre] = useState(false)
    const [modallisteoffre2 ,setmodallisteoffre2] = useState(false)
    const handleOpenM = () => setmodallisteoffre(true);
    const handleCloseM = () => setmodallisteoffre(false);
    const handleOpenM2 = () => setmodallisteoffre2(true);
    const handleCloseM2 = () => setmodallisteoffre2(false);
    const [idEchange ,setidEchange] = useState()
    const [all3 ,setall3] = useState([])
    const [iduprods ,setiduprods] = useState()
    const [val ,setval] = useState()
    const [state ,setstate] = useState()
    const [anchorEl, setAnchorEl] =useState(null);
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background: "#FFFFFF",
      width: "1128px",
      height: "715px",
      boxShadow:
        "2px 5px 15px rgba(26, 31, 39, 0.02), 10px 15px 40px rgba(26, 31, 39, 0.03)",
      borderRadius: "8px",
      padding:"10px",
      paddingLeft:"40px",
      paddingRight:"40px"
    
    };
    const style2 = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background: "#FFFFFF",
      width: "869px",
      height: "627px",
      boxShadow:
        "2px 5px 15px rgba(26, 31, 39, 0.02), 10px 15px 40px rgba(26, 31, 39, 0.03)",
      borderRadius: "8px",
      padding:"10px",
      paddingLeft:"40px",
      paddingRight:"40px"
    
    };
const open = Boolean(anchorEl);
const handleClose = () => {
    setAnchorEl(null);
  };
  const [current,setCurrent]=useState(1)
  const NbPage=1
  const [data,setdata]=useState([])
  const [idofre,setidofre]=useState()

  const [data2,setdata2]=useState([])
  function handlePagination (event,page) {
    props.changecurrent(page)
  }
  const getoffrebyechange = async () => {
    try {
      const response = await axios.get(Base_url +`offre/findOffresbyechange/${iduprods}?&sortBy=createdAt&sortOrder=desc&page=${current}&pageSize=10`);
     setdata(response.data.offres)

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [productValues, setProductValues] = useState([
    { name: "", quantite: 0},
    { name: "", quantite: 0 },
    { name: "", quantite: 0 },
    { name: "", quantite: 0 },
    { name: "", quantite: 0 },
  ]);
  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    const updatedProductValues = [...productValues];
    
    if (name === "name") {
      updatedProductValues[index][name] = value;
    } else if (name === "quantite") {
      updatedProductValues[index][name] = parseInt(value, 10);
    }
    
    setProductValues(updatedProductValues);
  }
  const getoffbyechange = async (id) => {
    try {
      const response = await axios.get(Base_url +`offre/findOffresbyechange/${id}?sortBy=id&sortOrder=desc&page=${props.current}&pageSize=1`);
      setall3(response.data.echanges)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [dis, setdis] = useState("")
  const [productValues2, setProductValues2] = useState([
    { name: "", quantite: 0 },
    { name: "", quantite: 0 },
    { name: "", quantite: 0 },
    { name: "", quantite: 0 },
    { name: "", quantite: 0 },
  ]);
  const handleInputChange2 = (event, index) => {
    const { name, value } = event.target;
    const updatedProductValues = [...productValues2];
    if (name === "name") {
      updatedProductValues[index][name] = value;
    } else if (name === "quantite") {
      updatedProductValues[index][name] = parseInt(value, 10); 
    }
    
    setProductValues2(updatedProductValues);
  }
  const accepteoffre = async () => {
    try {
      const response = await axios.put(Base_url + 'offre/accepterOffre/'+idofre,dis);
      toast.success("votre échange a éte accepte avec success", {
        autoClose: 2000,
  });
      setAnchorEl(null);
    } catch (error) {
      console.error("Error fetching data:", error);
      setAnchorEl(null);
    }
  };
  const Anullerchange = async () => {
    try {
      const response = await axios.put(Base_url + Path.Anullerchange+iduprods);
      setAnchorEl(null);
      toast.success("votre échange a éte supprimer avec success", {
        autoClose: 2000,
  });
    } catch (error) {
      console.error("Error fetching data:", error);
      setAnchorEl(null);
    }
  };

  const Submit = async () => {
    try {
        if (productValues.length === 0 || productValues2.length === 0 ||
            productValues.every(item => item.name === "" && item.quantite === 0) ||
            productValues2.every(item => item.name === "" && item.quantite === 0)) {
              toast.error("Product values are empty or contain only objects with empty properties");
            return;
        }else{ const data = {
          "echangeofffk": idEchange,
          "labofffk": props.user,
          "produitsaechange": productValues.map(({ name, quantite }) => ({ name, quantite })),
          "produitsechange": productValues2.map(({ name, quantite }) => ({ name, quantite })),
      };

      const response = await axios.post(Base_url + Path.addOffre, data);
      props.changeref()
      handleCloseM2()
      toast.success("Offre créé avec succès");
}

       
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};
const [dropdownStates, setDropdownStates] = useState({});
const [dropdownStates2, setDropdownStates2] = useState({});


const dropDownOn = (key) => {
  setDropdownStates((prevState) => ({
    ...prevState,
    [key]: true,
  }));
};

const dropDownOff = (key) => {
  setDropdownStates((prevState) => ({
    ...prevState,
    [key]: false,
  }));
};

const dropDownOn2 = (key) => {
  setDropdownStates2((prevState) => ({
    ...prevState,
    [key]: true,
  }));
};

const dropDownOff2 = (key) => {
  setDropdownStates2((prevState) => ({
    ...prevState,
    [key]: false,
  }));
};
const formatDate = (inputDate) => {
  const dateObj = new Date(inputDate);
  const day = dateObj.getDate().toString().padStart(2, '0');
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObj.getFullYear().toString();
  return `${day}-${month}-${year}`;
};
return (
<div className='tab'>
<Filterbar icon="none"/>
<TableContainer
    component={Paper}
    style={{
      boxShadow: "none",
      borderWidth: " 1px 0px",
      borderStyle: "solid",
      borderColor: "#EEEDF2",
      width: "100%" ,
      height: "0px !important"
    }}
  >
    <Table sx={{ width: "100%" }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>
            <p className="tableCell">#</p>
          </TableCell>
          {props?.th?.map((item, index) => (
               <TableCell key={index}>
               <p className="tableCell">{item.th}</p>
             </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>

      {
        props?.data?.map((item, index) => (
        <TableRow  key={index} >
        <TableCell style={{cursor:"pointer"}} >
        {item?.id}
        </TableCell >
   
        <TableCell style={{ cursor: "pointer" }}>
        <div style={{display:"flex",alignItems:"center",gap:"0.5em"}}>
        <div>
      <Avatar src={item?.labrairie?.imageStore}/>
      </div>
      <div>
      {item?.labrairie?.nameLibrairie}
      </div>
        </div>
        </TableCell>



        <TableCell style={{ cursor: "pointer" }}>
        {item?.labrairie?.telephone}

        </TableCell>
        <TableCell style={{ cursor: "pointer" }}>
        {item?.labrairie?.adresse}

        </TableCell>
        <TableCell>
       <p className='text-echange'>{formatDate(item?.createdAt)}</p>
        </TableCell>
        <TableCell>
       <p className='text-echange'>----------</p>
        </TableCell>
        <TableCell >
        { item?.Etat ==="Résolue"?
       <div className="stats-vensder">{item?.Etat }</div> 
       :
       item?.Etat ==="En_attente"?
       <div className="stats-admin3">{item?.Etat }</div> 
       :
       <div className="stats-admin4"><p>{item?.Etat }</p></div> 
         }
        </TableCell>
        <TableCell>
        <More
      size="22"
      color="#222222"
      aria-controls={open ? "basic-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={open ? "true" : undefined}
      className="more-ad"
      onClick={(e)=>{setAnchorEl(e.currentTarget);setstate(item?.Etat);setiduprods(item?.id);getoffbyechange(item?.id);setIduser(item?.id)}}
     />
     
        </TableCell>
      </TableRow>
         ))}
 
   
   
      </TableBody>
    </Table>
  </TableContainer>
 
 
 
 
 
 
 
 
 
 
  <Menu
              id="basic-menu"
              className="menu-avis"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {state!=="Résolue"?
<>
   <MenuItem className="menuitem-avis" onClick={()=>{handleOpenM();handleClose();getoffrebyechange()}} >
     <Stickynote size="30" color="#626262" />
     <span className="spn-ad">
     Liste des offres
     </span>
   </MenuItem>
   </>

:<></>

}

              <MenuItem
                className="menuitem-avis"
                onClick={Anullerchange}
              >
                <CloseCircle size="22" color="#D64545" />
                <span>
                  <p className="spn-ad2">Supprimer</p>
                </span>
              </MenuItem>
  </Menu>
  <Modal
                    open={modallisteoffre}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    onClose={handleCloseM}
                  >
                    <Box sx={style}>
               <div className="col-modal">
               <div className="flex-end">
                 
                 <div>
             
                   <i className="fa fa-close" onClick={handleCloseM}></i>
                 </div>
               </div>
<div className='conterM-echange'>

<div className='colM-echange'>

<div><h2>Liste des offres</h2></div>
<div><h5 style={{width:"211px"}}>Liste des produits offret pour l’echange par vous</h5></div>
<div>
<div className='scrolling'>
<TableContainer
    component={Paper}
    style={{
      boxShadow: "none",
      borderWidth: " 1px 0px",
      borderStyle: "solid",
      borderColor: "#EEEDF2",
      width: "100%" 
    }}
  >
    <Table sx={{ width: "100%" }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>
            <p className="tableCell">#</p>
          </TableCell>
          <TableCell>
            <p className="tableCell">Date</p>
          </TableCell>
</TableRow>
  </TableHead>
  <TableBody>

    {data.map((data, index) => (
        
  <TableRow className='hoverscro' onClick={()=>{     
   const updatedProductValues = data?.produitaechanges.map((product, index) => {
    return {
      name: product?.Name, 
      quantite: parseInt(product?.Qte) || 0 
    };
  });
  const updatedProductValues2 = data?.produitechanges.map((product, index) => {
    return {
      name: product?.Name, 
      quantite: parseInt(product?.Qte) || 0 
    };
    
  });
  setProductValues2(updatedProductValues2)
  setProductValues(updatedProductValues);
  setidofre(data.id);
  }}> 

  <TableCell>
            <p >{data.id}</p>
  </TableCell>
  <TableCell>
            <p >{formatDate(data.createdAt)}</p>
  </TableCell>
  </TableRow>
  
  ))}


    
    </TableBody>
  </Table>
  </TableContainer>
  </div>
</div>


</div>



<hr className='line-mechange'/>


<div className='colM-echange'>
<div><h2>Detaille d’offre</h2></div>
<div className='conterM-echange'>
  <div className='colM-echange'>
    <div><h5 >Liste des produits offret pour l’echange par vous</h5></div>
   <div className='colM-ech'>
   {productValues.map((productValue, index) => (
      <div style={{ display: "flex" }} key={index}>
        <OutlinedInput
          className="input-int2"
         value={productValue.quantite}
          name="quantite"
          onChange={(event) => handleInputChange(event, index)}
        />
        <OutlinedInput
          name="name"
          value={productValue.name}
          className="input-echM"
          onChange={(event) => handleInputChange(event, index)}
          placeholder={`Produit ${index + 1}`}
        />
      </div>
    ))}
   </div>
</div>
  <div className='colM-echange'>
  <div><h5 >Les produits offret par l’autre partie en echange par votre liste</h5></div>
  <div className='colM-ech'>
               {productValues2.map((productValue, index) => (
      <div style={{ display: "flex" }} key={index}>
        <OutlinedInput
               disabled={true}

          className="input-int2"
          value={productValue.quantite}
          name="quantite"
          onChange={(event) => handleInputChange2(event, index)}
        />
        <OutlinedInput
               disabled={true}

          name="name"
          className="input-echM"
          onChange={(event) => handleInputChange2(event, index)}
          placeholder={`Produit ${index + 1}`}
          value={productValue.name}
        />
      </div>
    ))}
 </div>
  </div>
</div>
<div className='colM-ech'>
  <h3>Laissez un message</h3>
  <OutlinedInput
         disabled={true}

          className="inputM-echa"
          name="quantite"
          value={dis}
          onChange={(event)=>{setdis(event)}}
        />

        <button className='bnt-m1' onClick={accepteoffre}>Accepter l’offre</button>
  </div>

</div>
</div>
</div>
    </Box>
                  </Modal>
                  <Modal
                    open={modallisteoffre2}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    onClose={handleCloseM2}
                  >
                    <Box sx={style2}>
               <div className="col-modal">
               <div className="flex-end">
                 
                 <div>
             
                   <i className="fa fa-close" onClick={handleCloseM2}></i>
                 </div>
               </div>
<div className='conterM-echange'>

<div className='colM-echange'>
<div><h2>Proposition d’offre</h2></div>
<div className='conterM-echange'>
  <div className='colM-echange'>
    <div><h5 >Liste des produits offret pour l’echange par l’autre partie</h5></div>
   <div className='colM-ech'>
   {productValues.map((productValue, index) => (
      <div style={{ display: "flex" }} key={index}>
        <OutlinedInput
               disabled={true}

          className="input-int2"
          value={productValue.quantite}
          name="quantite"
          onChange={(event) => handleInputChange(event, index)}
        />
        <OutlinedInput
               disabled={true}

          name="name"
          style={{width: "290px"}}
          className="input-echM"
          onChange={(event) => handleInputChange(event, index)}
          value={productValue.name}
 />
      </div>
    ))}
   </div>
</div>
  <div className='colM-echange'>
  <div><h5 >Les produits offret par vous en echange par la liste de l’autre partie</h5></div>
  <div className='colM-ech'>
               {productValues2.map((productValue, index) => (
      <div style={{ display: "flex" }} key={index}>
        <OutlinedInput
          className="input-int2"
          value={productValue.quantite}
          name="quantite"
          onChange={(event) => handleInputChange2(event, index)}
        />
        <OutlinedInput
          name="name"
          className="input-echM"
          style={{width: "290px"}}
          value={productValue.name}

          onChange={(event) => handleInputChange2(event, index)}
          placeholder={`Produit ${index + 1}`}
        />
      </div>
    ))}
 </div>
  </div>
</div>
<div className='colM-ech'  >
        <button className='bnt-m1' onClick={()=>{Submit()}}>Accepter l’offre</button>
  </div>

</div>
</div>
 
 
               </div>
                      
                    </Box>
                  </Modal>
  <div className="pagination-echn">


           <Pagination
             count={isNaN(props.totalPages)?1:props.totalPages}
             shape="rounded"
             className="pagination-shop"
             page={props.current}
             onChange={handlePagination}
           />
         </div>
</div>
  )
}

export default TableeEchange
