
import axios from "axios";
import { Path, Base_url } from "../../config/Config";
export const suggestion = async ({data,access}) => {
  try {
    const response = await axios.post(Base_url + Path.suggestionProduitadd,data,{
      headers: {
        Authorization: "Bearer " + access,
      },
    });
    return response.data;
  } catch (err) {
    console.log(access)
  }
};
export const suggestionforni = async ({data,access}) => {
  try {
    const response = await axios.post(Base_url + Path.suggestionProduitaddfroni,data,{
      headers: {
        Authorization: "Bearer " + access,
      },
    });
    return response.data;
  } catch (err) {
    console.log(access)
  }
};
