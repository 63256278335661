import axios from "axios"
import { createAsyncThunk} from '@reduxjs/toolkit';
import { Path, Base_url } from '../../config/Config';
export const getAllcommandeByclient = createAsyncThunk(
    'getAllcommandeByclient',
    async (data) => {
      try {
        const response = await axios.get(Base_url+ Path.getAllcommandeByclient+data.id+`?sortBy=id&sortOrder=desc&page=${data.current}&pageSize=20&etatcommande=${data.etat}`)
        return response.data
      } catch (err) {
        
      }
    }
);
export const findCommandeByidentifiant = createAsyncThunk(
  'findCommandeByidentifiant',
  async (data) => {
    try {
      const response = await axios.get(Base_url+ Path.findCommandeidentifiant+`?sortBy=id&sortOrder=desc&page=${data.current}&pageSize=20&etatcommande=${data.etat}&search=${data.searchValue}`)
      return response
    } catch (err) {
      
    }
  }
);