import {createSlice} from "@reduxjs/toolkit";
import { getprofileForni } from "../Service/getfrofileVender";
export const SlicegetprofileForni=createSlice({
  name:"SlicegetprofileForni",
  initialState:{
    getprofileForni:[],
    status:null
  },
  reducers: {},
  extraReducers:{
    [getprofileForni.fulfilled]:(state,{payload})=>{
        state.getprofileForni=payload.Fournisseur;
       state.status="success"
    },
   [getprofileForni.pending]:(state)=>{
    state.status="loading"
   },
   [getprofileForni.rejected]:(state)=>{
    state.status="failed"
   }

  }  

})

export default SlicegetprofileForni.reducer;