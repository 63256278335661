import {createSlice} from "@reduxjs/toolkit";
import { AllListProduitforni } from "../Service/AllistProduitLib";
export const AlllistProduitLibforni=createSlice({
  name:"AlllistProduitLibforni",
  initialState:{
    listProduitforni:[{}],
    status:null,
    totalpage:[]
    
  },
  reducers: {},
  extraReducers:{
    [AllListProduitforni.fulfilled]:(state,{payload})=>{
        state.listProduitforni=payload.produit
        state.status="success"
        state.totalpage=payload.totalPages
    },
   [AllListProduitforni.pending]:(state)=>{
    state.status="loading"
   },
   [AllListProduitforni.rejected]:(state)=>{
    state.status="failed"
   }
  }  

})

export default AlllistProduitLibforni.reducer;