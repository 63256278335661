import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Path, Base_url } from "../../config/Config";

const { productPath, adminPath } = Path


export const getProductList = createAsyncThunk(
  'products/get_list',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${Base_url}${productPath}findAll?page=1&pageSize=20&sortBy=titre&sortOrder=desc`)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const getFilteredProducts = createAsyncThunk(
  'products/get_filtered_products',
  async (data, { rejectWithValue }) => {
    const {SouscategorieId,current,prixMax,qteMin,qteMax,etat,titre,prixMin,cat,sortBy,sortOrder,librairieName,librairieAddress}=data
  console.log(librairieName)
    try {
      const response = await axios.get(`${Base_url}${adminPath}filtercommande?${SouscategorieId && SouscategorieId.length ? `SouscategorieId=${SouscategorieId.join(',')}` : ''}${prixMin ? `&prixMin=${prixMin}` :`&prixMin=0`}${prixMax ? `&prixMax=${prixMax}` : ''}${qteMin ? `&qteMin=${qteMin}` :`&qteMin=0`}${qteMax ? `&qteMax=${qteMax}` : ''}${etat? `&etat=${etat}` :cat==="etat=remise"?`&etat=remise` :''}${titre ? `&titre=${titre}` : ''}${current ? `&page=${current}` : `&page=1`}${cat && cat!=="etat=remise" ? `&categprodlabfk=${cat}` : ''}&pageSize=20${sortBy ? `&sortBy=${sortBy}` : '&sortBy=createdAt'}${sortOrder ? `&sortOrder=${sortOrder}` : '&sortOrder=desc'}${librairieAddress ? `&librairieAddress=${librairieAddress}` : ''}${librairieName ? `&librairieName=${librairieName}` : ''}`);
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)
export const getallProducts = createAsyncThunk(
  'products/get_filtered_products',
  async (data, { rejectWithValue }) => {
    const {SouscategorieId,current,prixMax,qteMin,qteMax,etat,titre,prixMin,cat,sortBy,sortOrder}=data
    try {
      const response = await axios.get(`${Base_url}admin/allproduits?${SouscategorieId && SouscategorieId.length ? `SouscategorieId=${SouscategorieId.join(',')}` : ''}${prixMin ? `&prixMin=${prixMin}` : ''}${prixMax ? `&prixMax=${prixMax}` : ''}${qteMin ? `&qteMin=${qteMin}` : ''}${qteMax ? `&qteMax=${qteMax}` : ''}${etat? `&etat=${etat}` :cat==="etat=remise"?`&etat=remise` :''}${titre ? `&titre=${titre}` : ''}${current ? `&page=${current}` : `&page=1`}${cat && cat!=="etat=remise" ? `&categprodlabfk=${cat}` : ''}&pageSize=20${sortBy ? `&sortBy=${sortBy}` : '&sortBy=titre'}${sortOrder ? `&sortOrder=${sortOrder}` : '&sortOrder=desc'}`);
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)
export const getAllReviews = createAsyncThunk(
  'products/get_all_reviews',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${Base_url}${adminPath}allavis`)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)