import React, { useEffect } from "react";
import "./navbar.css";
import Imge from "../../assets/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ShoppingCart, Logout, ArrowDown2 } from "iconsax-react";
import Menuicon from "../menu-icon/minu";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import { getIdentiteClientt } from "../../Store/Service/identiteClient";
import { getCategoryList } from "../../Store/categories/categoryService";
function NavBar(props) {
  const location = useLocation();
  const categoryList = useSelector(
    (state) => state?.categories?.categoryList
  );
  const navigate = useNavigate();        

 const Lsogout = async () => {
    try {
      await new Promise(async (resolve) => {
        localStorage.removeItem("persist:mySecureKey");       
         resolve();
      });
      navigate("/login");
      navigate(0);   
     } catch (error) {
      console.error("Error while logging out:", error);
    }
  };
  const nbprod = useSelector((state) => state.Panier.nbprod);
  const clientData = useSelector(
    (state) => state.IdentiteClient.identiteClient
  );
  const dispatch=useDispatch()
useEffect(() => {
  dispatch(getIdentiteClientt(props.user.id));
  dispatch(getCategoryList());
},[]);
  return (
    <header className="header">
           <div className="icon-menu">
            <Menuicon  Auth={props.user.auth} avatar={clientData?.avatar} fullname={clientData?.fullname}/>
           </div>
      <div className="logo">
        <Link to="/home">
          <img src={Imge} alt="logo"className="logo2"/>
        </Link>
   
      </div>
      <div className="groupli">
        <ul className="main-nav">
          <Link to="/home"  >
            <li style={location.pathname==="/home"?{ color:"#E9B949",}:{textAlign: "center",color:"#fff"}}>Accueil</li>
          </Link>
          <Link to="/Shop">
            <li>
     <div className="dropdown" >
     <div className="dropbtn" style={{display:"flex",alignItems:"center", gap:"0.5em"}}>
              <div><p style={location.pathname==="/Shop"?{ color:"#E9B949",}:{textAlign: "center",color:"#fff"}}>Shop</p></div>
              <ArrowDown2 size="18"style={location.pathname==="/Shop"?{ color:"#E9B949",}:{textAlign: "center",color:"#fff"}}/> 
             </div>
             <div className="dropdown-content">
    {
       Array.isArray(categoryList) && categoryList?.map((obj, key) => (
        <Link to={"/shop/"+obj?.id} style={location.pathname==="/shop/"+obj?.id?{background:"#E9B949",color:"#fff"}:null}>
          <p className="txt-content">{obj?.name}</p></Link>
       ))
    }
  </div>
     </div>
         </li>
          </Link>
          <Link to={props.user.auth?"/Commande_personnelle":"/Commande_personnelle2"}>
            <li style={location.pathname==="/Commande_Personnalisée2"?{ color:"#E9B949",}:{textAlign: "center",color:"#fff"}}>Commandé</li>
          </Link>
          <Link to="/AboutUs">
            <li style={location.pathname==="/AboutUs"?{ color:"#E9B949",}:{textAlign: "center",color:"#fff"}}>À propos</li>
          </Link>
          <Link to="/BecomePartner">
            <li style={location.pathname==="/BecomePartner"?{ color:"#E9B949",}:{textAlign: "center",color:"#fff"}}>Devenir partenaire</li>
          </Link>
      

        
           <Link >
            <li>
     <div className="dropdown" >
     <div className="dropbtn" style={{display:"flex",alignItems:"center", gap:"0.5em"}}>
              <div><p>Services</p></div>
              <ArrowDown2 size="18"/> 
             </div>
             <div className="dropdown-content">
  <Link to="/Services_Informatique">
    <p className="txt-content">services informatiques</p></Link>
    <Link to="/Contact">
    <p className="txt-content">Contact</p></Link>
 
    <Link to="/Faire_un_don">

    <p className="txt-content">Faire un don</p></Link>
  </div>
     </div>
         </li>
          </Link>
        </ul>
      </div>
      <div className={props.user.auth?"group0":"group032"}>
        <div className="cartchop">
            <Link
              to={props?.user?.etatCompte === "bloque" ? null : "/cart"}
              className="iconcolor"
            >
              <ShoppingCart size="32" color="#FFFFFF" variant="Bold" />
              <div className="patch">
                <p className="patch-txt">+{nbprod}</p>
              </div>
            </Link>
        </div>
        <div>
          <Link
            to="/login"
            className={
              props.user.auth ? "butto-NAV-link-none" : "butto-NAV-link"
            }
          >
            <button className="butto-NAV">
              <p className="conx">Connexion</p>
            </button>
          </Link>
          {props?.user?.etatCompte === "bloque" && props.user.auth ? (
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1em",
              }}
              onClick={Lsogout}
            >
              <Logout size="32" color="#fff" variant="Bold" />
              <div>
                <p style={{ color: "#fff" }}>LogOut</p>
              </div>
            </div>
          ) : (
            <Link
              to={
                props.user.role === "client"
                  ? "Profile/Monidentite"
                  : props.user.role === "labrairie"
                  ? "/Vender/TableauDuBord"
                  : props.user.role === "Admin"
                  ? "/Admin/TableauDuBord"
                  : props.user.role === "partenaire"
                  ? "/partenaire"
                  : props.user.role === "fournisseur"
                  ? "/fournisseur/TableauDuBord"
                  : ""
              }
            >
              <div
                className={
                  props.user.auth ? "section_user" : "section_user-none"
                }
              >
                <p className="username"> {clientData?.fullname}</p>
                <Avatar
                  style={{ height: "32px", width: "32px" }}
                  src={clientData?.avatar}
                  className="avrt-pagenave"
                />
              </div>
            </Link>
          )}
        </div>
      </div>
    </header>
  );
}

export default NavBar;
