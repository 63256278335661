import {createSlice} from "@reduxjs/toolkit";
import { findCommandeByidentifiant } from "../Service/getAllcommandebyClient";
export const findCommandeByid=createSlice({
  name:"findCommandeByid",
  initialState:{
    commandes:[],
    total:null,
    status:null
  },
  reducers: {},
  extraReducers:{
    [findCommandeByidentifiant.fulfilled]:(state,{payload})=>{
      state.commandes=payload;
     state.status="success";
     state.totalpage=payload?.totalPages;
  },
   [findCommandeByidentifiant.pending]:(state)=>{
    state.status="loading"
   },
   [findCommandeByidentifiant.rejected]:(state)=>{
    state.status="failed"
   }

  }  

})

export default findCommandeByid.reducer;