import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Path, Base_url } from "../../config/Config";


export const getCategoryList = createAsyncThunk(
  'categories/get_list',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${Base_url}${Path.categoryPath}`)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)
