
import axios from "axios"
import { createAsyncThunk} from '@reduxjs/toolkit';
import { Path, Base_url } from '../../config/Config';
export const findCommandeBylibrairie = createAsyncThunk(
    'findCommandeBylibrairie',
    async (data) => {
      try {
        const response = await axios.get(Base_url+ Path.findCommandeBylibrairie+data.id+`?sortBy=id&sortOrder=desc?&page=${data.currentt}&pageSize=10&etatcommande=tout`)
        return response.data
      } catch (err) {
        
      }
    }
  )