import { createSlice } from "@reduxjs/toolkit";
import { getAllReviews, getFilteredProducts, getProductList } from "./productService";

const initialState = {
  productList: {},
  reviews: {},
  productData: null,
  loading: false,
  error: null,
};

const productPending = (state) => {
  state.loading = true;
};

const productError = (state, action) => {
  state.error = action.payload;
};
export const productSlice = createSlice({
  name: "products",
  initialState,
  total:null,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductList.pending, productPending)
      .addCase(getAllReviews.pending, productPending)
      .addCase(getProductList.rejected, productError)
      .addCase(getAllReviews.rejected, productError)
      .addCase(getFilteredProducts.fulfilled, (state, { payload }) => {
        state.productList = payload;
        state.total = payload;
      })
      .addCase(getFilteredProducts.pending, productPending)
      .addCase(getFilteredProducts.rejected, productError)
      .addCase(getProductList.fulfilled, (state, { payload }) => {
        state.productList = payload;
      })
      .addCase(getAllReviews.fulfilled, (state, { payload }) => {
        state.productListFiltered = payload;
      });
  },
})

export default productSlice.reducer;
