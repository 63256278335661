import React, { useEffect, useState } from "react";
import './Explorerfournisseur.css'
import Avatar from '@mui/material/Avatar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Pagination from "@mui/material/Pagination";
import Filterbar from '../../filterbar/Filterbar';
import {ArrowLeft2,ArrowRight2,Sort} from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { Base_url, Path } from "../../../config/Config";
import axios from "axios";
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


const Explorerfournisseur = (props) => {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);
    const [action, setaction] = React.useState("tout");

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
      const [all, setAll] = React.useState();
      const navigat=(id)=>{
        navigate(`/Vender/Details_de_livraison/${id}`)
    }
    
            const items =8;
      const [current,setCurrent]=useState(1)
      const [NbPage,setNbPage]=useState()

      function handlePagination (event,page) {
        setCurrent(page)
      }   const [total, settotal] = React.useState(1);

      const Allcomnde = async () => {
        try {
          const response = await axios.get(Base_url + Path.findCommandeBylibrairielivre + props?.user.id+`?sortBy=id&sortOrder=desc&page=${current}&pageSize=20&etat=${action}`);
          setAll(response?.data?.livraison);
          setNbPage(response?.data?.totalPages)
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      useEffect(() => {
        Allcomnde()
        if(all?.length===0) 
        {
          navigate(0)
        }
      },[action,current]);

  return (
    <div className='liste-c'>
  <div>   <p className='txt-c'>Explorer</p></div>
    <div className='box01-lv'>
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: { background: "#F7D070" } }}>
         <Tab label={<p className="txttabs-c">Articles</p>} {...a11yProps(0)} />
          <Tab  label={<p className="txttabs-c">Fournisseurs</p>} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
      <br/>
      <div className='row01-c'>
      <Filterbar icon={"none"}nom="Trier par"/>

<table>
  <tbody>
<tr>

<th>#</th>
<th>Produit</th>
<th>Prix</th>
<th>Qté</th>
<th>Fournisseur</th>
<th>Téléphone</th>
<th></th>
</tr>
{all?.map((obj,index) => (

<tr key={index}  style={{cursor:"pointer"}}>

<td className='tdwidth-lv'>{obj.id}</td>
<td className='tdwidth02-lv'> <div className="row-c">
          
            <Avatar src={obj?.user?.avatar} className="img1-c"style={{borderRadius:"50%"}} />
              <div style={{marginTop:"3%"}}><p className='txt01-c'>{obj?.user?.fullname}</p></div>
              </div>
              </td>
<td className='tdwidth1-lv'><p className='txt02-c'>{obj?.total_ttc?.toFixed(2)}</p></td>
<td className='tdwidth1-lv'>{obj?.produitlabrairies?.length}</td>
<td className='tdwidth1-lv'>{obj?.createdAt}</td>

<td className='tdwidth1-lv'>
{obj?.etatVender==="Compléter"?
<><button className='bnt01-lc'><p className='txtbnt01-lc'>{obj?.etatVender}</p></button></>
:
<>
<button className='bnt02-lc'><p className='txtbnt02-lc'>{obj?.etatVender}</p></button>
</>
}
  </td>

</tr>

))}

</tbody>
</table>
      </div>

 
        </TabPanel>
        <TabPanel value={value} index={1}>
      <br/>
      <div className='row01-c'>
      <Filterbar icon={"none"}nom="Trier par"/>

<table>
  <tbody>
<tr>

<th>#</th>
<th>Produit</th>
<th>Prix</th>
<th>Qté</th>
<th>Fournisseur</th>
<th>Téléphone</th>
<th></th>
</tr>
{all?.map((obj,index) => (

<tr key={index}  style={{cursor:"pointer"}}>

<td className='tdwidth-lv'>{obj.id}</td>
<td className='tdwidth02-lv'> <div className="row-c">
          
            <Avatar src={obj?.user?.avatar} className="img1-c"style={{borderRadius:"50%"}} />
              <div style={{marginTop:"3%"}}><p className='txt01-c'>{obj?.user?.fullname}</p></div>
              </div>
              </td>
<td className='tdwidth1-lv'><p className='txt02-c'>{obj?.total_ttc?.toFixed(2)}</p></td>
<td className='tdwidth1-lv'>{obj?.produitlabrairies?.length}</td>
<td className='tdwidth1-lv'>{obj?.createdAt}</td>

<td className='tdwidth1-lv'>
{obj?.etatVender==="Compléter"?
<><button className='bnt01-lc'><p className='txtbnt01-lc'>{obj?.etatVender}</p></button></>
:
<>
<button className='bnt02-lc'><p className='txtbnt02-lc'>{obj?.etatVender}</p></button>
</>
}
  </td>

</tr>

))}

</tbody>
</table>
      </div>

 
        </TabPanel>
    </Box>
    </div>
    <div className='page-c'>  

    <Pagination
                  count={isNaN(NbPage)?1:NbPage}
                  shape="rounded"
                  className="pagination-shop"
                  page={current}
                  onChange={handlePagination}
        />
        
        </div>

    </div>
  )
}




export default Explorerfournisseur
