import React, { useEffect, useState } from "react";
import "./TableauDuBordF.css";
import Rating from "@mui/material/Rating";
import "chart.js/auto";
import { Bar, Doughnut } from "react-chartjs-2";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { nb_commande_par_jour } from "../../../Store/Service/nb_commande_par_jour";
import moment from "moment";
import { nb_commande } from "../../../Store/Service/nb_commande";
import { ProduitMieuxNote } from "../../../Store/Service/produitMieuxNote";
import { Pagination } from "@mui/material";
import { Base_url, Path } from "../../../config/Config";
import axios from "axios";
const TableauDuBordF = (props) => {
  const op = ["1", "2", "3", "4", "5", "6", "7"];
  const [selectedValue, setSelectedValue] = useState(7);
  const [prodplusvende, setprodplusvende] = useState([]);
  const [totalpage, settotalpage] = useState("");

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const librairieData = useSelector(
    (state) => state?.findCommandeBylibrairie?.commandeslibrairie
  );
  const [current,setCurrent]=useState(1)
  const [findLatestCommande,setfindLatestCommande]=useState([])
  function handlePagination (event,page) {
    setCurrent(page)
  }
  const [nbcommande,setnbcommande]=useState({  Completercommande: 0,
    Encourscommande: 0,
    Nouveaucommande: 0,
    Rejetercoomande: 0,
    commandes: [],
    success: false})
  const [nbcommandeparjour,setnbcommandeparjour]=useState([])
  const [nbr,setnbr]=useState()
  const [produit_mieux_note,setproduit_mieux_note]=useState([])
  const plusvende = async () => {
    try {
      const response = await axios.get(Base_url + "fournisseur/findtopproduct/"+props?.user.id);
      setprodplusvende(response.data.produits )
      setnbr(response.data.count)

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const findLatestCommandes = async () => {
    try {
      const response = await axios.get(Base_url + 'fournisseur/findLatestCommandes/'+props?.user.id+`?sortBy=id&sortOrder=desc?&page=${current}&pageSize=10&etatcommande=tout`);
     settotalpage(response.data.totalpage)
      setfindLatestCommande(response.data.latestCommandes )
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const ProduitMieuxNote = async () => {
    try {
      const response = await axios.get(Base_url + 'fournisseur/gettoprevprod/'+props?.user.id);
     setproduit_mieux_note(response.data.latestCommandes )
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const nb_commande = async () => {
    try {
      const response = await axios.get(Base_url + 'fournisseur/findallcommandebystate/'+props?.user.id);
      setnbcommande(response.data)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  
  const nb_commande_par_jour = async () => {
    try {
      const response = await axios.get(Base_url + 'fournisseur/findCommandeinday/'+props?.user.id+"?days="+selectedValue);
      setnbcommandeparjour(response.data.commandes )
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    Promise.all([
      findLatestCommandes(),
       plusvende(),
       ProduitMieuxNote(),
       nb_commande(),
    ])
  },[current]);
  useEffect(() => {
    Promise.all([
nb_commande_par_jour()
    ])
  },[current,selectedValue]);
  const currentDate = moment().format("YYYY-MM-DD");
  const filteredData = librairieData?.filter((item) => {
    const parsedDate = moment(item.createdAt, "YYYY-MM-DD");
    const threeDaysAgo = moment().subtract(10, "days");
    return parsedDate?.isBetween(threeDaysAgo, currentDate, null, "[]");
  });
  const data = {
    labels: [
      `Compléter  (${nbcommande?.Completercommande})`,
      `en_cours  (${nbcommande?.Encourscommande})`,
      `rejetees  (${nbcommande?.Rejetercoomande})`,
      `nouvelles  (${nbcommande?.Nouveaucommande})`,
    ],
    datasets: [
      {
        data: [
          nbcommande?.Completercommande,
          nbcommande?.Encourscommande,
          nbcommande?.Rejetercoomande,
          nbcommande?.Nouveaucommande,
        ],
        backgroundColor: ["#7BC47F", "#62B0E8", "#E66A6A", "#F9DA8B"],
        cutout: "80%",
      },
    ],
  };
  const dat = {
    labels: [],
    datasets: [
      {
        label: "Nombre de commandes",
        data: [],
        backgroundColor: ["#F7D070"],
        hoverBackgroundColor: ["#F7D070"],
        borderRadius: 8,
        borderWidth: 1,
        barThickness: 40,
      },
    ],
  };
  console.log(nbcommande)
  dat.labels.push(nbcommandeparjour?.date);
  dat.datasets[0].data.push(nbcommandeparjour?.nbr);
  nbcommandeparjour?.forEach((value) => {
    dat?.labels.push(value?.date);
    dat?.datasets?.[0]?.data?.push(value.nbr);
  });
  const options = {
    percentageInnerCutout: 0,
    plugins: {
      legend: {
        position: "right",
        labels: {
          usePointStyle: true,
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 50,
        top: 0,
        bottom: 0,
      },
    },
    rotation: 90,
  };
  const optionss = {
    responsive: true,

    plugins: {
      legend: {
        display: false,
      },
    },
  };


  return (
    <div className="Tb">
      <div>
        <p className="txt1-Tb">Salut, {props.user.fullname} 👋</p>
        <p className="txt2-Tb">Consultez votre tableau de bord aujourd'hui</p>
      </div>

      <div className="row1-tb">
        <div className="bloq1-tb">
          <div style={{display:"flex",gap:"0.5em",alignItems:"center"}}>
            <p className="txt3-Tb">
              Les plus vendus
            </p>
            <p className="txt4-Tb"> (30 derniers jours)</p>

          </div>

          <div className="scroll">
            {prodplusvende?.map((obj,index) => (
              obj?
                <div className="col1-tb" key={index}>
                <div className="row2-Tb">
                  <div>
                    <p className="txt5-Tb">
                    X{obj ? nbr[index] : 0}
                    </p>
                  </div>
                  <img
                    src={
                      
                      obj?.imageproduitfournisseurs?.[0].name_Image
                    }
                    className="img-Tb"
                  />
                  <div>
                    <p className="txt6-Tb">
                      {obj?.titre}
                    </p>
                  </div>
                </div>
              </div>
              :null
              
         
            ))}
          </div>
        </div>

   
        <div className="bloq10-tb">
        <div style={{display:"flex",gap:"0.5em",alignItems:"center"}}>
            <p className="txt3-Tb">
            Commandes d’états 
                  </p>
            <p className="txt4-Tb"> (30 derniers jours)</p>

          </div>

          <div className="row3-Tb">
            <div>
              <div
                style={{
                  width: "300px",
                  height: "300px",
                  position: "relative",
                }}
              >
                <Doughnut
                  data={data}
                  options={options}
                  width={200}
                  height={200}
                />
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    top: "52%",
                    left: " -29%",
                    textAlign: "center",
                    marginTop: "-28px",
                    lineHeight: "20px",
                  }}
                >
                  <p className="txt-2014">{nbcommande?.commandes?nbcommande?.commandes?.length:0}</p>
                  <p className="txt-2040">Total</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blq3-Tb">
        <div className="row3-tb">
          <div>
            <p className="txt9-tb">Nombre de commandes</p>
          </div>
          <div>
            <Select
              value={selectedValue}
              onChange={handleSelectChange}
              className="txt-select"
              style={{
                marginTop: "-2%",
                width: "202.57px",
                height: " 40px",
                borderRadius: "8px",
              }}
            >
              {op?.map((option,index) => (
                <MenuItem key={index} value={option} className="txt-select">
                  Les {option} derniers jours
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <Bar data={dat} options={optionss} width={800} height={200} />
      </div>

      <div className="blq4-Tb">
        <div>
          <p className="txt9-tb">Dernières commandes</p>
        </div>
        <br/> <br/>
        <table>
        <tbody>
          <tr>
            <th>#</th>
            <th>Client</th>
            <th>Montant</th>
            <th>Nbr d’articles</th>
            <th>Date de création</th>
            <th>Mise à jour</th>
          </tr>

          {findLatestCommande?.map((obj, index) => (
            <tr
              className={
                obj?.etatVender === "Nouveau" ? "backnovo-c" : "backnovo-c0"
              }
              key={index}
            >
              <td className="tdwidth">{obj?.id}</td>
              <td className="tdwidth02">
         
                <div className="row-c">
                  <Avatar
                    src={ obj?.labrairie?.imageStore}
                    style={{ borderRadius: "50%" }}
                    className="img1-c"
                  />
                  <div style={{ marginTop: "3%" }}>
                    <p className="txt01-c">{obj?.labrairie?.nameLibrairie}</p>
                  </div>
                </div>
              </td>
              <td className="tdwidth1">
                <p className="txt02-c">{obj.total_ttc?.toFixed(2)}</p>
              </td>
              <td className="tdwidth1">
                {obj?.produitfournisseurs?.length}
              </td>
              <td className="tdwidth1">{obj?.createdAt}</td>

              <td className="tdwidth1">
                {obj.etatfournisseur === "Compléter" ? (
                  <>
                    <button className="bnt01-c">
                      <p className="txtbnt01-c">Compléter</p>
                    </button>
                  </>
                ) : (
                  <>
                    {obj.etatfournisseur === "En cours"||obj.etatfournisseur === "en_cours" ? (
                      <button className="bnt02-c">
                        <p className="txtbnt02-c">{obj.etatfournisseur}</p>
                      </button>
                    ) : (
                      <>
                        {obj.etatfournisseur === "Rejeter" ? (
                          <button
                            className="bnt02-c"
                            style={{ background: "#FACDCD" }}
                          >
                            <p className="txtbnt02-c">{obj.etatfournisseur}</p>
                          </button>
                        ) : (
                          <>
                            {obj.etatfournisseur === "Nouveau" ? (
                              <button
                                className="bnt02-c"
                                style={{ background: "#FCEFC7" }}
                              >
                                <p
                                  className="txtbnt02-c"
                                  style={{ color: "#513C06" }}
                                >
                                  {obj.etatfournisseur}
                                </p>
                              </button>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </td>
            </tr>
          ))}
          </tbody>
        </table><br/><br/>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
      <Pagination
                  count={totalpage}
                  shape="rounded"
                  className="pagination-shop"
                  page={current}
                  onChange={handlePagination}
                />
      </div>

 
      </div>

    </div>
  );
};
export default TableauDuBordF;