import React, { useEffect,useState } from "react";
import'./Vendeurs.css'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Trash,CloseCircle} from "iconsax-react";
import { useDispatch} from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import { getAlluser } from "../../../../Store/Service/getAlluser.js";
import { bloqueuser } from "../../../../Store/Service/actionuser.js";
import Tablee from "./Table/Table";
import axios from "axios";
import { Base_url, Path } from "../../../../config/Config";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const Vendeursadmin  = (props) => {
    const [op2, setop2] = React.useState(false);
    const [iduser ,setIduser] = useState()
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
      const handeldata2=(data)=>{
        setCurrent(data)
      }
      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);
      const handleClose = () => {
        setAnchorEl(null);
      };
      const [Alluser, setAlluser] =useState([]);
      const [action,setaction]=useState('tout')
      const [current,setCurrent]=useState(1)
      const [totalpage,settotalpage]=useState(1)
      const gteAlllab= async () => {
        try {
          const response = await axios.get(Base_url + `admin/findallLaibrairieadmin?sortBy=id&sortOrder=de&page=${current}&pageSize=20&etatCompte=${action}`);
          setAlluser(response?.data?.Laibrairie);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      useEffect(() => {
        gteAlllab()
      }, [action,current]);
 
      const bloquecl=()=>{
     bloqueuser(iduser).then((res)=>{
        dispatch(getAlluser())
        setAnchorEl(null);
        toast.success("c'est client a bloque avec success", {autoClose: 2000, });
    })
}


  const Activ = Alluser?.filter(product => 
    product?.user?.etatCompte?.toLowerCase()?.includes("active")
  );
  const blog = Alluser?.filter(product => 
    product?.user?.etatCompte?.toLowerCase()?.includes("bloque")
  );


  return (
    <div className="pages-container1">

    <div className="header-ven">
   <div>   <p className="title-ven">Liste de Vendeurs</p></div>
    <div>
  

      </div>

    </div>


    <Box sx={{ width: '100%' }}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: { background: "#222" } }}>
       <Tab label={<p className="txttabs-c">Tout</p>} {...a11yProps(0)} />
        <Tab label={<p className="txttabs-c">Actif</p>} {...a11yProps(1)} />
        <Tab label={<p className="txttabs-c">Bloquée</p>} {...a11yProps(2)} />
      </Tabs>
    </Box>
 

    <TabPanel value={value} index={0}>  
    <Tablee  onClick={()=>{setaction("tout")}} handeldata2={handeldata2} current={current} totalpage={totalpage} accessToken={props.user.accessToken} data={Alluser} gteAlllab={gteAlllab}/>
    </TabPanel>
    
    <TabPanel value={value} index={1}>  
    <Tablee  handeldata2={handeldata2} current={current} totalpage={totalpage} onClick={()=>{setaction("tout")}} accessToken={props.user.accessToken} data={Activ}gteAlllab={gteAlllab} />
 </TabPanel>
 <TabPanel value={value} index={2}>  
 <Tablee  onClick={()=>{setaction("tout")}} handeldata2={handeldata2} current={current} totalpage={totalpage}  accessToken={props.user.accessToken} data={blog} gteAlllab={gteAlllab}/>
 </TabPanel>
    </Box>

         <Menu
              id="basic-menu"
              className="menu-avis"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem className="menuitem-avis" onClick={bloquecl}>
                <CloseCircle size="22" color="#626262" />
                <span className="spn-ad">
                Bloquer
                </span>
              </MenuItem>
  
              <MenuItem
                className="menuitem-avis"
                onClick={() => {
                  setop2(true);
                }}
              >
                <Trash size="22" color="#D64545" />
                <span>
                  <p className="spn-ad2">Suprimer</p>
                </span>
              </MenuItem>
            </Menu>
  </div>

  )
}

export default Vendeursadmin 
