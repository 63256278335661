import { createSlice } from "@reduxjs/toolkit";
import { getAllSubCategoriesByCategoryId, getAllSubCategories, addSubCategory, deleteSubCategory, updateSubCategory } from "./subCategoryService";

const initialState = {
  subCategoryList: {},
  subCategoryData: null,
  isloading: false,
  iserror: null,
};

const subCategoriesPending = (state) => {
  state.isloading = true;
};

const subCategoriesError = (state, action) => {
  state.iserror = action.payload;
};

export const subCategorySlice = createSlice({
  name: "subCategories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllSubCategories.pending, subCategoriesPending)
      .addCase(getAllSubCategoriesByCategoryId.pending, subCategoriesPending)
      .addCase(addSubCategory.pending, subCategoriesPending)
      .addCase(deleteSubCategory.pending, subCategoriesPending)
      .addCase(updateSubCategory.pending, subCategoriesPending)
      .addCase(getAllSubCategories.rejected, subCategoriesError)
      .addCase(addSubCategory.rejected, subCategoriesError)
      .addCase(deleteSubCategory.rejected, subCategoriesError)
      .addCase(updateSubCategory.rejected, subCategoriesError)
      .addCase(getAllSubCategoriesByCategoryId.rejected, subCategoriesError)
      .addCase(getAllSubCategories.fulfilled, (state, { payload }) => {
        state.subCategoryList = payload;
      })
      .addCase(getAllSubCategoriesByCategoryId.fulfilled, (state, { payload }) => {
        state.subCategoryList = payload;
      })
      .addCase(addSubCategory.fulfilled, (state, { payload }) => {
        state.subCategoryList = [...state.subCategoryList, payload];
      })
      .addCase(deleteSubCategory.fulfilled, (state, { payload }) => {
        state.subCategoryList = state.subCategoryList.filter(
          (el) => el.id !== payload
        );
      })
      .addCase(updateSubCategory.fulfilled, (state, { payload }) => {
        state.subCategoryList = state.subCategoryList.map((el) =>
          el.id === payload.id ? payload : el
        );
      });
  },
});

export default subCategorySlice.reducer