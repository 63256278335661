export  const Base_url="https://maktaba.opalerp.net/"
export const Path ={
    LoginApi:"user/login",
    SignupApi:"user/register",
    ContactApi:"user/contact",
    AddcodePromo:'codepromo/add',
    updateEtatAdmin:"codepromo/updateEtatAdmin",
    loginsocial:"user/loginsocial",
    forgotPasswordapi:"user/sendForgotPassword",
    resetpasswordApi:"user/forgotpassword/",
    getAllProduitlibrairie:"produitLabrairie/findAll",
    getinfolibrairie:"labrairie/findProfile/",
    getAllProduitbyLibrairie:"produitLabrairie/findBylabrairie/",
    getProduitDetail:"produitLabrairie/findOneProduit/",
    getAllAvisProduitDeatil:"avisProduitlibraire/getAllAvisByproduit/",
    addcommande:"commandeDetail/add",
    adminallcommande:"commandeDetail/allcommande",
    adminbnrcommande:"commandeDetail/commande30days",
    adminfindCommandeByall:"admin/findAllCommandes",
    adminfindCommandelivreByall:"admin/findAllLivraison",
    addAvis:"avisProduitlibraire/add",
    admintopproduits:"admin/top10product",
    addproduitfavorie:"produitFavorie/add",
    getAllproduitBycategorie:"produitLabrairie/findProduitsBycategorie/",
    addSignale:"signalerProduitlibraire/add",
    changePassword:"user/updatePassword/",
    identiteClient:"client/findOne/",
    modifierIdentiteClient:"user/updateIdentite/",
    editcategory:"admin/editcategory/",
    allproduits:"admin/allproduits",
    addAdrr:"adresses/add",
    addbypartnier:"adresses/addbypartnier",
    deleteAdrr:"adresses/delete",
    ModifAdrr:"adresses/update/",
    getproduitFavorie:"produitFavorie/findAllbyclient/",
    DeleteproduitFavorie:"produitFavorie/delete",
    getAllcommandeByclient:"commandeDetail/findcommandebyuser/",
    findCommandeidentifiant:"commandeDetail/findCommandeByidentifiant",
    getAllAvieByclient:"avisProduitlibraire/findAllbyclient/",
    deleteAvis:"avisProduitlibraire/delete/",
    ModifierAvis:"avisProduitlibraire/update/",
    findCommandeBylibrairie:"commandeDetail/findCommandeBylibrairie/",
    findCommandeByforni:"fournisseur/findAllCommandesbyfournisseur/",
    findCommandeBylibrairielivre:"labrairie/findAllLivraison/",
    findCommandeByadmindetail:"commandeDetail/findCommandeByadmindetail/",
    Detailcomonde:'commandeDetail/findOneCommande/',
    AddCommandespecial:"commandeDetail/addcommandespecial",
    AddCommandespecialinviter:"commandeDetail/addcommandespecialinviter",
    Commandespecial:"commandeDetail/findspeccommandebyuser/",
    commandlibpecial:"commandeDetail/findSpecCommandeBylibrairie/",
    findOneCommandeBypartnier:'commandeDetail/findOneCommandeBypartnier/',
    AccepteCommande:'commandeDetail/Accepter/',
    AnnulerCommande:'commandeDetail/Annuler/',
    LivreCommande:"commandeDetail/livre/",
    livreCommande:'commandeDetail/livre/',
    ProfileVender:'labrairie/findProfile/',
    UpdateProfilevender:'labrairie/updateProfile/',
    UpdateProfile:'fournisseur/updateProfile/',
    listProduitLib:'produitLabrairie/findBylabrairie/',
    allAvisLib:"avisProduitlibraire/avislib/",
    findLatestCommande:"labrairie/findLatestCommandes/",
    allAvipartnier:"avisProduitlibraire/findAllbyPartnier/",
    Ajoutproduit:"produitLabrairie/add",
    produitfournisseuradd:"produitfournisseur/add",
    Supproduit:"produitLabrairie/delete",
    Modifierprod:"produitLabrairie/update/",
    nbProduitLibBycategorie:"categorie/NbproduitlibBycategorie/",
    getAllProduitFornisseur:"produitfournisseur/findAll",
    Gategorie:"categorie/findCategorie",
    sousGategorie:"sousCategorie/findBycategorie/",
    ALLsousGategorie:"sousCategorie/findAll",
    addProduitCommande:"commandeDetail/addArticle",
    becomePartner:"becomePartner/add",
    deleteProduitCommande :"commandeDetail/deleteArticle/",
    AllPartnaire :"partenaire/findAll",
    addBonDachate:"bonAchat/add",
    allbonDachateUser :"bonAchat/findByuser/",
    addPoint : "user/addPoint/",
    produit_plus_vendus:"labrairie/findtopproduct/",
    nb_commande_par:"labrairie/findCommandeinday/",
    nbcommande:"commandeDetail/nb_commande/",
    demondeP:"becomePartner/findAll",
    AddLibrairie:"becomePartner/accepter/",
    AnnulerDemende:"becomePartner/Annuler/",
    AddFournisseur:"fournisseur/add",
    produit_mieux_note:"produitLabrairie/produit_mieux_notes/",
    prod_libmieux_note:"produitLabrairie/produit_mieux/",
    allProduitcataloge:"cataloge/findAll",
    updatecataloge:"cataloge/update/",
    oneProdCataloge :"cataloge/findOne/",
    addprod:"produitLabrairie/addProdCataloge",
    addProdCataloge :"cataloge/add",
    addProdCatalogeForni :"catalogefournisseur/add",
    deleteProdCataloge :"cataloge/delete/",
    Visible:"cataloge/changeVisibilite/",
    suggestionProduitadd:"suggestionProduit/add",
    suggestionProduitaddfroni:"suggestionproduitfournisseur/add",
    getsuggestion:"suggestionProduit/findAll",
    getAlluser:"user/findAll",
    getAlluseradmin:"admin/getallusers",
    findBypartenaire:"bonAchat/findByuser/",
    bloqueuser:"user/bloque/",
    deleteuser:"user/delete/",
    findOnePartnaire:"partenaire/findOnePartnaire/" ,
    findAllPartnaire:"partenaire/findAll" ,
    updateProfile:"partenaire/updateProfile/",
    updateProfileimge:"partenaire/updateProfileimge/",
    bonAchatfindBypartenaire:"bonAchat/findBypartenaire/",
    bonAchatfindBylibrairie:"bonAchat//findBylibrairie/",
    bonAchatupdateBypartenaire:"bonAchat/update/",
    bonAchatdelectBypartenaire:"bonAchat/delete/",
    findCategorie:"categorie/findCategorie",
    findCategorieadmin:'admin/allcategories',
    deleteCategorie:"categorie/deleteCategorie/",
    adminAddCategorie:"admin/addcategory",
    admindeleteCategorie:"admin/deletecategory",
    admindeletesuggestion:"admin/deletesuggestion",
    adminallusersrole:"admin/allusersrole",
    adminallcategories:"admin/allcategories",
    adminallproduits:"admin/allproduits",
    adminNombrecommandesVisiteurs:"commandeDetail/same-day",
    adminTotallAvis:"admin/getallavisuser/nombre_total_etoiles/",
    adminfindAlllibrarie:"labrairie/findAlllibrarie",
    adminMoyAvis:"admin/getavgavisuser/moyenne_avis/",
    categoryPath: "admin/allcategories",
    subCategoryPath: "sousCategorie/",
    productPath: "produitLabrairie/",
    adminPath: "admin/",
    reviewPath: "avisProduitlibraire/",
    addServiceInfo: "serviceInformatique/addServiceInfo",
    findAllServiceInfo: "serviceInformatique/findAllServiceInfo",
    AnullerServiceInfo: "serviceInformatique/annulerServiceInfo/",
    accepteServiceInfo: "serviceInformatique/accepterServiceInfo/",
    addDon: "don/addDon",
    annulerDon:'don/annulerDon/',
    accepterDon:"don/accepterDon/",
    filallDon:"don/findAllDon",
   Addechange:"echange/addEchange",
   Allechangebyvender:"echange/findEchangelaibrairie/",
   Anullerchange: "echange/accepterEchange/",
   acceptechange: "echange/annulerEchange/",
findAllEchange:"echange/findAllEchange",
addOffre:"offre/addOffre",
findOneFournisseur:"fournisseur/findOneFournisseur/",
}
