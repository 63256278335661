import axios from "axios"
import { createAsyncThunk} from '@reduxjs/toolkit';
import { Path, Base_url } from '../../config/Config';
export const getIdentiteClientt = createAsyncThunk(
    'getIdentiteClientt',
    async (data, { rejectWithValue })  => {
      const {id}=data
      try {
        const response = await axios.get(Base_url+ Path.identiteClient+data)
        return response.data
      } catch (err) {
        return rejectWithValue(err?.response?.data)
      }
    }
  )
