import React,{useEffect, useState} from 'react'
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import { Avatar, InputAdornment, Menu, MenuItem, OutlinedInput, Pagination, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { More, SearchNormal1, Trash } from 'iconsax-react';

import { Table } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Base_url, Path } from '../../../config/Config';

const Table1 = (props) => {
    const [id, setid] = React.useState();
    const [status, setstatus] = React.useState();

    const [point, setpoint] = React.useState([]);
    const handleClick = (e) => {
      setAnchorEl(e.currentTarget);
    }
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const styles = {
        tableCell: {
          color: "var(--neutral-400, #7E7E7E)",
          fontFamily: "Inter",
          fontSize:"14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "20px",
        },
      };
      const delect=async()=>{
            try {
              const response = await axios.delete(
                `${Base_url}${Path.bonAchatdelectBypartenaire}${id}`,
                {},
                {
                  headers: {
                    Authorization: "Bearer " + props?.props,
                  },
                }
              );
              props.handel(props.handel2 + 1);
              toast.success("Status changed successfully", { autoClose: 2000 });
            } catch (error) {
              toast.error("Error in delete:", { autoClose: 2000 });
            }
      }
      const update = async () => {
        try {
          const response = await axios.put(
            `${Base_url}${Path.bonAchatupdateBypartenaire}${id}`,
            {},
            {
              headers: {
                Authorization: "Bearer " + props?.props,
              },
            }
          );
          props.handel(props.handel2 + 1);
          toast.success("Status changed successfully", { autoClose: 2000 });
        } catch (error) {
          toast.error("Error in update:", { autoClose: 2000 });
        }
      };
      const formatDate = (inputDate) => {
        const dateObj = new Date(inputDate);
        const day = dateObj.getDate().toString().padStart(2, '0');
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObj.getFullYear().toString();
        return `${day}-${month}-${year}`;
      };
  return (
    <TableContainer component={Paper} style={{
        boxShadow: "none", borderWidth: " 1px 0px",
        borderStyle: "solid",
        borderColor: "#EEEDF2",
        overflow: "hidden"
    }}>
        <Table style={{ width: "95%" }} aria-label="simple table" >
            <TableHead >
                <TableRow >
                    <TableCell style={styles.tableCell}>Code</TableCell>
                    <TableCell style={styles.tableCell} align="left">Client</TableCell>
                    <TableCell style={styles.tableCell} align="left">Date de création</TableCell>
                    <TableCell style={styles.tableCell} align="left">Nbr de points</TableCell>
                    <TableCell style={styles.tableCell} align="left">Montant</TableCell>
                    <TableCell style={styles.tableCell} align="left">Date de validation</TableCell>
                    <TableCell style={styles.tableCell} align="left">Staut</TableCell>
                    <TableCell style={styles.tableCell} align="left"></TableCell>

                </TableRow>
            </TableHead>
            <TableBody >
                {props?.all?.map((row,rowIndex) => (
                    <TableRow
                        key={rowIndex}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                        <p className='tb-tp'> {row?.code}  </p>  
                        </TableCell>
                         <TableCell component="th" scope="row">

                            <div style={{display:"flex",gap:"1em",alignItems:"center"}}>
                       <Avatar src={row?.user?.avatar} className='img2-pv'/>
                            <div><p className='tb-tp'>   {row?.user?.fullname} </p> </div>

                                </div>

                        </TableCell>
     <TableCell component="th" scope="row"style={{borderRight: "none" }}> {row?.createdAt} </TableCell>
     <TableCell component="th" scope="row">
      {row?.nbpoint?row?.nbpoint:"--------"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row?.solde} Dt
                        </TableCell>
               
                        <TableCell component="th" scope="row">
                          {row.etat === "Non Valide"?formatDate(row?.updatedAt):"-----------"}
                        </TableCell>
     <TableCell component="th" scope="row"style={{borderRight: "none" }}> 
     <div
                                className={
                                  row.etat === "Non Valide"
                                  ? "Statutpnt"
                                  : "livrepnt"
                                }
                              >
                                {   
                                 row.etat === "Non Valide"?
                                "Non Valide"
                                :
                                "Valide"

                                }
                        
                              </div>
     </TableCell>
     <TableCell component="th" scope="row"style={{borderRight: "none" }}> 
     
     <More
    size="22"
    color="#222222"
    aria-controls={open ? "basic-menu" : undefined}
    aria-haspopup="true"
    aria-expanded={open ? "true" : undefined}
    onClick={(e) => {handleClick(e);setid(row?.id);setstatus(row?.etat) }}
    className="more-avis"
    />
     
     
      </TableCell>
    
                    </TableRow>
      
                        
                ))}
            </TableBody>
        </Table>

        <Menu

id="basic-menu"
className="menu-avis"
anchorEl={anchorEl}
open={open}
onClose={handleClose}
MenuListProps={{
    "aria-labelledby": "basic-button",
}}
>
  {status==="Valide"?
  <MenuItem className="menuitem-avis" onClick={()=>{update(); setAnchorEl(null)}}>
<div>Valide</div>
</MenuItem>
:null
  }

<MenuItem className="menuitem-avis" onClick={()=>{delect(); setAnchorEl(null)}}>
<div>
<p style={{color:"red"}}>
Supprimer
</p>
</div>
</MenuItem>

</Menu>
    </TableContainer>
  )
}

export default Table1
