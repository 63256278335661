import React, { useEffect } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Grid, OutlinedInput } from "@mui/material";
import "./Recapitulatif.css";
import { useDispatch, useSelector } from "react-redux";
import { AjouteCommande, AjouteCommandeforni } from "../../Store/Service/AjouteCommande";
import { toast } from "react-toastify";
import { useNavigate} from 'react-router-dom';
import { removeAll } from "../../Store/panier/panierSlice";
import { getprofileVender } from "../../Store/Service/getfrofileVender";

const Recapitulatif = (props) => {
  const [totalHT, settotalHT] = React.useState(0);
  const nav=useNavigate()
  const panier=useSelector(state=> state.Panier.panier)
  const commande = useSelector((state) => state.Commande.commande);
  const dispatch=useDispatch()
  const navigate = useNavigate();


  React.useEffect(() => {
    dispatch(getprofileVender(props.user?.id));
  }, [props.panier]);
  const venderData= useSelector(
    (state) => state.profileVender.getprofileVender
  );
  const valide = () => {
    if (props?.etatcompt === "active" || props?.authuser === false || props?.authuser === true) {
  
      if (props.panier.commandeDetails?.length !== 0) {
        let arry = [];
        let qt = true;
  
        for (let i = 0; i < props.panier.commandeDetails?.length; i++) {
          const commandeDetail = props.panier.commandeDetails[i];
          const produits = commandeDetail.produits;
  
          for (let j = 0; j < produits?.length; j++) {
            const produit = produits[j];
  
            // Find the matching product in the panier by id
            const matchingPanierItem = props.panier.commandeDetails[i].produits.find(item => item.prodlaibrcommdetfk === produit.prodlaibrcommdetfk);
  
            if (matchingPanierItem && matchingPanierItem.Allqte < produit.Qte) {
              arry.push(`${matchingPanierItem.titre} - Les quantités en stock : ${matchingPanierItem.Allqte}`);
              qt = false;
            }
          }
        }
  
        if (qt) {
          if (props.role === "labrairie") {
            const commandes = [];
            const groupedCommande = props.panier.commandeDetails.reduce((acc, commandeDetail) => {
              const produits = commandeDetail.produits;
  
              for (const produit of produits) {
                const { idl, newPrice, Qte, prodlaibrcommdetfk } = produit;
                if (!acc[idl]) {
                  acc[idl] = { produits: [], total_ttc: 0 };
                }
                acc[idl].produits.push({ prodfourcommgrosfk: prodlaibrcommdetfk, Qte: Qte });
  
                acc[idl].total_ttc += newPrice * Qte; // Assuming newPrice is the price per unit
              }
  
              return acc;
            }, {});
  
            for (const idl in groupedCommande) {
              const { produits, total_ttc } = groupedCommande[idl];
              commandes.push({
                produits,
                total_ttc,
                labrcomgrofk: props.user.id,
                fourcomgrofk: Number(idl)
              });
            }
  
            const data = {
              "commande": commandes
            };
  
            dispatch(AjouteCommandeforni({ data: data, access: props.user.accessToken })).then((response) => {
              toast.success("Commande a été passée avec succès", { autoClose: 2000 });
              dispatch(removeAll());
            }).catch(error => {
              // Handle the error accordingly
            });
  
          } else {
            nav("/Faire_une_commande");
          }
        } else {
          toast.error("Les quantités des produits suivantes sont insuffisantes : " + arry.join(', '), { autoClose: 2000 });
        }
  
      } else {
        toast.error("Vous n'avez aucun produit sur votre carte !!", { autoClose: 2000 });
      }
  
    } else {
      toast.error("Votre compte est bloqué. Contactez votre administrateur !!", { autoClose: 2000 });
    }
  };
  
  return (
    <div className="resume" data-aos="fade-left" data-aos-duration="2000">
      <h1 className="titre2">Récapitulatif</h1>
      <hr className="line2" />
      <div className="container">
        <div className="itemText">
          <p>Totale HT</p>
          <p>Remise</p>
          <p>Tax</p>
        </div>
        <div className="itemchiffer">
          <p>{props?.panier?.totalHT?.toFixed(3)} dt</p>
          <p>{(props?.panier?.newTotalremise-props?.panier?.totalHT)?.toFixed(3)} dt</p>
          <p>{props?.panier?.totaltva?.toFixed(3)}dt</p>
        </div>
      </div>

      <hr className="line2"></hr>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={0.5}
      >
        <p className="totalTTC">Total TTC</p>
        <p className="totalMontant">{(props?.panier?.newTotal )?.toFixed(3)} dt</p>
      </Grid>
      

 <button className="btn-verifie" onClick={valide}>
      Valider
      </button>
      
    </div>
  );
};
export default Recapitulatif;
