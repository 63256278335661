import axios from "axios"
import { createAsyncThunk} from '@reduxjs/toolkit';
import { Path, Base_url } from '../../config/Config';
export const getAllGategorie = createAsyncThunk(
    'getAllGategorie',
    async () => {
      try {
        const response = await axios.get(Base_url+ Path.findCategorieadmin)
  
        return response.data
      } catch (err) {
        
      }
    }
)