import React, { useEffect,useState } from "react";
import './Gestion_pointsF.css'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Filterbar from '../../filterbar/Filterbar';
import {Sort} from "iconsax-react";

import Table from "./table"
import { Base_url, Path } from "../../../config/Config";
import axios from "axios";
import { Pagination } from "@mui/material";

const Gestion_pointsF = (props) => {
    const [value, setValue] = React.useState(0);
    const handleClose = () => {
      setAnchorEl(null);
    };
    const [current,setCurrent]=useState(1)
    const [totalPages, settotalPages] =useState();
    function handlePagination (event,page) {
      setCurrent(page)
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
      const handell = (data) => {
        sethandel(data);
        };
        const [handel, sethandel] = React.useState(1);
      const [all, setAll] = React.useState([]);
      const [stat, setstat] = React.useState("");

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <div>{children}</div>
              </Box>
            )}
          </div>
        );
      }
      const Pointsp = async () => {
        try {
          const response = await axios.get(Base_url + Path.bonAchatfindBylibrairie + props?.user.id+`?page=${current}&pageSize=10&sortBy=createdAt&sortOrder=desc&etat=${stat}`
            ,{
              headers: {
                Authorization: "Bearer " + props.user.accessToken,
              },
            })
          
          ;
          setAll(response?.data.bonAchat );
          settotalPages(response?.data.totalPages)
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }
      useEffect(() => {
        Pointsp()
      }, [handel,current,stat]);
  return (
    <div className='Approvisionner'>
      <div className='row1-Appr'>
        <div><p className='txt1-Appr'>Gestion de points</p></div>
      </div>
      <div className='box01-Appr'>
            <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: { background: "#F7D070" } }}>
         <Tab onClick={()=>{setstat("");setCurrent(1)}} label={<p className="txttabs-Appr">Tout</p>} {...a11yProps(0)} />
          <Tab onClick={()=>{setstat("Valide");setCurrent(1)}} label={<p className="txttabs-Appr">Valide</p>} {...a11yProps(1)} />
          <Tab onClick={()=>{setstat("Non Valide");setCurrent(1)}} label={<p className="txttabs-Appr">Non valide</p>} {...a11yProps(2)} />

        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
      <br/>
      <div className='row01-Appr'>
      <Filterbar icon={"none"}nom="Trier par"/>
<Table all={all} handel={handell} handel2={handel} props={props?.user?.accessToken}/>
      </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
      <br/>
      <div className='row01-Appr'>
      <Filterbar icon={  <Sort size="22" color="#222222"/> }nom="Trier par"/>
<Table all={all} handel={handell} handel2={handel}props={props?.user?.accessToken}/>
      </div>
        </TabPanel>
        <TabPanel value={value} handel2={handel}index={2}>
      <br/>
      <div className='row01-Appr'>
      <Filterbar icon={  <Sort size="22" color="#222222"/> }nom="Trier par"/>
<Table all={all} handel={handell} props={props?.user?.accessToken}/>
      </div>
        </TabPanel>
    
    </Box>
    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
    <Pagination
                  count={totalPages}
                  shape="rounded"
                  className="pagination-shop"
                  page={current}
                  onChange={handlePagination}
            />
    </div>
    </div>

    </div>
  )
}



export default Gestion_pointsF
