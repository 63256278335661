import axios from "axios"
import { createAsyncThunk} from '@reduxjs/toolkit';
import { Path, Base_url } from '../../config/Config';
export const getprofileVender = createAsyncThunk(
    'getfrofileVender',
    async (id) => {
      try {
        const response = await axios.get(Base_url+ Path.ProfileVender+id)
        return response.data
      } catch (err) {
        
      }
    }
)

export const getprofileForni = createAsyncThunk(
  'getprofileForni',
  async (id) => {
    try {
      const response = await axios.get(Base_url+ Path.findOneFournisseur+id)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }
)