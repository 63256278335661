import axios from "axios";
import { Path, Base_url } from "../../config/Config";
export const AccepterCommande = async ({id,accessToken}) => {
  try {
    const response = await axios.put(Base_url + Path.AccepteCommande+id,{},{
      headers: {
        Authorization: "Bearer " +accessToken,
      },
    });
    return response.data;
  } catch (err) {
  }
};
export const AnnulerCommande = async ({id,data,accessToken}) => {
  try {
    const response = await axios.put(Base_url + Path.AnnulerCommande+id,data,{
      headers: {
        Authorization: "Bearer " +accessToken,
      },
    });
    return response.data;
  } catch (err) {
    
  }
};
export const LivreCommande = async ({id,accessToken}) => {
  try {
    const response = await axios.put(Base_url + Path.LivreCommande+id,{},{
      headers: {
        Authorization: "Bearer " +accessToken,
      },
    });
    return response.data;
  } catch (err) {
    
  }
};


export const AccepterCommandeforni = async ({id,accessToken}) => {
  try {
    const response = await axios.put(Base_url +"fournisseur/acceptercommande/"+id,{},{
      headers: {
        Authorization: "Bearer " +accessToken,
      },
    });
    return response.data;
  } catch (err) {
  console.error(err)
  }
};

export const LivreCommandeforni = async ({id,accessToken}) => {
  try {
    const response = await axios.put(Base_url + "fournisseur/livrercommande/"+id,{},{
      headers: {
        Authorization: "Bearer " +accessToken,
      },
    });
    return response.data;
  } catch (err) {
    
  }
};
