import React from 'react';
import './ResponsFotter.css';
import Imge from '../../../assets/logo.png';
import { Link } from 'react-router-dom';
import { Call, Location, Sms } from 'iconsax-react';

const ResponsFotter = () => {
  const data = [
    { name: 'Liens Rapides' },
    { name: 'À propos', link: '/AboutUs' },
    { name: 'Shop', link: '/Shop' },
    { name: 'Devenir partenaire', link: '/BecomePartner' },
    { name: '' },
    { name: 'Nos Catégories' },
    { name: 'Scolaire' },
    { name: 'Para-scolaires' },
    { name: 'Outils informatiques' },
    { name: 'Divers' },
    { name: 'Jeux educatifs' },
  ];
  const data2 = [
    { icon: <Location size="28" color="#d9e3f0" variant="Bold" />, name: '122 Rue de Makthar' },
    { icon: <Call size="28" color="#d9e3f0" variant="Bold" />, name: '(+216) 20 222 222' },
    { icon: <Sms size="28" color="#d9e3f0" variant="Bold" />, name: 'contact@maktba.com' },
  ];

  return (
    <div className="resf">
      <img src={Imge} alt="logoo" style={{ marginTop: '13%' }} />
      <div>
        <p className="txtresf1">
          Maktaba.tn est une plateforme de commerce électronique innovant reliant les réseaux de librairies,
          fournisseurs, écoles et espaces étudiants en Tunisie, offrant une solution tout-en-un pour tous leurs besoins.
        </p>
      </div>
      <div className="colrespof">
        <Link to="#">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAhklEQVR4nO3TsQ3CMBBA0RcxQSpqZqBniEzAVrTswAZMQZOWEuoU6BCtJZCCsA3CX7r6SXc2rS+vwxZHXHHDBSPWOeE94slscqHDCzQrfKgFnxNoh16BpgReKlQk09WCsxUzZ1UDnrCoAZ8+hc6FH3/89x9XWoOLFe3GpYq26lLF36265Z3ugcmLNVEqKJcAAAAASUVORK5CYII="
            className="fa fa-facebook-f"
          />
        </Link>
        <Link to="#">
          <img
            className="fa fa-twitter"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABWUlEQVR4nOXUMUscURQF4C+RVEmjhSAJimBlF2xESZNAVLBYRCxEbLWxsUkhEdMIKdKkSSBN0vgDtLBKCnsLLdxCm+gKNhFEgi5u3LAwA8sww7xhp0oO3GJ495xz5973Lv8KHhbMf4INHKOOGj7hWVvOMPrijw94GSjegwM0U+IX3uE79vAgJp3hGpMBBlsZ4u1RxRQqMekmOmhgHY8yxHvxJ8DgDufoj4nVRMIRZtGVMHgVIB7zB9qJaxmJNXzEDIYwEWjwNvnrj7EfSG4GxHLS4ATb0QzKMKgkDX6UWH0z2f8WxnFfkng1636/KcnkfZZB6+5P47AD8Vs8zTLoxlWH1X+Wg6UO2vQzKjIXC9FuKiLewIsQ8fZ5LOJ3gHg9WivBGMHXaGE1c6I1s9d5gmP4gl1cFGjLTtqDysIgNnGaI3qJbxgt0pI0szmsRI9vFfN4nrK+/wP8BZuBFXDRPn1vAAAAAElFTkSuQmCC"
          />
        </Link>
        <Link to="#">
          <img
            className="fa fa-instagram"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABWUlEQVR4nO2WwU7CQBCGv7t4A05y5ih6EDH6Dkp8IJEnUOQhMCQ8iehD6E1NWz3IQTEb/yakobutmRoP/Mkkzc7MfpnJdLKw0T9QExgC98A7sCxoLnau3EZZ6DmQlIDlWQz0y0C/DKCpfRaBN40qzVoUavvQCHQEHGfOBj7wgxHYQU8yZ3MfONTmD+AK6AJbMvc9AhaB3MQH9iU+Arue3I5ifHfkyldpCm0BU1XgbAa05dsLVJ6rvATX3hT6ssb/Kp/TjSX4QP6pJ+ZWMYeW4FqB4XNbymn7r8FRFeCu/DNPzEQxPUvwtfxtDVLW/wzsKGZs/Tt1FNPSIMWyyQp03/p3Wmo5pPB1ctCnwB2/XpkLrceeBq6m73GBlRnnY39eG8uK7M4HvqwQfOEDN9QSa2gE1Amor+eKFdTddRqCrsIjo0rPikJT1fVccUPxVgKWKGdQpL0bUbW+AcdI0V4wAXsCAAAAAElFTkSuQmCC"
          />
        </Link>
        <Link to="#">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA4ElEQVR4nO2WvQ4BQRSFvxU6P4VG5wU0KioqiQ3eQu81VJ7DA+h0RFTeQLUrohSFiLArktlkTGOLmVk/e5JT3HPvzZfMTDGQ6h/VA3ZAqMk+4MYB+xqhkb044NCQ30pdOAJL4GwTfAKqIq8DN1vgtdLzbIEvQEPkAyCwecd3YJ/E4xoLT0R9kLIZsAVGQB8YAitdYEfkeVFvpNkmUFL2M8DcNDhSmVd1TIMdYCHyqZRXTINr0twVyIo8ZxrcUmYLUi8wCW4rs8UUzDcedajJnwv2k/r6uJrhT2g3DjjVb+kBRsV7Hz5Qag0AAAAASUVORK5CYII="
            className="fa fa-linkedin-in"
          />
        </Link>
      </div>

      {data?.map((item, index) => (
        <div
          key={index}
          className={
            item?.name === 'Liens Rapides' || item?.name === 'Nos Catégories'
              ? 'txtref3'
              : 'txtref2'
          }
        >
          {item.link ? (
            <Link to={item.link} className="white-link">
              {item.name}
            </Link>
          ) : (
            <p>{item.name}</p>
          )}
        </div>
      ))}
      <div className="txtref3">
        <p>Contact</p>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
        {data2?.map((item, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '0em',
            }}
          >
            {item?.icon}
            <div className={'txtref2'} style={{ marginLeft: '1em' }}>
              <p>{item?.name}</p>
            </div>
          </div>
        ))}
      </div>
      <div>
        <hr className="lineres" />
      </div>
      <div className="txtref4">
        <p>Copyright © 2024 Maktaba.tn</p>
        <br />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div>Created by seifbenaicha</div>
        </div>
      </div>
    </div>
  );
};

export default ResponsFotter;
