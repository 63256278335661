import axios from "axios"
import { createAsyncThunk} from '@reduxjs/toolkit';
import { Path, Base_url } from '../../config/Config';
export const getsuggestion = createAsyncThunk(
    'getsuggestion',
    async (data) => {
      try {
        const response = await axios.get(Base_url+ Path.getsuggestion+`?page=${data.current}&pageSize=10&sortBy=id&sortOrder=a`)
        return response.data
      } catch (err) {
        
      }
    }
)