import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import './Filtrer_produits.css';
import { Refresh2 } from 'iconsax-react';

const Filtrer_produits = ({ onData, isopen,handleDataFromChildopen}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#FFFFFF",
    width: "360px",
    height: "550px",
    boxShadow: "2px 5px 15px rgba(26, 31, 39, 0.02), 10px 15px 40px rgba(26, 31, 39, 0.03)",
    borderRadius: "8px",
    p: 4,
  };

  const [date, setDate] = useState("Les plus récent");
  const [montant, setMontant] = useState("Par défaut");

  const handleClose = () => onData(false);
  const open = isopen;

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleMontantChange = (event) => {
    setMontant(event.target.value);
  };

  const handleApply = () => {
    const filterData = {};

    if (date === "Les plus récent") {
      filterData.sortBy = "createdAt";
      filterData.sortOrder = "Asc";
    } else if (date === "Les plus anciens") {
      filterData.sortBy = "createdAt";
      filterData.sortOrder = "DESC";
    }

    if (montant === "Du plus élevé au plus bas") {
      filterData.sortBy = "total_ttc";
      filterData.sortOrder = "Asc";
    } else if (montant === "Du plus bas au plus élevé") {
      filterData.sortBy = "total_ttc";
      filterData.sortOrder = "DESC";
    }
     handleDataFromChildopen(false)
    onData(filterData);
  };

  const handleReset = () => {
    setDate("Les plus récent");
    setMontant("Par défaut");
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Box sx={style}>
        <div className='container-modal'>
          <div><h2 style={{ textAlign: "center", marginTop: '-1%' }}>Trier par</h2></div>

          <div><p className="txtbnt-mf">Date</p></div>
          <div className="minirow-mf">
            <div className="txtwidthbnt-modal"><p className="txtbnt-mf1">Les plus récent</p></div>
            <div className="radi-modal"><input type="Radio" className="radio-mf1" name="r1" value="Les plus récent" checked={date === "Les plus récent"} onChange={handleDateChange} /></div>
          </div>
          <div className="minirow-mf">
            <div className="txtwidthbnt-modal"><p className="txtbnt-mf1">Les plus anciens</p></div>
            <div className="radi-modal"><input type="Radio" className="radio-mf1" name="r1" value="Les plus anciens" checked={date === "Les plus anciens"} onChange={handleDateChange} /></div>
          </div>

          <div><p className="txtbnt-mf2">Montant de commandes</p></div>
          <div className="minirow-mf">
            <div className="txtwidthbnt-modal"><p className="txtbnt-mf1">Par défaut</p></div>
            <div className="radi-modal"><input type="Radio" className="radio-mf1" name="r2" value="Par défaut" checked={montant === "Par défaut"} onChange={handleMontantChange} /></div>
          </div>
          <div className="minirow-mf">
            <div className="txtwidthbnt-modal"><p className="txtbnt-mf1">Du plus élevé au plus bas</p></div>
            <div className="radi-modal"><input type="Radio" className="radio-mf1" name="r2" value="Du plus élevé au plus bas" checked={montant === "Du plus élevé au plus bas"} onChange={handleMontantChange} /></div>
          </div>
          <div className="minirow-mf">
            <div className="txtwidthbnt-modal"><p className="txtbnt-mf1">Du plus bas au plus élevé</p></div>
            <div className="radi-modal"><input type="Radio" className="radio-mf1" name="r2" value="Du plus bas au plus élevé" checked={montant === "Du plus bas au plus élevé"} onChange={handleMontantChange} /></div>
          </div>

          <br />
          <div>
            <button className="bnt-modal3" onClick={handleReset}>
              <div><Refresh2 size="25" color="#222" /></div>
              <div><p className="txtbnt-modal32">Réinitialiser</p></div>
            </button>
          </div>
          <div>
            <button className="bnt2-modal23" onClick={handleApply}>Appliquer</button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default Filtrer_produits;
