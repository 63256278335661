import axios from "axios"
import { createAsyncThunk} from '@reduxjs/toolkit';
import { Path, Base_url } from '../../config/Config';
export const getNbProduitlibBycategorie = createAsyncThunk(
    'getNbProduitlibBycategorie',
    async (data, { rejectWithValue }) => {
      const{id}=data
console.log(id)
      try {
        const response = await axios.get(Base_url+ Path.nbProduitLibBycategorie+id)
        return response.data
      } catch (err) {
        return rejectWithValue(err?.response?.data)
      }
    }
  )