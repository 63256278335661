import axios from "axios";
import { Path, Base_url } from "../../config/Config";
export const Modifierprod = async ({id,data,access}) => {
  try {
    const response = await axios.put(Base_url+Path.Modifierprod+id,data,{
      headers: {
        Authorization: "Bearer " + access,
      },
    });
    return response.data;
  } catch (err) {
    
  }
};
export const Modifierprodforni = async ({id,data,access}) => {
  try {
    const response = await axios.put(Base_url+'produitfournisseur/update/'+id,data,{
      headers: {
        Authorization: "Bearer " + access,
      },
    });
    return response.data;
  } catch (err) {
    
  }
};