import axios from "axios"
import { createAsyncThunk} from '@reduxjs/toolkit';
import { Path, Base_url } from '../../config/Config';
export const getOneProdCataloge = createAsyncThunk(
    'getOneProdCataloge',
    async (id) => {
      try {
        const response = await axios.get(Base_url+ Path.oneProdCataloge+id)
        return response.data
      } catch (err) {
        
      }
    }
  )

  export const getOneProdCatalogeFotrni = createAsyncThunk(
    'getOneProdCatalogeFotrni',
    async (id) => {
      try {
        const response = await axios.get(Base_url+ "catalogefournisseur/findOne/"+id)
        return response.data
      } catch (err) {
        
      }
    }
  )