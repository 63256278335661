import axios from "axios";
import { Path, Base_url } from "../../config/Config";
export const supprimerprod = async (data, { access }) => {
  console.log("Access Token:", access);
  try {
    const response = await axios.delete(Base_url + Path.Supproduit, {
      headers: {
        Authorization: `Bearer ${access}`, // Ensure the correct format
        authorization: `Bearer ${access}`, // Including both cases just in case
      },
      data: data, // data should be inside the same object as headers
    });
    return response.data;
  } catch (err) {
    if (err.response) {
      // If the error response exists, log the server response
      console.log("Error:", err.response.data.msg);
    } else {
      // If there is no response, log the error
      console.log("Error:", err.message);
    }
  }
};

export const supprimerprodforni = async (data,{access}) => {
  try {
    const response = await axios.delete(Base_url+"produitfournisseur/delete",{data:data},{
      headers: {
        Authorization: "Bearer " + access,
      },
    });
    return response.data;
  } catch (err) {
    console.log(err.response.data.msg)
  }
};