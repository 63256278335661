
import axios from "axios";
import { Path, Base_url } from "../../config/Config";

export const bloqueuser = async ({id,accessToken}) => {

    try {
      const response = await axios.put(Base_url + Path.bloqueuser+id,{},{
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      });
      return response.data;
    } catch (err) {
  
    }
  };
  