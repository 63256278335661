import React, { useState } from 'react'
import {
    Avatar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@mui/material";
  import Paper from "@mui/material/Paper";

const Tablee = (props) => {
  return (
<div className='tab'>
<TableContainer
    component={Paper}
    style={{
      boxShadow: "none",
      borderWidth: " 1px 0px",
      borderStyle: "solid",
      borderColor: "#EEEDF2",
      width: "100%" 
    }}
  >
    <Table sx={{ width: "100%" }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>
            <p className="tableCell">#</p>
          </TableCell>
          <TableCell align="left">
            <p className="tableCell">Nom</p>
          </TableCell>
          <TableCell align="left">
            <p className="tableCell">prix</p>
          </TableCell>
          <TableCell>
            <p className="tableCell">Email adresse</p>
          </TableCell>
          <TableCell align="left">
            <p className="tableCell">Qté</p>
          </TableCell>
          <TableCell align="left">
            <p className="tableCell">Statut</p>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {
     props?.data?.[0]?.length?
      props?.data?.map((item, index) => (
   <TableRow key={index} >
   <TableCell >
   {item?.id}
   </TableCell >
   <TableCell >
   <div className="row-int01">

<img src={item.imagelibrairies?.[0]?.name_Image} className="img1-int" />
<div >
  <p className="txt01-prodd">{item.titre}</p>
</div>
</div>
   </TableCell>
   <TableCell >
   <p className="txt-tab3">{item?.prix}</p>
   </TableCell>
   <TableCell >
   {item?.categorie?.name}
   </TableCell>
   <TableCell >
  <p className='txt-tab2'>{item?.qte}</p>
   </TableCell>
   <TableCell>
   {item?.updatedAt}
   </TableCell>
   <TableCell>

   </TableCell>




 </TableRow>

      ))
    
      :null
    }
   
   
      </TableBody>
    </Table>
  </TableContainer>

</div>
  )
}

export default Tablee
