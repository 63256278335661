import axios from "axios"
import { createAsyncThunk} from '@reduxjs/toolkit';
import { Path, Base_url } from '../../config/Config';
export const getAllProduitCataloge = createAsyncThunk(
    'getAllProduitCataloge',
    async (data, { rejectWithValue }) => {
      try {
     
        const response = await axios.get(`${Base_url}${Path.allProduitcataloge}?sortBy=id&sortOrder=desc${"&page="+data?.current}${data.pageSize?"&pageSize="+data.pageSize:"&pageSize=30"}${data.cat!=="1"?"&category="+data.cat:''}${data.subcategory?"&subcategory="+data.subcategory:''}${data.titre?"&titre="+data.titre:''}`)
        return response.data
      } catch (error) {
        return rejectWithValue(error?.response?.data)
      }
    }
)
export const getAllProduitCatalogeAdmin = createAsyncThunk(
  'getAllProduitCatalogeAdmin',
  async (data, { rejectWithValue }) => {
    try {
   
      const response = await axios.get(`${Base_url}admin/findAll?sortBy=id&sortOrder=desc${"&page="+data?.current}${data.pageSize?"&pageSize="+data.pageSize:"&pageSize=30"}${data.cat!=="1"?"&category="+data.cat:''}${data.subcategory?"&subcategory="+data.subcategory:''}${data.titre?"&titre="+data.titre:''}`)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)
export const getAllProduitCatalogeforni = createAsyncThunk(
  'getAllProduitCatalogeforni',
  async (data, { rejectWithValue }) => {
    try {
   
      const response = await axios.get(`${Base_url}catalogefournisseur/findAll?sortBy=id&sortOrder=desc${"&page="+data?.current}${data.pageSize?"&pageSize="+data.pageSize:"&pageSize=30"}${data.cat!=="1"?"&category="+data.cat:''}${data.subcategory?"&subcategory="+data.subcategory:''}${data.titre?"&titre="+data.titre:''}`)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)