import React, { useEffect,useState } from "react";
import './Partenairesadmin .js'
import'./Partenaires.css'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Trash,CloseCircle} from "iconsax-react";
import { useDispatch } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getAlluser } from "../../../../Store/Service/getAlluser.js";
import { bloqueuser } from "../../../../Store/Service/actionuser.js";
import { Base_url, Path } from "../../../../config/Config.js";
import axios from "axios";
import Tablee from "./Table/Table.js";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const Partenairesadmin  = (props) => {
    const [ref, setref] = React.useState(0);
    const [op2, setop2] = React.useState(false);
    const [iduser ,setIduser] = useState()
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
      const handeldata=(data)=>{
        setref(data)
      }
      const handeldata2=(data)=>{
        setCurrent(data)
      }
      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);
      const handleClose = () => {
        setAnchorEl(null);
      };
      const bloquecl=()=>{
        bloqueuser(iduser).then((res)=>{
        dispatch(getAlluser())
        setAnchorEl(null);
        toast.success("c'est client a bloque avec success", {autoClose: 2000, });
    })
      }
      const [action,setaction]=useState('tout')
      const [current,setCurrent]=useState(1)
      const [totalpage,settotalpage]=useState(1)
      const [all, setAll] =useState([]);

      
      const AllFavorisp = async () => {
    try {
      const response = await axios.get(Base_url + `admin/findallpartenaireadmin?sortBy=id&sortOrder=de&page=${current}&pageSize=20&etatCompte=${action}`);
      setAll(response?.data?.Partenaire);
      settotalpage(response?.data?.totalPages)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    AllFavorisp()
  }, [ref,action,current]);

  return (
    <div className="pages-container1">
    <div className="header-page-categorie">
   <div><h1 className="title-page-categorie">Liste de Partenaires</h1></div>
    </div>
    <Box sx={{ width: '100%' }}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: { background: "#222" } }}>
       <Tab onClick={()=>{setaction("tout")}} label={<p className="txttabs-c">Tout</p>} {...a11yProps(0)} />
        <Tab  onClick={()=>{setaction("Active")}}label={<p className="txttabs-c">Actif</p>} {...a11yProps(1)} />
        <Tab onClick={()=>{setaction("Bloque")}} label={<p className="txttabs-c">Bloquée</p>} {...a11yProps(2)} />
      </Tabs>
    </Box>
    <TabPanel value={value} index={0}>  
    <Tablee data={all}handeldata2={handeldata2} current={current} totalpage={totalpage}accessToken={props.user.accessToken} handeldata={handeldata} reff={ref}/>
    </TabPanel>
    <TabPanel value={value} index={1}>  
    <Tablee data={all} handeldata2={handeldata2} current={current} totalpage={totalpage} accessToken={props.user.accessToken} handeldata={handeldata} reff={ref}/>
 </TabPanel>
 <TabPanel value={value} index={2}>  
 <Tablee data={all} handeldata2={handeldata2} current={current} totalpage={totalpage} accessToken={props.user.accessToken} handeldata={handeldata} reff={ref}/>
 </TabPanel>

    </Box>
         <Menu
              id="basic-menu"
              className="menu-avis"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem className="menuitem-avis" onClick={bloquecl}>
                <CloseCircle size="22" color="#626262" />
                <span className="spn-ad">
                Bloquer
                </span>
              </MenuItem>
  
              <MenuItem
                className="menuitem-avis"
                onClick={() => {
                  setop2(true);
                }}
              >
                <Trash size="22" color="#D64545" />
                <span>
                  <p className="spn-ad2">Suprimer</p>
                </span>
              </MenuItem>
         </Menu>
  </div>

  )
}

export default Partenairesadmin 
