import axios from "axios"
import { createAsyncThunk} from '@reduxjs/toolkit';
import { Path, Base_url } from '../../config/Config';
export const AllListProduitLibe = createAsyncThunk(
    'AllListProduitLibe',
    async (data, { rejectWithValue }) => {
      try {
        const response = await axios.get(Base_url+ Path.listProduitLib+data.id+`?&page=${data.currentt}&pageSize=20&sortBy=id&sortOrder=desc&titre=${data.titre}`)
        return response.data
      } catch (err) {
        return rejectWithValue(err?.response?.data)
      }
    }
)

export const AllListProduitforni = createAsyncThunk(
  'AllListProduitforni',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(Base_url+"produitfournisseur/findByfournisseur/"+data.id+`?&page=${data.currentt}&pageSize=20&sortBy=createdAt&sortOrder=desc&titre=${data.titre}`)
      return response.data
    } catch (err) {
      return rejectWithValue(err?.response?.data)
    }
  }
)