import { createSlice } from "@reduxjs/toolkit";
import { getTopReviews } from "./reviewService";

const initialState = {
  reviewList: {},
  reviewData: null,
  loading: false,
  error: null,
};

const reviewPending = (state) => {
  state.loading = true;
};

const reviewError = (state, action) => {
  state.error = action.payload;
};

export const reviewSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTopReviews.pending, reviewPending)
      .addCase(getTopReviews.rejected, reviewError)
      .addCase(getTopReviews.fulfilled, (state, { payload }) => {
        state.reviewList = payload;
      })
  },
})

export default reviewSlice.reducer;
