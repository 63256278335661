import axios from "axios"
import { createAsyncThunk} from '@reduxjs/toolkit';
import { Path, Base_url } from '../../config/Config';
export const nb_commande_par_jour = createAsyncThunk(
    'nb_commande_par_jour',
    async (data) => {
        try {
          const response = await axios.get(Base_url+Path.nb_commande_par+data.id+`?days=${data.day}`);
        return response.data
        } catch (err) {
          
        }
    }
  )