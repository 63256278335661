import axios from "axios"
import { createAsyncThunk} from '@reduxjs/toolkit';
import { Path, Base_url } from '../../config/Config';
export const getAllProduitBylibrairie = createAsyncThunk(
    'getAllProduitBylibrairie',
    async (data, { rejectWithValue }) => {
      const {SouscategorieId,current,prixMax,qteMin,qteMax,etat,titre,prixMin,cat,sortBy,sortOrder,id}=data

      try {
        const response = await axios.get(Base_url+ Path.getAllProduitbyLibrairie+id+`?${SouscategorieId && SouscategorieId.length ? `SouscategorieId=${SouscategorieId.join(',')}` : ''}${prixMin ? `&prixMin=${prixMin}` : ''}${prixMax ? `&prixMax=${prixMax}` : ''}${qteMin ? `&qteMin=${qteMin}` : ''}${qteMax ? `&qteMax=${qteMax}` : ''}${etat? `&etat=${etat}` :cat==="etat=remise"?`&etat=remise` :''}${titre ? `&titre=${titre}` : ''}${current ? `&page=${current}` : `&page=1`}${cat && cat!=="etat=remise" ? `&categprodlabfk=${cat}` : ''}&pageSize=20${sortBy ? `&sortBy=${sortBy}` : '&sortBy=createdAt'}${sortOrder ? `&sortOrder=${sortOrder}` : '&sortOrder=desc'}`)
        return response.data
      } catch (error) {
        return rejectWithValue(error?.response?.data)
      }
    }
  )