import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Base_url, Path } from "../../config/Config";

const { subCategoryPath } = Path;

export const getAllSubCategories = createAsyncThunk(
  "subCategories/get_all_sub_categories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${Base_url}${subCategoryPath}findAll`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllSubCategoriesByCategoryId = createAsyncThunk(
  "subCategories/get_all_sub_categories_by_category_id",
  async (data, { rejectWithValue }) => {
    const { id } = data;
    try {
      const response = await axios.get(
        `${Base_url}${subCategoryPath}findBycategorie/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addSubCategory = createAsyncThunk(
  "categories/add_sub_category",
  async (subCategoryData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${Base_url}${subCategoryPath}add`,
        subCategoryData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSubCategory = createAsyncThunk(
  "categories/delete_sub_category",
  async (data, { rejectWithValue }) => {
    const { id } = data;
    try {
      const response = await axios.delete(
        `${Base_url}${subCategoryPath}deleteSousCategorie/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateSubCategory = createAsyncThunk(
  "categories/update_sub_category",
  async (data, { rejectWithValue }) => {
    const { id, subCategoryData } = data;
    try {
      const response = await axios.put(
        `${Base_url}${subCategoryPath}updateSousCategorie/${id}`,
        subCategoryData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);