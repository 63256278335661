import React, { useEffect,useState } from "react";
import './cardlisteprod.css'
import {Visible, Visibleforni, Visiblein} from '../../../Store/Service/Visiblein'
import {Eye,EyeSlash} from "iconsax-react";
import { getAllProduitCataloge, getAllProduitCatalogeAdmin } from "../../../Store/Service/allProduitCataloge";
import { useDispatch } from "react-redux";
import { getallProducts } from "../../../Store/products/productService";
const Cardlisteprod = (props) => {
  const [state, setState] = useState(true)
  const [vs, setvs] = useState(props.Visibetat)
  const dispatch = useDispatch();
  const updateVisible = (id,vis) => {
   let data={
      "etat":vis
    }

    if(props.title==="forni"){
  
        Visibleforni(id,data,props.accessToken).then((res) => {
          dispatch(getallProducts({
            current:props.current,
            titre:props.searchValue,
            sortBy:"titre",
            sortOrder:"desc",
            cat:props.categorieId,
           })
         )
        });
     
    }else{
      if(props.action==="prod")
      {
        Visible(id,data,props.accessToken).then((res) => {
          dispatch(getallProducts({
            current:props.current,
            titre:props.searchValue,
            sortBy:"titre",
            sortOrder:"desc",
            cat:props.categorieId,
           })
         )
        });
      }else{

        Visiblein(id,data,props.accessToken).then((res) => {
          dispatch(getAllProduitCatalogeAdmin({cat:"1",titre:null,current:1,pageSize:"20"}))
        });
      }
    }
     




  };
  return (
    <div className='prod-lst'>
      {props?.Role=="admin" ?
      props.Visibetat!=="invisible"?
        <div className='Eye' onClick={()=>{setState(false);updateVisible(props.id,"invisible");setvs("invisible")}}>
        <Eye size="22" color="#05400A"  variant="Bold"/>
        </div>
       :
       <div className='Eye2' onClick={()=>{setState(true);updateVisible(props.id,"visible");setvs("visible")}}>
       <EyeSlash size="22" color="#610404"  variant="Bold"/>
       </div>
      :
      ""
      }
      {props?.Role=="admin"?
         <img src={props.img} className='img-lst2' onClick={props.toggleDrawer('right', true,props.id)} />
      :
      <img src={props.img} className='img-lst' />
      }
      <div>
        <p className='txt-lst'>#{props.id}</p>
        </div>
        <div>
        <p className='txt-lst2'>{props.titre}</p>
        </div>
    </div>
  )
}

export default Cardlisteprod
