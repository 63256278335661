import React from "react";
import "./Spinier.css";
import Logo from "../../assets/logo2.png";

const Spinier = () => {
  return (
    <div className="spinier">
      <div>
        <img src={Logo} className="img-spi" />{" "}
      </div>
      <div>
        <div>Vous serez redirigé dans quelques secondes</div>
      </div>

      <div className="container-spi">
        <div className="progress2 progress-moved-spi">
          <div className="progress-bar2-spi"></div>{" "}
        </div>
      </div>
    </div>
  );
};

export default Spinier;
