import axios from "axios";
import { Path, Base_url } from "../../config/Config";
export const Addprod = async ({ data, access }) => {
  try {
    const response = await axios.post(Base_url + Path.Ajoutproduit,data,{
      headers: {
        Authorization: "Bearer " + access,
      },
    });
    return response.data;
  } catch (err) {
    console.log(err)
    throw err;
  }
};


export const addproduitfournisseur = async ({ data, access }) => {
  try {
    const response = await axios.post(Base_url + Path.produitfournisseuradd,data,{
      headers: {
        Authorization: "Bearer " + access,
      },
    });
    return response.data;
  } catch (err) {
    console.log(err)
    throw err;
  }
};

