import {createSlice} from "@reduxjs/toolkit";

export const SliceAlldemondep=createSlice({
  name:"SliceAlldemondep",
  initialState:{
    demendes:[{}],
    status:null
  },
  reducers: {},

})

export default SliceAlldemondep.reducer;