import React, { useState } from 'react'
import './Filterbar.css'
import {SearchNormal1} from "iconsax-react";
import { InputAdornment, MenuItem, OutlinedInput, Select } from "@mui/material";
import Triipar from '../Modal/Triipar';
import Filtrer_produits from '../Modal/Filtrer_produits';

const Filterbar = (props) => {
  const [dataFromChild, setDataFromChild] = React.useState(false);
  const handleDataFromChildopen = (data) => {
    setDataFromChild(data);
  };
  const handleDataFromChild = (data) => {
    if (data)
    {
      props?.firlerdata(data);

    }
  };
  const [searchValue, setSearchValue] = React.useState(""); 

  const handleInputChange = (event) => {
    setSearchValue(event.target.value); 
   if( props?.onchange)
{
  props.onchange(event.target.value)
}  

};
const [selectedValue, setSelectedValue] = useState(10);
const handleChange = (event) => {
  setSelectedValue(event.target.value);
  if( props?.onchangeAfiche)
  {
    
    props.onchangeAfiche(event.target.value)
  }  
};


  return (
    <div className='row1-fil'>
<div>
<div className='row2-int'> 
    <OutlinedInput
     value={searchValue} 
     onChange={handleInputChange}
          className="input-int"
          placeholder={"Rechercher..."}
          endAdornment={
            <InputAdornment position="end">
              <SearchNormal1 size="19" color="#B1B1B1" />
            </InputAdornment>
          }
        />
        {
          props.icon=="none"?""
          :
          <button className='bnt0-int' onClick={()=>{setDataFromChild(true);}}>
          {props.icon}
          <div><p  className='txt0-int'>{props.nom}</p></div>
          </button>
        }
 


    {props.nom==="Ajouter filtre"?
    <>
       <Filtrer_produits onData={handleDataFromChild} isopen={dataFromChild} handleDataFromChildopen={handleDataFromChildopen}/>
    </>:
    <>
     <Triipar onData={handleDataFromChild} isopen={dataFromChild}/>
    </>}

    </div>
</div>

{props.titre=="TabAvisp"?
""
:
<div>
  
  <div className='row2-int'> 
      <p>Affichage</p>
<Select     onChange={handleChange}
              className="row2-int"
              defaultValue={10}
              style={{ width: "150px", height: " 40px", borderRadius: "8px" }}
             
            >
              <MenuItem  value={10}>
                <em className="txt0-int">10</em>
              </MenuItem>
              <MenuItem  value={20}>
                <em className="txt0-int">20</em>
              </MenuItem>
              <MenuItem  value={30}>
                <em className="txt0-int">30</em>
              </MenuItem>
              <MenuItem  value={40}>
                <em className="txt0-int">40</em>
              </MenuItem>
              <MenuItem  value={50}>
                <em className="txt0-int">50</em>
              </MenuItem>
            </Select>
            <p className='txt2-int'>résultats sur 50</p>
      </div>
  </div>
  
}


 </div>
  )
}

export default Filterbar
