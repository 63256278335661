import React, { useEffect,useState } from "react";
import "./Inventaire.css"
import { TickSquare, More, FilterAdd, ArrowCircleRight2, Edit, Trash } from "iconsax-react";

import { Link, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Filterbar from "../../../filterbar/Filterbar";
import { useDispatch, useSelector } from "react-redux";
import { AllListProduitLibe } from "../../../../Store/Service/AllistProduitLib";
import Pagination from "@mui/material/Pagination";
import Tablee from "./Table/Table";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const Inventaire = (props) => {

  const dispatch = useDispatch();
  const produit = useSelector((state) => state.AlllistProduitLib.listProduit);
  const { id } = useParams();
  const [current,setCurrent]=useState(1)

  function handlePagination (event,page) {
    setCurrent(page)
  }
  const [dataFromChild, setDataFromChild] = React.useState('');
const onchange=(data)=>{
setDataFromChild(data)
}
  useEffect(() => {
    dispatch(AllListProduitLibe({id:id,currentt:current,titre:dataFromChild}));
  }, [current,dataFromChild]);
  const items =7;
  function handlePagination (event,page) {
    setCurrent(page)
  }
  const totalpage = useSelector((state) => state.AlllistProduitLib.totalpage);
  return (
    <div className="Inventaire">
    
      <Filterbar
        icon={<FilterAdd size="22" color="#222222" />}
        nom="Ajouter filtre"
        onchange={onchange}
      />
  
      <Tablee data={produit} />

<div className="Pagination">
<Pagination
                  count={totalpage}
                  shape="rounded"
                  className="pagination-shop"
                  page={current}
                  onChange={handlePagination}
                />
</div>
    </div>
  );
};

export default Inventaire
