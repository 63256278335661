import React, { useEffect, useState } from "react";
import "./InventaireF.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Listevender from "../fournisseur-liste/Listevender";
import { Trash, ExportCircle, ArrowCircleLeft, SearchNormal1, TickSquare } from "iconsax-react";
import Ajouter from "../ajoutprod-vender/Ajouter";
import Avis from "../fournisseur-avis/Avis";
import { supprimerprod } from "../../../../Store/Service/supprimerprod";
import { toast } from "react-toastify";
import {  Checkbox, InputAdornment, Modal, OutlinedInput, Pagination} from "@mui/material";
import {  useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { saveAs } from 'file-saver';
import { getAllProduitCataloge, getAllProduitCatalogeforni } from "../../../../Store/Service/allProduitCataloge";
import { Addprod, addproduitfournisseur } from "../../../../Store/Service/addProd";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const InventaireF = (props) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#FFFFFF",
    width: "755px",
    height: "715px",
    boxShadow:
      "2px 5px 15px rgba(26, 31, 39, 0.02), 10px 15px 40px rgba(26, 31, 39, 0.03)",
    borderRadius: "8px",
    p: 4,
  };
  const [searchValue, setSearchValue] = useState("");
  const [Modelopen, setModelopen] = useState(false);
  const [value, setValue] = useState(0);
  const [nextpage, setnextpage] =useState(true);
  const [produit, setproduit] = useState({titre:"",prix:"",qte:"",categorieId:"1",idprod:"",op:false,imagelibrairies:{},prix_en_Solde:"",remise:"",description:"",Allid:[]});
  const handleDataFromChild = (data) => {
    setproduit(data);
  };
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedCheckboxes([...selectedCheckboxes, value]);
    } else {
      setSelectedCheckboxes(selectedCheckboxes.filter((item) => item !== value));
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleInputChange2 = (event) => {
    setSearchValue(event.target.value);
  };
  const [ref,setref]=useState()
  const supall=()=>{
    setref(true)
    supprimerprod({"ids":selectedCheckboxes},{ access: props.user.accessToken }).then((response)=>{
      toast.success("votre produit a ete supprimer avec success",{autoClose: 2000})
      setref(false)
      }).catch(err=>{console.error(err);setref(false) })
  }
  const produitd = useSelector(
    (state) => state.AllCatalogeforni.produCatalogefroni
  );
  const [current, setCurrent] = useState(1);
  function handlePagination(event, page) {
    setCurrent(page);
  }  const nbrtotal = useSelector(
    (state) => state.AllCatalogeforni.nbrtotal
  );

  const dispatch=useDispatch();
  useEffect(() => {
    dispatch(getAllProduitCatalogeforni({cat:"1",titre:searchValue,current:current,pageSize:"5"}))
  }, [searchValue,current]);


const [selectedProducts, setSelectedProducts] = useState([]);
const addProduits = (titre, description, image, categorieId, SouscategorieId) => {
  const isSelected = selectedProducts.some((product) => product.titre === titre);
  if (isSelected) {
    const updatedProducts = selectedProducts.filter((product) => product.titre !== titre);
    setSelectedProducts(updatedProducts);
  } else {
    setSelectedProducts([...selectedProducts, { titre, description, image, categorieId, SouscategorieId,labrprodfk:props.user.id }]);
  }
};

  const addProduit = (titre, description, imageCataloges, categorieId, SouscategorieId,codebar) => {
    const productData = {
      titre,
      description,
      fourprodfk: props.user?.id,
      categprodfoufk: categorieId,
      souscatprodfourfk: SouscategorieId,
      image: imageCataloges, 
      codebar:codebar
    };
  console.log(produitd)
    addproduitfournisseur({ data: { products: [productData] }, access: props.user.accessToken })
      .then((response) => {
        toast.success("produit bien ajouté", { autoClose: 2000 });
        setModelopen(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("error produit ", { autoClose: 2000 });

      });
  };

  const addProduitts = () => {
    const productData = {
      products: selectedProducts.map((product) => ({
        titre: product.titre,
        description: product.description,
        labrprodfk: product.labrprodfk,
        categprodlabfk: product.categorieId,
        souscatprodfk: product.SouscategorieId,
        image: product.image,
      })),
    };
    Addprod({ data: productData, access: props.user.accessToken })
      .then((response) => {
        toast.success("produit bien ajouté", { autoClose: 2000 });
        setModelopen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const produite = useSelector((state) => state.AlllistProduitLib.listProduit);
  const exporte = (jsonData) => {
    const filteredData = produite.map(({ id, titre, description, prix, qte, remise, prix_en_Solde }) => ({
      id,
      titre,
      description,
      prix: prix || 0,
      qte: qte || 0,
      remise: remise || 0,
      prix_en_Solde: prix_en_Solde || 0,
    }));
  
    // Extract columns and rows from filtered data
    const columns = Object.keys(filteredData[0]);
    const rows = filteredData.map(row => columns.map(column => row[column]));
  
    // Create content string with spaces between rows
    const header = columns.join('\t');
    const body = rows.map(row => row.join('\t')).join('\n\n'); // Add '\n\n' for space between rows
    const content = `${header}\n${body}`;
  
    // Trigger file download
    const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
    saveAs(blob,'exported_data.txt');
  };

  const styles = {
    tableCell: {
      fontSize: "16px",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "20px",
      color:"rgb(67 64 64 / 71%)",
      textAlign:"left",
    },
  };
  return (
    <>
      {nextpage ? (
        <div className="row-int">
          <div className="row3-int">
            <div>
              <p className="txt-int">Inventaire</p>
            </div>
            <div className="row4-int">
              <div style={{ cursor: "pointer" }} onClick={supall}>
      
                <Trash size="24" color="#E66A6A" />
                <p className="txt3-int">Supprimer</p>
              </div>
              <div style={{ cursor: "pointer" }} onClick={exporte}>
                <ExportCircle size="24" color="#515151" />
                <p className="txt20-int">Exporter</p>
              </div>
              <div className="bnt01-inv"onClick={() => {
                    setModelopen(true);
                  }}>
              Nouveau produit
              </div>
            </div>
          </div>
          <div className="box-int">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  TabIndicatorProps={{ style: { background: "#F7D070" } }}
                >
                  <Tab
                    label={<p className="txttabs-int">Liste de produits</p>}
                    {...a11yProps(0)}
                  />
                </Tabs>
              </Box>
              <Listevender value={value}  accessToken={props.user.accessToken} reff={ref}     selectedCheckboxes={selectedCheckboxes}     handleCheckboxChange={handleCheckboxChange}onData={handleDataFromChild} setnextpage={()=>{setnextpage(false)}} id={props.user?.id}/>
            </Box>
          </div>
       
        </div>
      ) : (
        <div className="box2-int">
          <div
            className="rowbox-int"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setnextpage(true);
              setproduit({titre:"",prix:"",qte:"",categorieId:"1",idprod:"",op:false})
            }}
          >
            <ArrowCircleLeft size="25" color="#9E9E9E" />
            <div>
              <p className="txtbox-int">Retourner</p>
            </div>
          </div>
          {produit.op? 
          <Ajouter titre="Modifier produit" prod={produit} setnextpage={()=>{setnextpage(true)}}  accessToken={props.user.accessToken} />
                 :  
                 <Ajouter titre="Faire une suggestion" setnextpage={()=>{setnextpage(true)}}  id={props.user?.id} accessToken={props.user.accessToken}/>
          }
        </div>
      )}
            <Modal
        open={Modelopen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={() => {
          setModelopen(false);
        }}
      >
        <Box sx={style}>
          <div className="modeldecortion-inv">
            <div className="flex-end">
              {" "}
              <div>
                {" "}
                <i
                  className="fa fa-close"
                  onClick={() => {
                    setModelopen(false);
                  }}
                ></i>
              </div>
    
            </div>
            <div className="flex-end">
{
  selectedProducts.length!=0?<button className="bnt023-inv" onClick={addProduitts}>Ajoute produits</button>
:null
}
</div>
            <OutlinedInput
                    value={searchValue}
                    onChange={handleInputChange2}
                className="search-inpute2"
                placeholder={"Rechercher l'ID de commande, l'article"}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchNormal1 size="19" color="#B1B1B1" />
                  </InputAdornment>
                }
              />
            <div className="scrollingModel-inv">
<br/>
        <TableContainer
    component={Paper}
    style={{
      boxShadow: "none",
      borderWidth: " 1px 0px",
      borderStyle: "solid",
      borderColor: "#EEEDF2",
      width: "95%",
      marginLeft:"5%"
    }}
  >
    <Table sx={{ width: "100%" }} aria-label="simple table">
      <TableHead>
        <TableRow>
        <TableCell>
        <TickSquare
              size="20"
              color="#7E7E7E"

            />
          </TableCell>
          <TableCell>
            <p className="tableCell-inv">ID</p>
          </TableCell>
          <TableCell align="left">
            <p className="tableCell-inv">titre</p>
          </TableCell>
          <TableCell>
            <p className="tableCell-inv">Description</p>
          </TableCell>
          <TableCell>
          </TableCell>
        </TableRow>
      </TableHead>
  
      <TableBody>
      {produitd?.map((item, index) => (
   <TableRow key={index}>
     <TableCell style={styles.tableCell}>
     <Checkbox
              style={{ color: "#E9B949" }}
              checked={selectedProducts.some((product) => product.titre === item?.titre)}
              onChange={() => addProduits(item?.titre, item?.description, item?.imageproduitfournisseurs?.[0]?.name_Image, item?.categorie?.id, item?.SouscategorieId)}
           
           />
     </TableCell>

   <TableCell style={styles.tableCell}>

 <div style={{display:"flex",alignItems:"center"}}>
  <p>#</p>
 {item?.id}
  </div>
   </TableCell>
   <TableCell style={styles.tableCell}>
  <div style={{display:"flex",alignItems:"center",gap:"0.5em"}}>
  <img src={item?.imagecatalogefournisseurs?.[0]?.name_Image} className="imginv"/>
    <div> {item?.titre}</div>
  </div>
   </TableCell>
   <TableCell style={styles.tableCell}>
   {item?.description?item?.description:"......"}
   </TableCell>
   <TableCell>
   <div className="bnt02-inv" onClick={()=>{addProduit(item?.titre,item?.description,item?.imagecatalogefournisseurs?.[0]?.name_Image,item?.categorie?.id,item?.SouscategorieId,item?.codebar)}}>Ajoute Produit</div>
   </TableCell>
   </TableRow>
      ))}
   </TableBody>
  </Table>
  </TableContainer>
  <br/>        
  <Pagination
              count={nbrtotal}
              shape="rounded"
              className="pagination-shop"
              page={current}
              onChange={handlePagination}
            />
            </div>
        
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default InventaireF;
