import { createSlice } from "@reduxjs/toolkit";
import { getCategoryList } from "./categoryService";

const initialState = {
  categoryList: {},
  categoryData: null,
  loading: false,
  error: null,
};

const categoriesPending = (state) => {
  state.loading = true;
};

const categoriesError = (state, action) => {
  state.error = action.payload;
};

export const categorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategoryList.pending, categoriesPending)
      .addCase(getCategoryList.rejected, categoriesError)
      .addCase(getCategoryList.fulfilled, (state, { payload }) => {
        state.categoryList = payload.categories;
      });
  },
});

export default categorySlice.reducer;
