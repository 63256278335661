import React, { useState } from 'react'
import {
    Avatar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@mui/material";
  import { Trash,CloseCircle} from "iconsax-react";
  import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
  import Paper from "@mui/material/Paper";
import { More, Sort } from 'iconsax-react';
import { useNavigate } from 'react-router-dom';
import { getAlluser } from '../../../../../Store/Service/getAlluser';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { bloqueuser } from '../../../../../Store/Service/actionuser';
import { Base_url, Path } from '../../../../../config/Config';
import axios from 'axios';
const Tablee = (props) => {
    const navigate = useNavigate();
    const [iduser ,setIduser] = useState()
    const [tact ,setact] = useState()
    const [anchorEl, setAnchorEl] =useState(null);
    const dispatch = useDispatch();
const open = Boolean(anchorEl);
const handleClose = () => {
    setAnchorEl(null);
  };
  const bloquecl=()=>{
    bloqueuser({id:iduser,accessToken:props.accessToken}).then((res)=>{
        setAnchorEl(null);
        props.reflsh()
        toast.success("c'est client a bloque avec success", {autoClose: 2000, });
    })
}
const sup = async () => {
  try {
    const response = await axios.delete(Base_url +Path.deleteuser+iduser,{
      headers: {
        Authorization: "Bearer " + props.accessToken,
      },
    });
    toast.success("c'est client a suppriem avec success", {autoClose: 2000, });
setAnchorEl(null);
props.reflsh()
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};


const formatDate = (inputDate) => {
    const dateObj = new Date(inputDate);
    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear().toString();
    return `${day}-${month}-${year}`;
  };
  return (
<div className='tab'>
<TableContainer
    component={Paper}
    style={{
      boxShadow: "none",
      borderWidth: " 1px 0px",
      borderStyle: "solid",
      borderColor: "#EEEDF2",
      width: "100%" 
    }}
  >
    <Table sx={{ width: "100%" }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>
            <p className="tableCell">#</p>
          </TableCell>
          <TableCell align="left">
            <p className="tableCell">Nom</p>
          </TableCell>
          <TableCell align="left">
            <p className="tableCell">Téléphone</p>
          </TableCell>
          <TableCell>
            <p className="tableCell">Email adresse</p>
          </TableCell>
          <TableCell align="left">
            <p className="tableCell">Derniére Activité</p>
          </TableCell>
          <TableCell align="left">
            <p className="tableCell">Statut</p>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {props?.data?.map((item, index) => (
   <TableRow key={index}>
   <TableCell style={{cursor:"pointer"}} onClick={()=>{navigate("/Admin/details_client/"+item?.id)}}>
   {item?.id}
   </TableCell >
   <TableCell style={{cursor:"pointer"}} onClick={()=>{navigate("/Admin/details_client/"+item?.id)}}>
   <div className="row-int01"onClick={()=>{navigate("/Admin/details_client/"+item?.id)}}>
    <Avatar src={item?.avatar} className="img512-ad" />
     <div style={{ marginTop: "3%" }}>
        <p className="txt01-int">{item?.fullname}</p>
      </div>
    </div>
   </TableCell>
   <TableCell style={{cursor:"pointer"}} onClick={()=>{navigate("/Admin/details_client/"+item?.id)}}>
   <p className="txt02-int">{item?.telephone?item?.telephone:"-"}</p>
   </TableCell>
   <TableCell style={{cursor:"pointer"}} onClick={()=>{navigate("/Admin/details_client/"+item?.id)}}>
  <p className='Montant-tab'>{item?.email}</p>
   </TableCell>
   <TableCell >
  <p className='Nbr_articles-tab'>{formatDate(item?.createdAt)}</p>
   </TableCell>
   <TableCell style={{cursor:"pointer"}} onClick={()=>{navigate("/Admin/details_client/"+item.id)}}>
   { item?.etatCompte ==="active"?
  <div className="stats-admin123"style={{cursor:"pointer"}}onClick={()=>{navigate("/Admin/details_client/"+item.id)}}>{item?.etatCompte }</div> 
  :
  <div className="stats-admin2"style={{cursor:"pointer"}}onClick={()=>{navigate("/Admin/details_client/"+item.id)}}><p>{item?.etatCompte }</p></div> 
    }
   </TableCell>
   <TableCell>
   <More
 size="22"
 color="#222222"
 aria-controls={open ? "basic-menu" : undefined}
 aria-haspopup="true"
 aria-expanded={open ? "true" : undefined}
 className="more-ad"
 onClick={(e)=>{setAnchorEl(e.currentTarget);setIduser(item?.id);setact(item?.etatCompte )}}
/>

   </TableCell>


 </TableRow>

      ))}
   <Menu
              id="basic-menu"
              className="menu-avis"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
{tact==="active"?
 <MenuItem className="menuitem-avis" onClick={bloquecl}>
 <CloseCircle size="22" color="#626262" />
 <span className="spn-ad">
 Bloquer
 </span>
</MenuItem>
:null

}
            
  
              <MenuItem
                className="menuitem-avis"
                onClick={sup}
              >
                <Trash size="22" color="#D64545" />
                <span>
                  <p className="spn-ad2">Suprimer</p>
                </span>
              </MenuItem>
         </Menu>
      </TableBody>
    </Table>
  </TableContainer>

</div>
  )
}

export default Tablee
