import React, { useEffect, useState } from "react";
import "./DetailLivrFornisseuer.css";
import Avatar from "@mui/material/Avatar";
import { ArrowCircleLeft, Call, Sms, Location } from "iconsax-react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Detailcomonde } from "../../../../Store/Service/Detailcomonde";
import { AccepterCommande, LivreCommande, LivreCommandeforni } from "../../../../Store/Service/AccepterCommandeDetail";
import { toast } from "react-toastify";
import { AnnulerCommande } from "../../../../Store/Service/AccepterCommandeDetail";
import { findCommandeBylibrairie } from "../../../../Store/Service/findCommandeBylibrairie";
import axios from "axios";
import { Base_url } from "../../../../config/Config";

const DetailLivrFornisseuer = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id}=useParams()
  const idcomonde = useParams(":id");
  const [ref, setref] = useState();
  const [Anuuler, setAnuuler] = useState(true);
  const navigat = () => {
    navigate("/fournisseur/Liste_de_livraisons");
  };
  const [DetailcomondeClient, setDetailcomondeClient] = useState([]);

  const Allcomnde = async () => {
    try {
      const response = await axios.get(Base_url +"fournisseur/findOneCommande/"+id);
      setDetailcomondeClient(response?.data?.commande);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    Allcomnde()
  }, [ref]);
  


  const [open, setopen] = useState("Nouveau");
  const Livrer=()=>{
    LivreCommandeforni({id:id,accessToken:props.user.accessToken}).then((response)=>{
        toast.success("commande Livrer",{autoClose: 2000})
        Allcomnde()
        setref(true)

    }).catch(err=>{console.log(err)})
    setopen(false);
    setref(false)

  }


  const Annuler=()=>{
        AnnulerCommande({id:idcomonde.id,accessToken:props.user.accessToken}).then((response)=>{
         toast.error("commande Annuler",{autoClose: 2000})
         dispatch(findCommandeBylibrairie(props?.user.id));
         setref(true)
        setopen("false");
        setAnuuler(true) ;
        setref(false);

     }).catch(err=>{
      console.error(err)
     })
  }

  
  return (
    <div className="Detailcomnder">
      <div
        className="rowbox-dc"
        style={{ cursor: "pointer" }}
        onClick={navigat}
      >
        <ArrowCircleLeft size="25" color="#9E9E9E" />
        <div>
          <p className="txtbox-dc">Retourner</p>
        </div>
      </div>
      <div className='row12-dl'>
   <div >
        <p className='txt1-dc'>Détails de livraison</p>
    </div>
    {
      DetailcomondeClient?.[0]?.Date_préparée===null?
      <div>
      <button className='bnt-dl' onClick={Livrer}>Marquer comme livré</button>
  </div>
  :""
    }
   
    </div>
      <div className="row1-dc">
        <div className="col1-dc">
          <div className="box1-dc">
            <div className="border-dc"> </div>
            <div className="colbox1-dc">
              <div>
                <p className="txtbox1-ddc"># {DetailcomondeClient?.[0]?.id}</p>
              </div>
              <div>
                <ul className="ul-dc">
                  <li>
                    <p className="txtli-dc">
                      Montant Totale:
                      <span className="txtspanli-dc">
                        {DetailcomondeClient?.[0]?.total_ttc}  dt
                      </span>
                    </p>
                  </li>
                  <br />
                  <li>
                    Nbr d’article(s):
                    <span className="txtspanli-dc">
                     
                      {DetailcomondeClient?.[0]?.produitfournisseurs?.length}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="box2-dc">
            <div>
              <p className="txtbox2-dc">Détails de contact</p>
            </div>
            <div className="minirow-dc">
              <Avatar
                src={
                  DetailcomondeClient?.[0]?.labrairie?.imageStore
                }
                className="img-dc"
              />
              <div style={{width:"50%"}}>
                <p className="txt1box3-dc">
                  {DetailcomondeClient?.[0]?.labrairie?.nameLibrairie}
                </p>
              </div>
            </div>
            <div className="minirow-dc">
              <Call size="24" color="#9E9E9E" variant="Bold" />
              <div>
                <p className="txt1box2-dc">
                  (+216) {DetailcomondeClient?.[0]?.labrairie?.telephone}
                </p>
              </div>
            </div>
            <div className="minirow-dc">
              <Sms size="24" color="#9E9E9E" variant="Bold" />
              <div>
                <p className="txt1box3-dc">
                  {DetailcomondeClient?.[0]?.labrairie?.emailLib}
                </p>
              </div>
            </div>
            <div className="minirow-dc">
              <Location size="24" color="#9E9E9E" variant="Bold" />
              <div>
                <p className="txt1box3-dc">
  {DetailcomondeClient?.[0]?.labrairie?.adresse}     
                </p>
              </div>
            </div>
          </div>
          <div className="box3-dc">
            <div>
              <p className="txtbox2-dc">Calendrier</p>
            </div>

            
            <div className="minirow-dc">
              <div className="cirl-dc"></div>
              <div>
                <p className="txt3box3-dc">Demande reçu : </p>
              </div>
              <div>
                <p className="txt4box3-dc">{DetailcomondeClient?.[0]?.createdAt}</p>
              </div>
            </div>

{
  DetailcomondeClient?.[0]?.date_acceptation!=null&&Anuuler?
  <div className="minirow-dc">
  <div className="cirl-dc2"></div>
  <div>
    <p className="txt3box3-dc">Demande acceptée: </p>
  </div>
  <div>
    <p className="txt4box3-dc">{DetailcomondeClient?.[0]?.date_acceptation}</p>
  </div>
</div>
:<></>
}
{DetailcomondeClient?.[0]?.Date_préparée!=null?
<div className="minirow-dc">
<div className="cirl-dc3"></div>
<div>
  <p className="txt3box3-dc">Demande préparée: </p>
</div>
<div>
  <p className="txt4box3-dc">{DetailcomondeClient?.[0]?.Date_préparée}</p>
</div>
</div>
:
<></>
}
{
DetailcomondeClient?.[0]?.Data_rejetée!=null &&Anuuler?
<div className="minirow-dc">
<div className="cirl-dc4"></div>
<div>
  <p className="txt3box3-dc">Demande rejetée: </p>
</div>
<div>
  <p className="txt4box3-dc">{DetailcomondeClient?.[0]?.Data_rejetée}</p>
</div>
</div>
:
<></>

}

           


          </div>
        </div>
        <div className="col05-dc">
          <div className="box4-dc">
            <div>
              <p className="txtbox1-ddc">Liste des articles</p>
            </div>
            <div className="rowbox4-dc">
              <div>
                <p className="txtbox4-dc">Article</p>
              </div>
              <div>
                <p className="txtbox4-dc">Qté</p>
              </div>
              <div>
                <p className="txtbox4-dc">Totale HT</p>
              </div>
            </div>
            <div className="scroll-div2">
            <table className="tab-prd">
              {DetailcomondeClient?.[0]?.produitfournisseurs?.map((obj, index) => (
                <tr key={index}>
                  <td className="widthtd-dc">
                    <div className="row2box4-dc">
                      <img
                        src={
                          obj.imageproduitfournisseurs?.[0].name_Image
                        }
                        className="imgbox4-dc"
                      />
                      <div className="colbox4-dc">
                        <div>
                          {" "}
                          <p className="txt3box4-dc">{obj.titre}</p>
                        </div>
                        <div>
                          {" "}
                          <p className="txt4box4-dc">{obj?.prix_en_Solde!==null && obj?.prix_en_Solde!==0?obj?.prix_en_Solde:obj.prix}dt</p>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="widthtd1-dc">
                    <p className="txttab-dc">{obj?.produit_c_gro?.Qte}</p>
                  </td>
                  <td>
                    <p className="txttab-dc">
                      {obj?.prix_en_Solde!==null && obj?.prix_en_Solde!==0?obj?.prix_en_Solde:obj.prix * obj.produit_c_gro?.Qte}
                    </p>
                  </td>
                </tr>
              ))}
            </table>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailLivrFornisseuer;
