import { Grid} from "@mui/material";
import * as React from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "./PanierV.css";
import { Link } from "react-router-dom";
import Box_produit_Card from "../../../components/box-produit-card/box-produit-card";
import Recapitulatif from "../../../components/Recapitulatif.js/Recapitulatif";
import { useSelector } from "react-redux";
import {  useMediaQuery, useTheme } from "@mui/material";
import Responsive from "./responsiveCard/responsivecard"
const PanierV = (props) => {
  const [ref,setref]=React.useState(0)
  const panier=useSelector(state=> state.Panier.panier)
  const changeval=(data)=>{
    setref(data)
  }
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
   <>
   {!isSmallScreen?
       <div className="Cartv">
       <Grid item>
         <h1 className="titrev">Votre panier</h1>
       </Grid>
       <div className="row-cart" >
         <div className="produitv">
           <Grid container direction="row" className="items" style={{paddingBottom:"2%"}}>
             <div  className="item Article">
               Article
             </div>
             <div  className="item Qte1">
               Qte
             </div>
             <div  className="item total1">
               Total HT
             </div>
             <div  className="item"></div>
           </Grid>
           <hr className="underline"></hr>
           <div className="scroll">
             {panier?.map((e,key)=>(
           <Box_produit_Card key={key} total={e.prix*e.qte} panier={panier} changeval={changeval}titre={e.titre} prix={e.prix} qte={e.qte} imgp={e.imgp} index={key} idl={e.idl} idp={e.idp} Allqte={e.Allqte}/>))}
           </div>
         </div>
         <Recapitulatif user={ props.user}  role={props.user.role} iduser={props.user.id} auth={props.user.auth} panier={panier} etatcompt={props.user.etatCompte} authuser={props.user.auth}/>
       </div>
       <div className="Continuerv">
         <ArrowBackIosNewIcon className="icon1" />
         <Link to="/Vender/Approvisionner" style={{color:"#222"}}> Continuer vos achats</Link>
       </div>
     </div>
     :
     <Responsive prop={props}/>

   }
   
   </>

   
  );
};
export default PanierV;
