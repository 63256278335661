import axios from "axios"
import { createAsyncThunk} from '@reduxjs/toolkit';
import { Path, Base_url } from '../../config/Config'
import { toast } from 'react-toastify';

export const Signupuser = createAsyncThunk(
  'Signup',
  async (postData) => {
    try {
      const response = await axios.post(Base_url+ Path.SignupApi, postData)
      return response.data
    } catch (err) {
      toast.error(err.response.data.err,{autoClose: 2000})
    }
  }
)


