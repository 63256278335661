import axios from "axios"
import { createAsyncThunk} from '@reduxjs/toolkit';
import { Path, Base_url } from '../../config/Config';
export const getAllProduitByCategorie = createAsyncThunk(
    'getAllProduitByCategorie',
    async (id) => {
      try {
        const response = await axios.get(Base_url+ Path.getAllproduitBycategorie+id+"?page=1&pageSize=30&sortBy=titre&sortOrder=desc")
        return response.data
      } catch (err) {
        
      }
    }
  )