import React,{useEffect, useState} from 'react'
import './Profil.css'
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import { Edit, Export, Shop, Trash } from 'iconsax-react';
import { Avatar } from '@mui/material';
import axios from 'axios';
import { Base_url, Path } from '../../../config/Config';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
const Profil = (props) => {
    const nav=useNavigate()
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const [ok2,setok2]=useState(false)
    const [ok,setok]=useState(false)
    const [Profilp2, setProfilp2] = useState([]);
const [image2, setImage2] = useState(null);
const [sizeimg2,setSizeimg2]=useState(false)
const [open2,setopen2]=useState(true)
const [avatar2,setavatar2]=useState()
const [Profilp, setProfilp] = useState({
    nom:  "",
    addr:  "",
    Email:"",
    date: "",
    telephone: ""
  });

  const [pass, setpass] = useState({
    ActuelPassword:  "",
    newPassword:  "",
    ConfirmernewPassword:"",
  });

  const handleInputChange= (field) => {
    return (e) => {
        setpass((prev) => ({
        ...prev,
        [field]: e.target.value
      }));
    };
  };

const handleInputChange2 = (field) => {
    return (e) => {
      setProfilp((prev) => ({
        ...prev,
        [field]: e.target.value
      }));
    };
  };
    const onImageChange2 = (event) => {
        if (event.target.files && event.target.files[0]) {
          setavatar2(event.target.files[0])
          setSizeimg2((event.target.files[0].size)>(1024*1024))
          setImage2(URL.createObjectURL(event.target.files[0]));
          setopen2(false);
          setok2(true);
        }
      };
const  admin=async ()=>{
  try {
    const response = await axios.get(Base_url + Path.findOnePartnaire+ props?.user.id);
    setProfilp((prevProfilp) => ({
      ...prevProfilp,
      nom: response.data?.partenaire?.fullname || "",
      addr: response.data?.partenaire?.partenaire?.address || "",
      Email: response.data?.partenaire?.email || "",
      date: response.data?.partenaire?.Date_de_naissance || "",
      telephone: response.data?.partenaire?.telephone || "",
    }));
  
    if(response.data?.partenaire?.avatar)
    {
      setImage2(response.data?.partenaire?.avatar)

    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}
      useEffect(() => {
        admin()
      }, []);
      
const update=async ()=>{
    const data= new FormData() ; 

    if (Profilp.telephone.length !== 0) {
      data.append("telephone", Profilp.telephone);
  }
  
  if (Profilp.nom.length !== 0) {
      data.append("fullname", Profilp.nom);
  }
  
  if (Profilp.Email.length !== 0) {
      data.append("email", Profilp.Email);
  }
  
  if (Profilp.date.length !== 0) {
      data.append("Date_de_naissance", Profilp.date);
  }
  try {
  const response = await axios.put(Base_url + Path.modifierIdentiteClient+ props?.user.id,data);
  toast.success("votre Profile Modifier avec success",{autoClose: 2000})
  setTimeout(function() {
    nav(0);
  }, 1500);
  }catch(error)
  {
    console.error()
  }
}

const updateImage=async ()=>{
    const data= new FormData() ; 
    data.append("image",avatar2)
  try {
  const response = await axios.put(Base_url + Path.modifierIdentiteClient+ props?.user.id,data);
  toast.success("votre Profile Modifier avec success",{autoClose: 2000})
  setTimeout(function() {
    nav(0);
  }, 1500);
  }catch(error)
  {
    console.error()
  }
}

const password=async ()=>{
if((pass.ConfirmernewPassword===pass.newPassword )&&(pass.ConfirmernewPassword.length!=0)&&(pass.ActuelPassword.length!=0)&&(pass.newPassword.length!=0))
{
    const data={
        newPassword:pass.newPassword,
        ActuelPassword:pass.ActuelPassword
    }
  try {
  const response = await axios.put(Base_url + Path.changePassword+ props?.user.id,data);
  if(response.data.success!==false)
  {
    toast.success("votre Profile Modifier avec success",{autoClose: 2000})
    setTimeout(function() {
      nav(0);
    }, 1500);
  }else{
    toast.error("verify votre ActuelPassword",{autoClose: 2000})
  }

  }catch(error)
  {
    console.error()
  }
}else
{
    toast.error("verify votre Confirmer le nouveau mot de passe",{autoClose: 2000})

}
}

  return (
    <div className='pf'>
      <div className='txt-pf'>Profil</div>
      <div className='box-pf'>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: "#222" } }}
              >
                <Tab
                  label={<p className="txttabs-c">Profile</p>}
                  {...a11yProps(0)}
                />
                <Tab
                  label={<p className="txttabs-c">Mot de passe</p>}
                  {...a11yProps(1)}
                />
           
              </Tabs>
              
      
            </Box>

            <TabPanel value={value} index={0}>
          <div className='row-box'>
    
<div>
<div><p className='txt4-vp'>Photo de profil</p></div>

<div className='col4-vp'>
<div className='box3-vp'>
{open2?
<>
<label htmlFor="file-input2" className="labelup">
   <div className='circle-vp'>
<Avatar  style={{ height: "150px", width: "150px",borderRadius:"50%" }}  src={image2} className="avrt-page" />
    </div>
    </label>
    <input type="file" className="uplod" id="file-input2"accept=".jpg,.png,jfif" onChange={onImageChange2}/>

</>:

<>
<div className='circle2-vp'>
    <div style={{marginLeft:"-10%"}}><Avatar  style={{ height: "48px", width: "48px" }}  src={image2} className="avrt2-page" /></div>
    <div><p className="txtavatar1-vp">300x300 - Max 2 MB</p></div>
    <div className="rowbntavatr-vp">
      <button className="bnt10-avatar-vp" onClick={()=>{setopen2(true);setok2(false);updateImage()}}><Export size="22" color="#222" variant="Bold" style={{marginTop:"3%"}}/><div className="txt102av-vp">Télécharger photo</div></button>
    <button className="bnt20-avatar-vp"  onClick={()=>{ setImage2(null);setopen2(true);setok2(false) }}><Trash size="22" color="#FF8A65"variant="Bold" style={{marginTop:"3%"}}/><div className="txt103av-vp">Supprimer photo</div></button></div>
    </div>


</>
}
</div>
<div className="col20-vp">
        <div className="txt4-vp">Nom et Prénom</div>
        <input className="input-provp" onChange={handleInputChange2("nom")} value={Profilp.nom} />
      </div>
      <div className="col20-vp">
        <div className="txt4-vp">Email</div>
        <input className="input-provp"onChange={handleInputChange2("Email")}  value={Profilp?.Email} />
      </div>
      <div className="col20-vp">
        <div className="txt4-vp">Numéro de téléphone</div>
        <input type='number' className="input-provp" onChange={handleInputChange2("telephone")}  value={Profilp?.telephone}/>
      </div>
      <div className="col20-vp">
        <div className="txt4-vp">Date de naissance</div>
        <input type='date'  className="input-provp"onChange={handleInputChange2("date")}  value={Profilp?.date} />
      </div>

      { ok===true?
  <div className='rowpass2-pf'>
  <div style={{display:"flex",gap:"1em"}}>
  <button className='bntpasww2'onClick={()=>{setok(false)}}>Annuler</button>
   <button className='bntpasww3' onClick={update}> Confirmer</button>
  </div>
  </div>
  :
  null

      }
    

</div>
   


</div>

{ok===false?
    <button className='butt-vp' onClick={()=>{setok(true)}}>
    <Edit size="24" color="#222222" variant="Bold"/><div><p className='txtbnt-vp'>Modifier</p></div></button>
:
null
}


          </div>






        </TabPanel>
        <TabPanel value={value} index={1}>
<div style={{width:"399px"}}>
<div className='pass-pf'>
 <div><p className='txtpass-pf'>Mot de passe</p></div>
 <div><p className='txtpass2-pf'>Veuillez saisir votre mot de passe actuel pour modifier votre mot de passe.</p></div>
 <div className="col20-vp">
        <div className="txt04-vp">Mot de passe actuel</div>
        <input  type='password' className="input-provp"onChange={handleInputChange("ActuelPassword")}  value={pass?.ActuelPassword} />
      </div>
      <div className="col20-vp">
        <div className="txt04-vp">Nouveau mot de passe</div>
        <input   type='password'className="input-provp"onChange={handleInputChange("newPassword")}  value={pass?.newPassword} />
      </div>
      <div className="col20-vp">
        <div className="txt04-vp">Confirmer le nouveau mot de passe</div>
        <input type='password' className="input-provp"onChange={handleInputChange("ConfirmernewPassword")}  value={pass?.ConfirmernewPassword} />
      </div>

<div className='rowpass-pf'>
   <div style={{display:"flex",gap:"1em"}}>
   <button className='bntpasww2'>Annuler</button>
    <button className='bntpasww3' onClick={password}> Confirmer</button>
   </div>

</div>



  </div>

</div>


       </TabPanel>
      </div>
    </div>
  )
}

export default Profil
