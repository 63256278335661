import axios from "axios"
import { createAsyncThunk} from '@reduxjs/toolkit';
import { Path, Base_url } from '../../config/Config';
import { toast } from "react-toastify";
export const addBonDachateapi = createAsyncThunk(
    'addBonDachateapi',
    async ({data,access}) => {
      try {
      const response = await axios.post(Base_url+ Path.addBonDachate,data,{
        headers: {
          Authorization: "Bearer " + access,
        },
      })
      return response.data
      }
      catch (err) 
        {toast.error(err.response.data.msg,{autoClose: 2000})}
      
    }
)