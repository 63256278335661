import {createSlice} from "@reduxjs/toolkit";
import { getOneProdCatalogeFotrni } from "../Service/OneProdCataloge";



export const OneProdfornii=createSlice({
  name:"OneProdfornii",
  initialState:{
    Oneprodforni:[],
    status:null
  },
  reducers: {},
  extraReducers:{
    [getOneProdCatalogeFotrni.fulfilled]:(state,{payload})=>{
        state.Oneprodforni=payload.produits
        state.status="success"
    },
   [getOneProdCatalogeFotrni.pending]:(state)=>{
    state.status="loading"
   },
   [getOneProdCatalogeFotrni.rejected]:(state)=>{
    state.status="failed"
   }
  }  
})

export default OneProdfornii.reducer;