import React, { useEffect,useState } from "react";
import './Clients.js'
import'./Clients.css'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Trash,CloseCircle} from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getAlluser } from "../../../../Store/Service/getAlluser.js";
import { bloqueuser } from "../../../../Store/Service/actionuser.js";
import { Base_url, Path } from "../../../../config/Config.js";
import axios from "axios";
import Tablee from "./Table/Table.js";
import { Pagination } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const Clients = (props) => {

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);
 const [action,setaction]=useState('tout')
  const [current,setCurrent]=useState(1)
  const [Alluser,setAlluser]=useState([])
  const [totalpage,settotalpage]=useState(1)
  function handlePagination (event,page) {
    setCurrent(page)
  }     
  const AllFavorisp = async () => {
    try {
      const response = await axios.get(Base_url + `admin/findallusersadmin?sortBy=id&sortOrder=de&page=${current}&pageSize=20&etatCompte=${action}`);
      setAlluser(response?.data?.users);
      settotalpage(response?.data?.totalPages)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    AllFavorisp()
  }, [current,action]);
  return (
    <div className="pages-container1">
    <div className="header-page-categorie">
   <div>   <h1 className="title-page-categorie">Liste de Clients</h1></div>
    </div>
    <Box sx={{ width: '100%' }}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: { background: "#222" } }}>
       <Tab onClick={()=>{setaction("tout")}}  label={<p className="txttabs-c">Tout</p>} {...a11yProps(0)} />
        <Tab onClick={()=>{setaction("active")}}label={<p className="txttabs-c">Actif</p>} {...a11yProps(1)} />
        <Tab onClick={()=>{setaction("bloque")}}label={<p className="txttabs-c">Bloquée</p>} {...a11yProps(2)} />
      </Tabs>
    </Box>
    <TabPanel value={value} index={0}>  
    <Tablee data={Alluser} reflsh={AllFavorisp}accessToken={props.user.accessToken } />
    </TabPanel>
    <TabPanel value={value} index={1}>  
    <Tablee data={Alluser}reflsh={AllFavorisp} accessToken={props.user.accessToken }/>
 </TabPanel>
 <TabPanel value={value} index={2}>  
 <Tablee data={Alluser} reflsh={AllFavorisp} accessToken={props.user.accessToken }/>
 </TabPanel>
    </Box>
  <div className="pagination-adm">
  <Pagination
                  count={isNaN(totalpage)?1:totalpage}
                  shape="rounded"
                  page={current}
                  onChange={handlePagination}
                />

  </div>
  </div>

  )
}

export default Clients
