import { createAsyncThunk } from "@reduxjs/toolkit"
import { Base_url, Path } from "../../config/Config"
import axios from "axios"

const { reviewPath } = Path

export const getTopReviews = createAsyncThunk(
  'reviews/get_top_reviews',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${Base_url}${reviewPath}getTopAvisProduits`)
      return response.data
    } catch (error) {
      return rejectWithValue(error?.response?.data)
    }
  }
)