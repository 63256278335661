import {createSlice} from "@reduxjs/toolkit";
import { getAllProduitCatalogeforni } from "../Service/allProduitCataloge";


export const AllprodCatalogeforni=createSlice({
  name:"AllprodCatalogeforni",
  initialState:{
    produCatalogefroni:[],
    nbrtotal:[],
    status:null
  },
  reducers: {},
  extraReducers:{
    [getAllProduitCatalogeforni.fulfilled]:(state,{payload})=>{
        state.produCatalogefroni=payload.catalogue
        state.nbrtotal=payload.totalPages
        state.status="success"
    },
   [getAllProduitCatalogeforni.pending]:(state)=>{
    state.status="loading"
   },
   [getAllProduitCatalogeforni.rejected]:(state)=>{
    state.status="failed"
    state.produCatalogefroni=null
   }
  }  

})

export default AllprodCatalogeforni.reducer;