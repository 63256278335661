import React, { useEffect, useState } from 'react'
import'./Echanger.css'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tablee from './Tablee/Tablee';
import Modal from "@mui/material/Modal";
import { InputAdornment, OutlinedInput } from '@mui/material';
import { Base_url, Path } from '../../../config/Config';
import { toast } from 'react-toastify';
import axios from 'axios';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


const EchangerAdmin = (props) => {
    const [open, setOpen] = React.useState(false);
    const [alldata, setalldata] = React.useState([]);
    const [all2, setall2] = React.useState([]);
    const [all3, setall3] = React.useState([]);
    const [ref, setref] = React.useState(true);
    const [current, setcurrent] = React.useState(1);
    const [totalPages, settotalPages] = React.useState();
    const changecurrent=(data)=>{setcurrent(data)}
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
      const [action, setAction] = React.useState("Tout");
      const tabData = [
        { label: 'Tout', action: 'Tout' },
        { label: 'Mes échanges', action: 'Mes échanges' },
        { label: 'Mes offres', action: 'Mes offres' },

      ];
      const th = [
        {
         th:"À échanger "
        },
        {
            th:"Échange"
           },
           {
            th:"Date"
           },
           {
            th:"Staut"
           },
           
      ];
      const th2 = [
        {
         th:"Client"
        },
        {
            th:"Téléphone"
           },
           {
            th:"Email adresse"
           },
           {
            th:"Date"
           },
           {
            th:"Par"
           },
           {
            th:"Staut"
           },
      ];
      const th3 = [
        {
          th:"nom vender"
         },
           {
            th:"À échanger"
           },
           {
            th:"Échange"
           },
           {
            th:"Date"
           },
           {
            th:"Staut"
           },
      ];
      const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "#FFFFFF",
        width: "544px",
        height: "722px",
        boxShadow:
          "2px 5px 15px rgba(26, 31, 39, 0.02), 10px 15px 40px rgba(26, 31, 39, 0.03)",
        borderRadius: "8px",
        padding:"10px",
      
      };
      const onsubmit=async ()=>{
        const dataa ={
          "labechfk": props.user.id,
          "produitsaechange": productValues,
          "produitsechange": productValues2
        }
      
        try {
          const response = await axios.post(Base_url + Path.Addechange,dataa);
          toast.success("votre échange a éte Ajoute avec success", {
          autoClose: 2000,
    });
    setref(!ref)
    handleClose()
        } catch (error) {
          console.log(error)
          toast.error(error?.response?.data?.error, {
            autoClose: 2000,
      });
   
        }
      }
      const [productValues, setProductValues] = useState([
        { name: "", quantite: 0 },
        { name: "", quantite: 0 },
        { name: "", quantite: 0 },
        { name: "", quantite: 0 },
        { name: "", quantite: 0 },
      ]);
      const [productValues2, setProductValues2] = useState([
        {name: "", quantite: 0 },
        { name: "", quantite: 0 },
        { name: "", quantite: 0 },
        { name: "", quantite: 0 },
        { name: "", quantite: 0 },
      ]);
      const handleInputChange = (event, index) => {
        const { name, value } = event.target;
        const updatedProductValues = [...productValues];
        
        if (name === "name") {
          updatedProductValues[index][name] = value;
        } else if (name === "quantite") {
          updatedProductValues[index][name] = parseInt(value, 10); // Parse value to integer
        }
        
        setProductValues(updatedProductValues);
      }
      const handleInputChange2 = (event, index) => {
        const { name, value } = event.target;
        const updatedProductValues = [...productValues2];
        if (name === "name") {
          updatedProductValues[index][name] = value;
        } else if (name === "quantite") {
          updatedProductValues[index][name] = parseInt(value, 10); 
        }
        
        setProductValues2(updatedProductValues);
      }

  const getechangeAll = async () => {
    try {
      const response = await axios.get(Base_url + Path.findAllEchange+`?sortBy=id&sortOrder=desc&page=${current}&pageSize=10&etatechange=tout`);
      setalldata(response.data.echanges)
      settotalPages(response.data.totalPages)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getoffre = async () => {
    try {
      const response = await axios.get(Base_url +`offre/findOffresbylibrarie/${props?.user?.id}?&sortBy=createdAt&sortOrder=desc&page=${current}&pageSize=10`);
      setall2(response.data.offres)
      settotalPages(response.data.totalPages)

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getmyechange = async () => {
    try {
      const response = await axios.get(Base_url +`echange/findEchangelaibrairie/${props?.user?.id}?&sortBy=createdAt&sortOrder=desc&page=${current}&pageSize=10&etatechange=tout`);
      setall3(response.data.echanges)
      settotalPages(response.data.totalPages)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getstat=()=>{
  if(action=='Tout'){getechangeAll()}
  if(action=='Mes échanges'){getmyechange()}
  if(action=='Mes offres'){getoffre()}
  }
const changeref=()=>{setref(!ref)}
  useEffect(() => {
    getstat()
  }, [ref,action,current]);

  return (
    <div className='Echange'>
      <div>
     <div className='row-Echange'>   
   <div><p className='txt1-Echanges'>Échanger</p></div>
     </div>
        </div>
      <div className='box-Echanges'>
      <Box sx={{ width: '100%' }}>

              <Tablee data={alldata} current={current} totalPages={totalPages} changecurrent={changecurrent} changeref={changeref} user={props?.user?.id}th={th2} state={false}  titre='tout'/>


    </Box>
    <Modal
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    onClose={handleClose}
                  >
                    <Box sx={style}>
               <div className="col-modal">
               <div className="flex-end">
                 
                 <div>
             
                   <i className="fa fa-close" onClick={handleClose}></i>
                 </div>
               </div>

               <p className="txt1-modal">échanger produit(s)</p>
             
               <p className="txt2-modal">Dites-nous quels produits vous donnerez dans cet échange :</p>
          <div className='col2-modal'>
       <div className='scrolling-model'>
       {productValues.map((productValue, index) => (
      <div style={{ display: "flex" }} key={index}>
        <OutlinedInput
          className="input-int2"
          placeholder={`Qte`}
          name="quantite"
          onChange={(event) => handleInputChange(event, index)}
        />
        <OutlinedInput
          name="name"
          className="input-ech"
          onChange={(event) => handleInputChange(event, index)}
          placeholder={`Produit ${index + 1}`}
        />
      </div>
    ))}
       </div>
         
               <p className="txt2-modal">Dites-nous quels sont les produits que vous voulez à la place:</p>
        
               <div className='scrolling-model'>
               {productValues2.map((productValue, index) => (
      <div style={{ display: "flex" }} key={index}>
        <OutlinedInput
          className="input-int2"
          placeholder={`Qte`}
          name="quantite"
          onChange={(event) => handleInputChange2(event, index)}
        />
        <OutlinedInput
          name="name"
          className="input-ech"
          onChange={(event) => handleInputChange2(event, index)}
          placeholder={`Produit ${index + 1}`}
        />
      </div>
    ))}
       </div>
         
        <div className='bntrow-model'>
        
        <button className='bnt1-modal' onClick={handleClose}>Annuler</button>
        <button className='bnt2-modal' onClick={onsubmit}>Créer demande</button>


        </div>
          </div>
       

               </div>
                      
                    </Box>
                  </Modal>
      </div>
    </div>
  )
}




export default EchangerAdmin
