import { createSlice } from "@reduxjs/toolkit";
import { Loginuser } from '../../Store/Service/LoginService';

export const userSlice = createSlice({
  name: "loginservice",
  initialState: {
    accessToken: "",
    refreshToken: "",
    user: {},
    isLogin: false,
    data: {}
  },
  reducers: {
    logout: (state) => {
      state.isLogin = false;
      state.accessToken = "";
      state.refreshToken = "";
      state.user = {};
      state.data = {};
    }
  },
  extraReducers: {
    [Loginuser.pending]: (state) => {
      state.isLogin = false;
    },
    [Loginuser.fulfilled]: (state, { payload: { accessToken, refreshToken, error, user } }) => {
      if (error !== "bloque") {
        state.isLogin = true;
        state.accessToken = accessToken;
        state.refreshToken = refreshToken;
        state.user = user;
      } else {
        state.isLogin = false;
        state.accessToken = error;
      }
    },
    [Loginuser.rejected]: (state) => {
      state.isLogin = false;
    }
  }
});

export const { logout } = userSlice.actions;

export default userSlice.reducer;
