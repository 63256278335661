import React, { useEffect, useState } from "react";
import { InputAdornment, MenuItem, OutlinedInput, Select } from "@mui/material";
import { AddCircle, ArrowCircleLeft } from "iconsax-react";
import "./Listeprod.css";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import { SearchNormal1 } from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
import { getNbProduitlibBycategorie } from "../../../Store/Service/nbProduitLibByCategoorie";
import Ajouter from "../../ajoutprod-vender/Ajouter";
import Cardlisteprod from "../../card-produit/cardlisteprod/cardlisteprod";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Swiper} from "swiper/react";
import {  getAllProduitCatalogeforni } from "../../../Store/Service/allProduitCataloge";
import {  getOneProdCatalogeFotrni } from "../../../Store/Service/OneProdCataloge";
import { getCategoryList } from "../../../Store/categories/categoryService";
import { sousGategorie } from "../../../Store/Service/sousGategorie";


const Listeprod = (props) => {

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || "auto"   }}
      role="presentation"
    >
      <List>
        <ListItem>
          <div className="col5487-lst">
            <div
              className="rowbox-int"
              style={{ cursor: "pointer" }}
              onClick={toggleDrawer(anchor, false)}
            >
              <ArrowCircleLeft size="25" color="#9E9E9E" />
              <div>
                <p className="txtbox-int">Retourner</p>
              </div>
            </div>
  
            <div>
              <p className="txtliste-lit">{Oneproduit.titre}</p>
            </div>
            <div>
              <p className="txtliste-lit2">#{Oneproduit.id}</p>
            </div>
            <div>
              <Swiper spaceBetween={50} slidesPerView={3}>
                <img src={Oneproduit.imagecatalogefournisseurs?.[0]?.name_Image} className="imglist-lst2" />
              </Swiper>
            </div>
            <div>
              <p className="txtliste-lit">Description:</p>
            </div>
            <div>
              <p className="txtliste-lit3">
               {Oneproduit.description}
              </p>
            </div>
          </div>
        </ListItem>
      </List>
    </Box>
  );
  const dispatch=useDispatch();
  const produit = useSelector(
    (state) => state.AllCatalogeforni.produCatalogefroni
  );
  const nbrtotal = useSelector(
    (state) => state.AllCatalogeforni.nbrtotal
  );
  
  const filtered = produit?.filter(item => item?.etat === 'visible');
  const categoryList = useSelector(
    (state) => state?.categories?.categoryList
  );
  const [categorieId, setcategorieId] = useState("1");
  const [souscategorieId, setsouscategorieId] = useState("");
  const souscategorie = useSelector((state) => state.Onecategorie.categorie);
  const Oneproduit = useSelector(
    (state) => state.OneProdforni.Oneprodforni
  );

  const handleSelectChange = (event) => {
    setcategorieId(event.target.value);
    dispatch(sousGategorie(event.target.value));
  };
  const handleSelectChangesous = (event) => {
    setsouscategorieId(event.target.value);
  };
  const [searchValue, setSearchValue] = useState("");
  const handleInputChange2 = (event) => {
    setSearchValue(event.target.value);
  };
  const theme = useTheme();
  const [state, setState] = React.useState({
    right: false,
  });
  const [nextpage, setnextpage] = useState(true);
  const [prod, setprod] = useState({prix:"",qte:""});

  useEffect(() => {
    dispatch(getCategoryList());
    dispatch(getNbProduitlibBycategorie(props.user?.id));
  }, []);

  const items = 8;
  const [current, setCurrent] = useState(1);
  function handlePagination(event, page) {
    setCurrent(page);
  }
  const toggleDrawer = (anchor, open,id) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    dispatch(getOneProdCatalogeFotrni(id))
  
  };

  useEffect(() => {
    dispatch(getAllProduitCatalogeforni({cat:categorieId,titre:searchValue,current:current}))
  }, [categorieId,searchValue,current]);
  const loder=(data)=>{
    props.loder(data)
  }
return (
    <>
      {nextpage ? (
        <div className="pages-container1">
          <div className="header-page-categorie">
            <div>
              {" "}
              <h1 className="title-page-categorie">Liste de produits</h1>
            </div>
            <button
              className="btn-suggestion"
              onClick={() => {
                setnextpage(false);
              }}
            >
              Faire une suggestion
            </button>
          </div>
          <div className="box1-categorie" style={{width:"100%"}}>
            <div className="header-box1">
              <OutlinedInput
                    value={searchValue}
                    onChange={handleInputChange2}
                className="search-inputelp"
                placeholder={"Rechercher l'ID de commande, l'article"}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchNormal1 size="19" color="#B1B1B1" />
                  </InputAdornment>
                }
              />
          
                <div className="rowlisteprod">
                <Select
                 value={categorieId}
                 onChange={handleSelectChange}
      className='txt-select'
      style={{ width: "164.5px", height: "39px", borderRadius: "8px" }}
    >
      <MenuItem value="1" className='txt-selectprodlist'>
      Catégories
      </MenuItem>
      {
           Array.isArray(categoryList) &&categoryList?.map((obj, key) => (
          <MenuItem value={obj?.id} className='txt-selectprodlist'>{obj?.name}</MenuItem>
         ))
      }
    </Select>    

    <Select
      defaultValue={"1"}
      className='txt-selectprodlist'
      style={{ width: "164.5px", height: "39px", borderRadius: "8px" }}
      onChange={handleSelectChangesous}
    >
      <MenuItem value="1" className='txt-selectprodlist'>
      Sous-catégories
      </MenuItem>
      {souscategorie?.map((obj,index) => (
                <MenuItem value={obj?.id} key={index} className="txt-select">
                  {obj?.name}
                </MenuItem>
              ))}
    </Select>    

                </div>
              </div>
           
          </div>

          <div className="grid-container">
            {filtered?.map((obj,index) => (
              <div  key={index} className="grid-item" onClick={toggleDrawer("right", true,obj.id)}>
                <Cardlisteprod id={obj.id} titre={obj.titre} img={obj.imagecatalogefournisseurs?.[0]?.name_Image}/>
              </div>
            ))}

            <div>
            </div>
          </div>
          <div className="page-listev">
            <Pagination
              count={nbrtotal}
              shape="rounded"
              className="pagination-shop"
              page={current}
              onChange={handlePagination}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="box2-int">
            <div
              className="rowbox-int"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setnextpage(true);
              }}
            >
              <ArrowCircleLeft size="25" color="#9E9E9E" />
              <div>
                <p className="txtbox-int">Retourner</p>
              </div>
            </div>

            <Ajouter temp='forni' titre="Faire une suggestion" loder={loder} setnextpage={()=>{setnextpage(true)}} id={props.user?.id} accessToken={props.user?.accessToken} />
          </div>
        </>
      )}
          <React.Fragment>
      
      <Drawer
        anchor={'right'}
        open={state['right']}
        width="150"
      >
        {list('right')}
      </Drawer>
    </React.Fragment>
    </>
  );
};
export default Listeprod;
