import React,{useEffect, useState} from 'react'
import './Historique.css'
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { getAllcommandeByclient } from '../../../Store/Service/getAllcommandebyClient';
import Tabcommandes from './tabcommandes/Tabcommandes';
import { Pagination } from '@mui/material';
import { Base_url } from '../../../config/Config';
import axios from 'axios';
import { ArrowCircleLeft } from 'iconsax-react';
import { useNavigate } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Historique = (props) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [commandes, setcommandes] = React.useState([]);
  const [totalPages, settotalPages] = useState();
const [etat, setEtat] = useState("tout");
const [searchValue, setsearchValue] = useState("");
const [current, setcurrent] = useState(1);
function handlePagination (event,page) {
  setcurrent(page)
}
const dispatch = useDispatch();
const AllFavorisp = async () => {
  try {
    const response = await axios.get(Base_url +`fournisseur/findAllCommandesbyvender/${props.user.id}?sortBy=createdAt&sortOrder=desc&page=${current}&pageSize=10&etatlabrairie=${etat}`);
    setcommandes(response?.data?.commandes);
    settotalPages(response?.data?.totalPages)
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
useEffect(()=>{
  AllFavorisp() 
 },[searchValue,current,etat])
 const nav=useNavigate()

  return (
    <div style={{background:"#F5F5F5",padding:"2%"}}>
           <div className="rowbox-int" style={{ cursor: "pointer" }} onClick={()=>{nav("/Vender/Approvisionner")}} >
            <ArrowCircleLeft size="25" color="#9E9E9E" />
            <div>
              <p className="txtbox-int">Retourner</p>
            </div>
          </div>  <br/> <br/>

      <div  className='cp'>
    <div>  <p className='txt1-cp'>Votre Historique</p></div>
      <div  className='cp2'>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: "#F7D070" } }}
              >
                <Tab
                  label={<p className="txttabs-c">Tout</p>}
                  {...a11yProps(0)}
                  onClick={()=>{setEtat("tout");setcurrent(1)}}
                />
                <Tab
                  label={<p className="txttabs-c">Livré</p>}
                  onClick={()=>{setEtat("Livre");setcurrent(1)}}
                  {...a11yProps(1)}
                />
                <Tab
                  label={<p className="txttabs-c">En cours</p>}
                  onClick={()=>{setEtat("en cours");setcurrent(1)}}
                  {...a11yProps(2)}
                />
                <Tab
                 onClick={()=>{setEtat("Annule");setcurrent(1)}}
                  label={<p className="txttabs-c">Annulé</p>}
                  {...a11yProps(3)}
                />
              </Tabs>
              
      
            </Box>
            
            <TabPanel value={value} index={0}>
         <Tabcommandes commandes={commandes} />
        </TabPanel>
        <TabPanel value={value} index={1}>
         <Tabcommandes commandes={commandes} />
        </TabPanel>
        <TabPanel value={value} index={2}>
         <Tabcommandes commandes={commandes} />
        </TabPanel>
        <TabPanel value={value} index={3}>
         <Tabcommandes commandes={commandes} />
        </TabPanel>
        <div className="row21400-comn">
        
        <Pagination
                count={totalPages}
                shape="rounded"
                className="pagination-shop"
                page={current}
                onChange={handlePagination}
          />
      </div>
      </div>
      </div>

    </div>
  )
}

export default Historique
