
import axios from "axios"
import { createAsyncThunk} from '@reduxjs/toolkit';
import { Path, Base_url } from '../../config/Config';
export const Detailcomonde = createAsyncThunk(
    'Detailcomonde',
    async (data, { rejectWithValue }) => {
      try {
        const response = await axios.get(Base_url+ Path.Detailcomonde+data)
        return response.data
      } catch (err) {
        return rejectWithValue(err?.response?.data)
      }
    }
  )