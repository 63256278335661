import { Path, Base_url } from "../../config/Config";
import axios from "axios"
export const Visiblein = async (id,data,accessToken) => {
    try {
      const response = await axios.put(Base_url+Path.Visible+id,data,{headers:{
        Authorization: "Bearer " +accessToken,
      },}
      );
      return response.data;
    }
    catch(err){
    }
  };
  
  export const Visible = async (id,data,accessToken) => {
    try {
      const response = await axios.put(Base_url+"admin/changevisibiliter/"+id,data,{headers:{
        Authorization: "Bearer " +accessToken,
      },}
      );
      return response.data;
    }
    catch(err){
    }
  };

  export const Visibleforni = async (id,data,accessToken) => {
    try {
      const response = await axios.put(Base_url+"produitfournisseur/changeVisibilite/"+id,data,{headers:{
        Authorization: "Bearer " +accessToken,
      },}
      );
      return response.data;
    }
    catch(err){
    }
  };