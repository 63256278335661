import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Path, Base_url } from "../../config/Config";
import { toast } from "react-toastify";
export const AjouteCommande = createAsyncThunk(
  "AjouteCommande",
  async ({ data, access }) => {

    try {
      const response = await axios.post(Base_url + Path.addcommande, data, {
        headers: {
          Authorization: "Bearer " + access,
        },
      });
      return response.data;
    } catch (err) {
      toast.error(err.response.data.message,{autoClose: 2000})
      throw err;
    }
  }
);
export const AjouteCommandeforni = createAsyncThunk(
  "AjouteCommandeforni",
  async ({ data, access }) => {

    try {
      const response = await axios.post(Base_url +'commandeengros/add', data, {
        headers: {
          Authorization: "Bearer " + access,
        },
      });
      return response.data;
    } catch (err) {
      toast.error(err.response.data.message,{autoClose: 2000})
      throw err;
    }
  }
);