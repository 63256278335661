import axios from "axios"
import { createAsyncThunk} from '@reduxjs/toolkit';
import { Path, Base_url } from '../../config/Config';
export const AllbonDachateByuser = createAsyncThunk(
    'AllbonDachateByuser',
    async (data, { rejectWithValue }) => {
      try {
        const response = await axios.get(Base_url+ Path.allbonDachateUser+data.id+`?&sortBy=createdAt&sortOrder=desc&page=${data.current}&pageSize=10`)
        return response.data
      } catch (err) {
        return rejectWithValue(err?.response?.data)
      }
    }
)

