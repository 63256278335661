import React, { useEffect, useState } from 'react'
import'./responsivecard.css'
import { Add, MinusSquare, Trash } from 'iconsax-react'
import Recapitulatif from '../../../../components/Recapitulatif.js/Recapitulatif';
import { useDispatch, useSelector } from 'react-redux';
import { add,removeCommande } from '../../../../Store/panier/commandeSlice';
import { remove,update as changeQte} from "../../../../Store/panier/panierSlice";

const Responsivecard = (props) => {
  const panier=useSelector(state=> state.Panier.panier)
  const [qnt, setqnt] = useState(1);
  const quantityplus = (idp,imgp,prix,titre,idl,qte,Allqte) => {
    if(qte<Allqte)
    {
      changeQtee(idp,imgp,prix,titre,idl,qte+ 1)
    }

  };

  const quantityminu = (idp,imgp,prix,titre,idl,qte,) => {
    if (qte > 1) {
      changeQtee(idp,imgp,prix,titre,idl,qte-1)
    }
   
  };

  const [qte,setqte]=useState(props.qte)
  const dispatch = useDispatch();
  const removeProduit=(index)=>{
    dispatch(remove(index))
    dispatch(removeCommande(index))
  }
  const changeQtee=(idp,imgp,prix,titre,idl,qnt)=>{
    dispatch(changeQte({"idp":idp,"imgp":imgp,"prix":prix,"titre":titre,"qte":Number(qnt),"idl":idl}))
    dispatch(add({"qte":qnt,"idlib":idl,"prix":(prix)*qnt,"produitlabrairieId":idp}))

  
  }

  useEffect(()=>{
    setqnt(panier[0]?.qte)
    dispatch(add({"qte":panier[0]?.qte,"idlib":panier[0]?.idl,"prix":(panier[0]?.prix)*panier[0]?.qte,"produitlabrairieId":panier[0]?.idp}))
},[qte])
  return (
    <div className='responsivecard'>
      <div><p className='txt-respon'>Panier</p></div>
<div className='col-responsivecard'>
{panier?.map((e,key)=>(
  <div className="col2-responsivecard" key={key}>
  <div className='row-responsivecard'>
  <img src={e?.imgp} className='img-res'/>
  <div><p className='txt-respon2'>{e?.titre}</p></div>
  <Trash
   size="25"
   color="#D64545"
   variant="Bulk"
   style={{cursor:"pointer"}}
   onClick={()=>{removeProduit(key)}}
  />
  </div>
  <div className='row2-res'>
  
  <div className='box1-res'>
  <div><p className='txt3-rescard'>Prix</p></div>
  <div><p className='txt4-rescard'>{e?.prix?.toFixed(3)} dt</p></div>
  </div>
  <div className='box2-res'>
  <div><p className='txt3-rescard'>Qté</p></div>
  <div className='row-responsivecard2'>
  <MinusSquare
   size="24"
   color="#E9B949"
   style={{cursor:"pointer"}}
   variant="Bold"
   onClick={(()=>{quantityminu(e?.idp,e?.imgp,e?.prix,e?.titre,e?.idl,e?.qte)})}
  />
    <p className='txt4-rescard'>{e?.qte}</p>
    
    <Add
   size="24"
   color="#E9B949"
   style={{cursor:"pointer"}}
   variant="Bold"
   onClick={(()=>{quantityplus(e?.idp,e?.imgp,e?.prix,e?.titre,e?.idl,e?.qte,e?.Allqte)})}
  />
    </div>
  </div>
  <div className='box1-res'>
  <div><p className='txt3-rescard'>Totale HT</p></div>
  <div>
    <p className='txt4-rescard'>{((e?.prix)*e?.qte).toFixed(3)} dt</p>
    
    </div>
  </div>
  
  </div>
  
  
  </div>
))}

<hr  className='lin-resp'/>
</div>
<div className='rec-res'>
<Recapitulatif iduser={props.prop.user.id} auth={props.prop.user.auth} panier={panier} etatcompt={props.prop.user.etatCompte} authuser={props.prop.user.auth}/>
</div>




    </div>
  )
}

export default Responsivecard
