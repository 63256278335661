import "./App.css";
import React, { lazy, Suspense, useEffect, useRef, useState,Fragment } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Toast from "./components/toast/Toast";
import Spinier from "./components/spinier/Spinier";
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/footer/Footer";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import Clients from "./components/Admin-components/Liste d’utilisateurs/Clients/Clients";
import Fournisseursadmin from "./components/Admin-components/Liste d’utilisateurs/Fournisseurs/Fournisseursadmin";
import Vendeursadmin from "./components/Admin-components/Liste d’utilisateurs/Vendeurs/Vendeursadmin";
import Partenairesadmin from "./components/Admin-components/Liste d’utilisateurs/Partenaires/Partenairesadmin ";
import Notfound from "./Pages/not-found/Not-found";
import Bloked from "./Pages/blok/Bloked";
import Profil from "./components/Admin-components/Profil/Profil";
import {  useMediaQuery, useTheme } from "@mui/material";
import TableauDuBordF from "./components/FournisseursComponents/tableduborde/TableauDuBord";
import ListeproduitF from "./components/FournisseursComponents/ListeproduitF/ListeproduitF";
import InventaireF from "./components/FournisseursComponents/Inventaire/fournisseur-Inventaire/InventaireF";
import Gestion_pointsF from "./components/FournisseursComponents/Gestionpoints/Gestion_pointsF";
import Explorerfournisseur from "./components/FournisseursComponents/Explorer/Explorerfournisseur";
import PanierV from "./components/vender-Approvisionner/card/PanierV";
import DetailLivrFornisseuer from "./components/FournisseursComponents/Liste de livraisons/composentliste/DetailLivrFornisseuer";
import Details_Fournisseurs from "./components/Admin-components/details_Fournisseurs/Details_Fournisseurs";
import Modify_prodForni from "./components/Admin-components/Modify-prodForni/Modify_prodForni";
import Historiquee from "./components/VenderPage/Historique/Historique";
import Listeprod from "./components/FournisseursComponents/Listeprod/Listeprod";
import EchangerAdmin from "./components/Admin-components/Echanger/Echanger";
const Comondeidentifiant = lazy(() =>
  import("./components/partenairecomponet/Comondeidentifiant/Comondeidentifiant")
);
const Done = lazy(() =>
  import("./Pages/FaireComonde/Done")
);
const Commande_personnelle2 = lazy(() =>
  import("./Pages/Commande_personnelle2/Commande_personnelle2")
);
const Codepage = lazy(() =>
  import("./components/partenairecomponet/Codepage/Codepage")
);
const CatalogeForni = lazy(() =>
  import("./components/Admin-components/CatalogeForni/CatalogeForni")
);
const UpdateGategories = lazy(() =>
  import("./components/Admin-components/UpdateGategories/UpdateGategories")
);
const Liseproduit = lazy(() =>
  import("./components/Admin-components/Liseproduit/Liseproduit")
);
const Commande_personnelle = lazy(() =>
  import("./Pages/Commande_personnelle/Commande_personnelle")
);
const Faire_un_don = lazy(() =>
  import("./Pages/Faire_un_don/Faire_un_don")
);
const Liste_de_don = lazy(() =>
  import("./components/Admin-components/Liste_de_don/Liste_de_don")
);
const Liste_de_Services = lazy(() =>
  import("./components/Admin-components/Liste_de_Services/Liste_de_Services")
);
const Services_Informatique = lazy(() =>
  import("./Pages/Services_Informatique/Services_Informatique")
);
const Echanger = lazy(() =>
  import("./components/VenderPage/Echanger/Echanger")
);
const Echanges = lazy(() =>
  import("./components/Admin-components/echanges/Echanges")
);
const Details_Partenaires = lazy(() =>
  import("./components/Details_Partenaires/Details_Partenaires")
);
const DetaillivraisonsAdmin = lazy(() =>
  import(
    "./components/Admin-components/ListelivrisionAdmin copy/composentliste/Detailcomnder"
  )
);
const Details_Vender = lazy(() =>
  import("./components/Admin-components/details_Vender/details_Vender")
);
const Details_client = lazy(() =>
  import("./components/Admin-components/details_client/details_client")
);
const ListelivraisonsAdmin = lazy(() =>
  import(
    "./components/Admin-components/ListelivrisionAdmin copy/ListelivrisionAdmin"
  )
);
const DetailcomnderAdmin = lazy(() =>
  import(
    "./components/Admin-components/ListecomondeAdmin/composentliste/Detailcomnder"
  )
);
const ListecomondeAdmin = lazy(() =>
  import("./components/Admin-components/ListecomondeAdmin/ListecomondeAdmin")
);
const AjouteGategories = lazy(() =>
  import("./components/Admin-components/AjouteGategories/AjouteGategories")
);
const TableauDuBordAdmin = lazy(() =>
  import("./components/Admin-components/Tableborde/TableauDuBord")
);

const Catégories = lazy(() =>
  import("./components/Admin-components/Catégories/Catégories")
);
const Commandespartenaire = lazy(() =>
  import("./components/partenairecomponet/Mes_commandes/Commandes")
);
const Avisp = lazy(() => import("./components/partenairecomponet/Avis/Avisp"));
const Profilp = lazy(() =>
  import("./components/partenairecomponet/profilp/Profilp")
);
const Favorisp = lazy(() =>
  import("./components/partenairecomponet/Favoris/Favorisp")
);
const Pointsp = lazy(() =>
  import("./components/partenairecomponet/Points/Points")
);
const Listpro = lazy(() =>
  import("./components/Admin-components/listeprod/listpro")
);

const Partenaire = lazy(() => import("./Pages/partenaire/Partenaire"));

const Modify = lazy(() =>
  import("./components/Admin-components/Modify-prod/Modify-prod")
);

const Ajouteprod = lazy(() =>
  import("./components/Admin-components/Ajouteprod/Ajouteprod")
);

const AdminPartners = lazy(() =>
  import("./components/Admin-components/AdminPartners/AdminPartners")
);

const Admin = lazy(() => import("./Pages/Admin/Admin"));
const Gestion_points = lazy(() =>
  import("./components/Gestion de points-vender/Gestion_points")
);
const FaireComonde = lazy(() => import("./Pages/FaireComonde/FaireComonde"));

const FornisseuerProfile = lazy(() =>
  import(
    "./components/FournisseursComponents/profile-Fornisseuer/FornisseuerProfile"
  )
);
const FactorisationFornisseuer = lazy(() =>
  import(
    "./components/FournisseursComponents/Factorisation/FactorisationFornisseuer"
  )
);
const DetailcomnderFornisseuer = lazy(() =>
  import(
    "./components/FournisseursComponents/Listecommandes/composentliste/DetailcomnderFornisseuer"
  )
);
const ListecommandesFornisseuer = lazy(() =>
  import(
    "./components/FournisseursComponents/Listecommandes/ListecommandesFornisseuer"
  )
);
const ListelivraisonsFornisseuer = lazy(() =>
  import(
    "./components/FournisseursComponents/Liste de livraisons/ListelivraisonsFornisseuer"
  )
);


const Fournisseurs = lazy(() => import("./Pages/Fournisseurs/Fournisseurs"));

const DetailLivr = lazy(() =>
  import("./components/Liste-de-livraisons/composentliste/DetailLivr")
);
const Factorisation = lazy(() =>
  import("./components/vender-Factorisation/Factorisation")
);
const Approvisionner = lazy(() =>
  import("./components/vender-Approvisionner/Approvisionner")
);
const Listecommandes = lazy(() =>
  import("./components/Liste-commandes-vender/Listecommandes")
);
const Listeivraisons = lazy(() =>
  import("./components/Liste-de-livraisons/Listeivraisons")
);
const VenderProfile = lazy(() =>
  import("./components/profile-vender/VenderProfile")
);
const Detailcomnder = lazy(() =>
  import("./components/Liste-commandes-vender/composentliste/Detailcomnder")
);
const Categorie = lazy(() =>
  import("./components/VenderPage/categorie/categorie")
);
const Inventaire = lazy(() =>
  import("./components/vender-Inventaire/Inventaire")
);
const Points = lazy(() =>
  import("./components/composot-Profile/page5-profile/Points")
);
const TableauDuBord = lazy(() =>
  import("./components/VenderPage/TableauDuBord/TableauDuBord")
);
const Dachats = lazy(() =>
  import("./components/composot-Profile/page5-profile/Bon dachats/Dachats")
);
const Profile = lazy(() => import("./Pages/Profile/Profile"));
const Home = lazy(() => import("./Pages/home/Home"));
const Resetpassword = lazy(() => import("./Pages/new-password/Resetpassword"));
const Shop = lazy(() => import("./Pages/shop/shop"));
const Login = lazy(() => import("./Pages/login/Login"));
const Newpassword = lazy(() => import("./Pages/new-password/new-password"));
const Contact = lazy(() => import("./Pages/Contact/Contact"));
const Signup = lazy(() => import("./Pages/signup/SignUp"));
const AboutUs = lazy(() => import("./Pages/About-Us/AboutUs"));
const Detailprod = lazy(() => import("./Pages/Detail-prod/Detail-prod"));
const BecomePartner = lazy(() =>
  import("./Pages/Become-a-Partner/Become_a_Partner")
);

const Protection = lazy(() => import("./Store/protection"));
const Cart = lazy(() => import("./Pages/Cart/Cart"));
const LibrairieProfile = lazy(() =>
  import("./Pages/librairieProfile/librairieProfile")
);
const Page1_profile = lazy(() =>
  import("./components/composot-Profile/page-profile/Page")
);
const Page2_profile = lazy(() =>
  import("./components/composot-Profile/page2-profile/Page2")
);
const Commandes = lazy(() =>
  import("./components/composot-Profile/page3-profile/Commandes")
);
const Avis = lazy(() =>
  import("./components/composot-Profile/page4-profile/Avis")
);
const Vender = lazy(() => import("./Pages/vender/vender"));
function App() {
  const [loading, setLoading] = useState(false);
  const {pathname}=useLocation()
  const loder=(data)=>{
    setLoading(data)
  }

  const theme = useTheme();
const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const LoginServiceData = useSelector((state) => state.loginservice);
  const googleServiceData = useSelector((state) => state.Googleservice);
  var decoded = googleServiceData.isLogin!==false?jwt_decode(googleServiceData?.accessToken):null;
  const user = {
    id: LoginServiceData?.user?.id? LoginServiceData?.user?.id:decoded?.id,
    auth:
      LoginServiceData.isLogin === true && googleServiceData.isLogin === false
        ? LoginServiceData.isLogin
        : googleServiceData.isLogin,
    fullname:  LoginServiceData?.user?.fullname?LoginServiceData?.user?.fullname:decoded?.fullname,
    role:  LoginServiceData?.user?.role?LoginServiceData?.user?.role:decoded?.role,
    avatar:  LoginServiceData?.user?.avatar?LoginServiceData?.user?.avatar:decoded?.avatar,
    etatCompte: LoginServiceData?.user?.etatCompte?LoginServiceData?.user?.etatCompte:decoded?.etatCompte,
    accessToken:LoginServiceData.accessToken?LoginServiceData.accessToken:googleServiceData.accessToken
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname,loading]);
  const partenairee = /^\/partenaire(\/.*)?$/; 
  const partenairee2 = /^\/Vender(\/.*)?$/; 
  const partenairee3 = /^\/Admin(\/.*)?$/; 

  const isMatching = partenairee.test(pathname);
  const isMatching2 = partenairee2.test(pathname);
  const isMatching3 = partenairee3.test(pathname);

  return (
 
   
 
      <Suspense fallback={<Spinier />}>
     {isMatching ||isMatching3|| isMatching2?<></>: <NavBar user={user} />}
      {        
      !loading ? 
      <Routes>
      <Route
        path="/Login"
        element={
          <Protection user={user}>
            <Login user={user} loder={loder} />
          </Protection>
        }
      />
      <Route
        path="/Commande_personnelle2"
        element={
            <Commande_personnelle2 user={user} loder={loder} />
        }
      />
         <Route
        path="/Commande_Succes"
        element={
            <Done/>
        }
      />
      <Route
        path="/Signup"
        element={
          <Protection user={user}>
            <Signup loder={loder} />
          </Protection>
        }
      />
      <Route
        path="/New_password"
        element={
          <Protection user={user}>
            <Newpassword loder={loder} />
          </Protection>
        }
      />
        <Route
        path="/Faire_un_don"
        element={
            <Faire_un_don loder={loder} />
        }
      />
           <Route
          path="/Commande_personnelle"
          element={
            user.auth?
              <Commande_personnelle user={user} loder={loder}  />
              :<Notfound />
          }
        />
      <Route path="/Contact" element={<Contact  loder={loder} />}  />
      <Route path="/AboutUs" element={<AboutUs  loder={loder} />} />
      <Route path="/BecomePartner" element={<BecomePartner  loder={loder}  />} />
      <Route path="/Shop" element={<Shop  loder={loder} />} />
      <Route path="/Shop/:id" element={<Shop  loder={loder} />} />

      <Route
        path="/Detailproduit/:id"
        element={<Detailprod user={user}  loder={loder}   />}
      />
      <Route path="/cart" element={<Cart user={user} loder={loder}  />} />
      <Route path="/librairieProfile/:id" element={<LibrairieProfile />} />

      <Route
        path="/Profile"
        element={
          <Protection user={user}>
            <Profile user={user} loder={loder}  />
          </Protection>
        }
      >
        <Route
          path="/Profile/Monidentite"
          element={<Page1_profile user={user} loder={loder}  />}
        />
        <Route
          path="/Profile/Favoris"
          element={
            <Protection user={user}>
              <Page2_profile user={user} isSmallScreen={isSmallScreen} loder={loder} />
            </Protection>
          }
        />
        <Route
          path="/Profile/Commandes"
          element={
            <Protection user={user}>
              <Commandes user={user} loder={loder}  />
            </Protection>
          }
        />
        <Route
          path="/Profile/Avis"
          element={
            <Protection user={user}>
              <Avis user={user}  loder={loder} />
            </Protection>
          }
        />
        <Route
          path="/Profile/MesPoints"
          element={
            <Protection user={user}>
              <Points user={user}  loder={loder} />
            </Protection>
          }
        >
          <Route
            path="/Profile/MesPoints/Bondachats"
            element={<Dachats loder={loder}  />}
          />
          <Route
            path="/Profile/MesPoints"
            element={<Navigate to="/Profile/MesPoints/Bondachats" />}
          />
        </Route>
      </Route>
      <Route path="/" element={<Navigate to="/Home" />} />
      <Route path="/Home" element={<Home  />} />

      <Route
        path="/reset-password/:id/:token"
        element={<Resetpassword loder={loder} />}
      />

      <Route
        path="/Vender"
        element={
          <Protection user={user}>
            <Vender user={user} />
          </Protection>
        }
      >
        <Route
          path="/Vender/TableauDuBord"
          element={<TableauDuBord user={user} />}
        />
          <Route
          path="/Vender"
          element={<TableauDuBord user={user} />}
        />
        <Route
          path="/Vender/Liste_de_produits"
          element={
            <Protection user={user}>
              <Categorie user={user} loder={loder}/>
            </Protection>
          }
        />
        <Route
          path="/Vender/Inventaire"
          element={
            <Protection user={user}>
              <Inventaire user={user} />
            </Protection>
          }
        />
        <Route
          path="/Vender/Liste_de_commandes"
          element={
            <Protection user={user}>
              <Listecommandes user={user} />
            </Protection>
          }
        />
        <Route
          path="/Vender/Details_de_commande/:id"
          element={
            <Protection user={user}>
              <Detailcomnder user={user} />
            </Protection>
          }
        />
        <Route
          path="/Vender/Details_de_livraison/:id"
          element={
            <Protection user={user}>
              <DetailLivr user={user} />
            </Protection>
          }
        />
        <Route
          path="/Vender/Profile"
          element={
            <Protection user={user}>
              <VenderProfile user={user} loder={loder} />
            </Protection>
          }
        />
        <Route
          path="/Vender/Liste_de_Livraisons"
          element={
            <Protection user={user}>
              <Listeivraisons user={user} />
            </Protection>
          }
        />
        <Route
          path="/Vender/Approvisionner"
          element={
            <Protection user={user}>
              <Approvisionner user={user} />
            </Protection>
          }
        />
        <Route
          path="/Vender/Factorisation"
          element={
            <Protection user={user}>
              <Factorisation />
            </Protection>
          }
        />
             <Route
          path="/Vender/Historique"
          element={
            <Protection user={user}>
              <Historiquee user={user}/>
            </Protection>
          }
        />
        <Route
          path="/Vender/Gestion_de_points"
          element={
            <Protection user={user}>
              <Gestion_points user={user}/>
            </Protection>
          }
        />
        <Route
          path="/Vender/Echanger"
          element={
            <Protection user={user}>
              <Echanger user={user}/>
            </Protection>
          }
        />
           <Route
          path="/Vender/Panier"
          element={
            <Protection user={user}>
              <PanierV user={user} loder={loder}/>
            </Protection>
          }
        />
      </Route>

      <Route
        path="/fournisseur"
        element={
          <Protection user={user}>
            <Fournisseurs user={user} />
          </Protection>
        }
      >
        <Route
          path="/fournisseur/Liste_de_livraisons"
          element={
            <Protection user={user}>
              <ListelivraisonsFornisseuer user={user} />
            </Protection>
          }
        />
        <Route
          path="/fournisseur/Details_de_livraison/:id"
          element={
              <DetailLivrFornisseuer user={user} />
          }
        />
           <Route
          path="/fournisseur/Explorer"
          element={
            <Protection user={user}>
              <Explorerfournisseur user={user}/>
            </Protection>
          }
        />
        <Route
          path="/fournisseur/Liste_de_commandes"
          element={
            <Protection user={user}>
              <ListecommandesFornisseuer user={user}/>
            </Protection>
          }
        />
           <Route
          path="/fournisseur/Liste_de_produits"
          element={
            <Protection user={user}>
              <Listeprod user={user} loder={loder}/>
            </Protection>
          }
        />
        <Route
          path="/fournisseur/Details_de_commandes/:id"
          element={
            <Protection user={user}>
              <DetailcomnderFornisseuer user={user}/>
            </Protection>
          }
        />
        <Route
          path="/fournisseur/Factorisation"
          element={
            <Protection user={user}>
              <FactorisationFornisseuer />
            </Protection>
          }
        />
        <Route
          path="/fournisseur/Profile"
          element={
            <Protection user={user}>
              <FornisseuerProfile loder={loder} user={user} />
            </Protection>
          }
        />
          <Route
          path="/fournisseur/TableauDuBord"
          element={
            <Protection user={user}>
              <TableauDuBordF user={user} />
            </Protection>
          }
        />
            <Route
          path="/fournisseur/ListeProduits"
          element={
            <Protection user={user}>
              <ListeproduitF user={user} />
            </Protection>
          }
        />
           <Route
          path="/fournisseur/Inventaire"
          element={
            <Protection user={user}>
              <InventaireF user={user} />
            </Protection>
          }
        />
              <Route
          path="/fournisseur/Gestion_de_points"
          element={
            <Protection user={user}>
              <Gestion_pointsF user={user} />
            </Protection>
          }
        />
      </Route>
      <Route
        path="/Faire_une_commande"
        element={<FaireComonde user={user} loder={loder} />}
      />
      <Route
        path="/Admin"
        element={
          <Protection user={user}>
            <Admin user={user} />
          </Protection>
        }
      >
          <Route
          path="/Admin/Echanger"
          element={
            <Protection user={user}>
              <EchangerAdmin user={user}/>
            </Protection>
          }
        />
        <Route
          path="/Admin/Liste_de_demande"
          element={
            <Protection user={user}>
              <AdminPartners user={user} loder={loder}/>
            </Protection>
          }
        />
          <Route
          path="/Admin/Liste_de_Services"
          element={
            <Protection user={user}>
              < Liste_de_Services user={user} />
            </Protection>
          }
        />
             <Route
          path="/Admin/Liste_de_don"
          element={
            <Protection user={user}>
              <Liste_de_don user={user} />
            </Protection>
          }
        />

        <Route
          path="/Admin/Catalogue"
          element={
            <Protection user={user}>
              <Listpro user={user} loder={loder} />
            </Protection>
          }
        />
        <Route
          path="/Admin/Catalogue_fournisseur"
          element={
            <Protection user={user}>
              <CatalogeForni user={user} loder={loder}/>
            </Protection>
          }
        />
        <Route
          path="/Admin/Produits"
          element={
            <Protection user={user}>
              <Liseproduit user={user}  loder={loder}/>
            </Protection>
          }
        />
        <Route
          path="/Admin/Modifier_produit/:id"
          element={
            <Protection user={user}>
              <Modify user={user}  loder={loder}/>
            </Protection>
          }
        />
            <Route
          path="/Admin/Modifier_produitForni/:id"
          element={
            <Protection user={user}>
              <Modify_prodForni user={user} loder={loder} />
            </Protection>
          }
        />
        <Route
          path="/Admin/Ajouter_un_produit/:id"
          element={
            <Protection user={user}>
              <Ajouteprod user={user} loder={loder}/>
            </Protection>
          }
        />
        <Route
          path="/Admin/ListeClients"
          element={
            <Protection user={user}>
              <Clients user={user}loder={loder} />
            </Protection>
          }
        />
        <Route
          path="/Admin/ListePartenaires"
          element={
            <Protection user={user}>
              <Partenairesadmin user={user} loder={loder} />
            </Protection>
          }
        />
        <Route
          path="/Admin/ListeVendeurs"
          element={
            <Protection user={user}>
              <Vendeursadmin user={user} loder={loder}/>
            </Protection>
          }
        />
         <Route
          path="/Admin/UpdateGategories/:id"
          element={
            <Protection user={user}>
              <UpdateGategories user={user} loder={loder}/>
            </Protection>
          }
        />
        <Route
          path="/Admin/ListeFournisseurs"
          element={
            <Protection user={user}>
              <Fournisseursadmin user={user} loder={loder}/>
            </Protection>
          }
        />
        <Route
          path="/Admin/Categories"
          element={
            <Protection user={user}>
              <Catégories user={user} loder={loder}/>
            </Protection>
          }
        />
        <Route
          path="/Admin/TableauDuBord"
          element={
            <Protection user={user}>
              <TableauDuBordAdmin user={user} loder={loder}/>
            </Protection>
          }
        />
        <Route
          path="/Admin/AjouteGategories"
          element={
            <Protection user={user}>
              <AjouteGategories user={user}  loder={loder}/>
            </Protection>
          }
        />
        <Route
          path="/Admin/Liste_de_commandes"
          element={
            <Protection user={user}>
              <ListecomondeAdmin user={user} loder={loder}/>
            </Protection>
          }
        />
        <Route
          path="/Admin/Liste_de_livraisons"
          element={
            <Protection user={user}>
              <ListelivraisonsAdmin user={user} loder={loder}/>
            </Protection>
          }
        />
        <Route
          path="/Admin/Details_de_commande/:id"
          element={
            <Protection user={user}>
              <DetailcomnderAdmin user={user} loder={loder}/>
            </Protection>
          }
        />
        <Route
          path="/Admin/Details_de_livraisons/:id"
          element={
            <Protection user={user}>
              <DetaillivraisonsAdmin user={user} loder={loder}/>
            </Protection>
          }
        />
        <Route
          path="/Admin/details_client/:id"
          element={
            <Protection user={user}>
              <Details_client user={user}loder={loder} />
            </Protection>
          }
        />
         <Route
          path="/Admin/details_Fournisseurs/:id"
          element={
            <Protection user={user}>
              <Details_Fournisseurs user={user} loder={loder}/>
            </Protection>
          }
        />
        <Route
          path="/Admin/details_Partenaires/:id"
          element={
            <Protection user={user}>
              <Details_Partenaires user={user} loder={loder}/>
            </Protection>
          }
        />
        <Route
          path="/Admin/details_Vendeurs/:id"
          element={
            <Protection user={user}>
              <Details_Vender user={user}loder={loder} />
            </Protection>
          }
        />
        <Route
          path="/Admin/Profile"
          element={
            <Protection user={user}>
              <Profil user={user} loder={loder}/>
            </Protection>
          }
        />
        <Route
          path="/Admin/Echanges"
          element={
            <Protection user={user}>
              <Echanges user={user} loder={loder}/>
            </Protection>
          }
        />
      </Route>


      <Route
        path="/partenaire"
        element={
          <Protection user={user}>
            <Partenaire user={user} />
          </Protection>
        }
      >
        <Route
          path="/partenaire"
          element={<Navigate to="/partenaire/commandes" />}
        />
   
            <Route
          path="/partenaire/Codepage"
          element={<Protection user={user}><Codepage user={user} /></Protection>}
        />
        
        <Route
          path="/partenaire/Comondeidentifiant"
          element={<Protection user={user}><Comondeidentifiant user={user} /></Protection>}
        />
        <Route
          path="/partenaire/commandes"
          element={<Protection user={user}><Commandespartenaire user={user} /></Protection>}
        />
        <Route
          path="/partenaire/Mes_points"
          element={<Protection user={user}><Pointsp user={user} /></Protection>}
        />
        <Route
          path="/partenaire/Mes_Favoris"
          element={<Protection user={user}><Favorisp user={user} /></Protection>}
        />
        <Route
          path="/partenaire/Profile"
          element={<Protection user={user}><Profilp user={user} /></Protection>}
        />
        <Route path="/partenaire/Avis" element={<Protection user={user}><Avisp user={user} /></Protection>} />
      </Route>








      <Route
          path="/Services_Informatique"
          element={
              <Services_Informatique user={user} loder={loder} />
          }
        />
      <Route path="/bloke" element={<Bloked />} />
      <Route path="*" element={<Notfound />} />
    </Routes>
    :
    <Spinier />
      }
            {isMatching ||isMatching3||  isMatching2?<></>:  <Footer/>}

       
        <Toast />
      </Suspense>

  
 
  );
}

export default App;
