import {createSlice} from "@reduxjs/toolkit";
import { getAllProduitCatalogeAdmin } from "../Service/allProduitCataloge";


export const AllProduitCatalogeadmin=createSlice({
  name:"AllProduitCatalogeadmin",
  initialState:{
    produCataloge:[],
    nbrtotal:[],
    status:null
  },
  reducers: {},
  extraReducers:{
    [getAllProduitCatalogeAdmin.fulfilled]:(state,{payload})=>{
        state.produCataloge=payload.catalogue
        state.nbrtotal=payload.totalPages
        state.status="success"
    },
   [getAllProduitCatalogeAdmin.pending]:(state)=>{
    state.status="loading"
   },
   [getAllProduitCatalogeAdmin.rejected]:(state)=>{
    state.status="failed"
    state.produCataloge=null
   }
  }  

})

export default AllProduitCatalogeadmin.reducer;