import axios from "axios"
import { createAsyncThunk} from '@reduxjs/toolkit';
import { Path, Base_url } from '../../config/Config'

export const GoogleService = createAsyncThunk(
  'GoogleService',
  async (postData) => {
    try {
    const response = await axios.post(Base_url+ Path.loginsocial, postData)
    return response.data
    }
    catch (error) {
      return console.error(error?.response?.data)
    }

  }
)

